import React, { useState, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { EditorState } from "draft-js";
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import Input from "../../../components/Control/Input";
import Button from "../../../components/Control/Button";
import { createBlog } from "../../../redux/actions/blogActions";
const FILE_SIZE = 1000000;
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const entryScheme = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  description: Yup.string().required().label("Description"),
  photo: Yup.mixed()
    .required(
      "Image is required",
      (value) => !(value instanceof String || value instanceof File)
    )
    .test(
      "fileFormat",
      "Unsupported Format! only upload .png, .jpg and .jpeg images",
      (value) => {
        let result = false;
        if (value instanceof File) {
          result = value && SUPPORTED_IMAGE_FORMATS.includes(value.type);
        } else {
          result = true;
        }

        return result;
      }
    )
    .test("fileSize", "File too large, should be less than 468", (value) => {
      let result = false;
      if (value instanceof File) {
        result = value && value.size <= FILE_SIZE;
      } else {
        result = true;
      }
      return result;
    }),
});

const BLOG_INITIAL = {
  title: "",
  description: "",
  created_by: "",
  photo: "",
};

const NewBlog = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ authReducer }) => authReducer);

  const convertToFormData = (jsonData) => {
    let formData = new FormData();
    for (const key in jsonData) {
      if (key === "photo" && !(jsonData[key] instanceof File)) {
        continue;
      }
      formData.append(key, jsonData[key]);
    }
    return formData;
  };

  const createBlogHandler = useCallback(
    (_data) => {
      if (editorState.getCurrentContent().hasText()) {
        let fd = convertToFormData(_data);
        dispatch(createBlog(fd));
      }
    },
    [dispatch, editorState]
  );
  const goback = useCallback(() => history.push("/admin/blog"), [history]);

  return (
    <div className="container all-columns">
      <div className="column-text none">
        <div className="container">
          <div className="header">
            <div className="row justify-content-between">
              <h1> New Blog </h1>
              <Button
                color="red"
                onClick={goback}
                className="mt-4"
                style={{ float: "right" }}
                content={"Back "}
              />
            </div>
          </div>
        </div>

        <div className="content">
          <Formik
            initialValues={{
              ...BLOG_INITIAL,
              created_by: user.id,
            }}
            validationSchema={entryScheme}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              if (editorState.getCurrentContent().hasText()) {
                setTimeout(() => {
                  createBlogHandler(values);
                  actions.setSubmitting(false);
                }, 1000);
              }
            }}
          >
            {(props) => {
              return (
                <div className="entry-form">
                  <form className="editor" onSubmit={props.handleSubmit}>
                    <Input
                      type="text"
                      name="title"
                      value={props.values.title}
                      placeholder="Title"
                    />
                    <div className="form-group p-0">
                      <input
                        type="file"
                        name="photo"
                        onBlur={props.handleBlur}
                        label="Photo"
                        className="pt-0"
                        onChange={(evt) => {
                          props.setFieldValue("photo", evt.target.files[0]);
                        }}
                      />
                      {props.errors.photo && (
                        <div className="input-error">{props.errors.photo}</div>
                      )}
                    </div>

                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      onBlur={props.handleBlur("description")}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "emoji",
                          "image",
                          "remove",
                          "history",
                        ],
                      }}
                      onContentStateChange={(data) => {
                        // console.log("Data", data);
                        if (editorState.getCurrentContent().hasText()) {
                          props.setFieldValue(
                            "description",
                            JSON.stringify(data)
                          );
                        }
                      }}
                    />

                    <button
                      className="btn btn-primary"
                      disabled={props.isSubmitting}
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewBlog;
