import { POSTS } from "../variables";

export const createPost = (data) => ({
  type: POSTS.ADD_POSTS,
  payload: data,
});

export const createPostSucess = (data) => ({
  type: POSTS.ADD_POSTS_SUCCESS,
  payload: data,
});

export const createPostFailure = (data) => ({
  type: POSTS.ADD_POSTS_FAILURE,
  payload: data,
});

export const updatePost = (data) => ({
  type: POSTS.UPDATE_POSTS,
  payload: data,
});

export const updatePostSucess = (data) => ({
  type: POSTS.UPDATE_POSTS_SUCCESS,
  payload: data,
});

export const updatePostFailure = (data) => ({
  type: POSTS.UPDATE_POSTS_FAILURE,
  payload: data,
});

export const removePost = (data) => ({
  type: POSTS.REMOVE_POSTS,
  payload: data,
});

export const removePostSucess = (data) => ({
  type: POSTS.REMOVE_POSTS_SUCCESS,
  payload: data,
});

export const removePostFailure = (data) => ({
  type: POSTS.REMOVE_POSTS_FAILURE,
  payload: data,
});

export const findPost = (data) => ({
  type: POSTS.FIND_POSTS,
  payload: data,
});

export const findPostSucess = (data) => ({
  type: POSTS.FIND_POSTS_SUCCESS,
  payload: data,
});

export const findPostFailure = (data) => ({
  type: POSTS.FIND_POSTS_FAILURE,
  payload: data,
});

export const getPost = (filters) => ({
  type: POSTS.GET_POSTS,
  payload: filters,
});

export const getPostSucess = (data) => ({
  type: POSTS.GET_POSTS_SUCCESS,
  payload: data,
});

export const getPostFailure = (data) => ({
  type: POSTS.GET_POSTS_FAILURE,
  payload: data,
});
