import { dataMachine } from "./dataMachine";
import { API } from "../api";
import { requestOptions } from "../api/request";
import queryString from "query-string";

export const fetchInvestmentProductsMachine = dataMachine(
  "fetchInvestmentProductsMachine"
).withConfig({
  services: {
    fetchData: (cxt, evt) => {
      const filters = queryString.stringify(evt.data.payload);
      return API.request({
        url: "investments/?" + filters,
        opt: requestOptions("GET", evt.data.token, {}),
      });
    },
  },
});
