import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../common/useRouter";
import { findBlog } from "../../../redux/actions/blogActions";

import LayoutFour from "../../../components/Layout/LayoutFour";
import PostContent from "../../../components/Blog/PostContent";

export default function PostItem() {
  const router = useRouter();

  const dispatch = useDispatch();
  const { blog } = useSelector(({ blogReducer }) => blogReducer);
  const { posts } = useSelector(({ postsReducer }) => postsReducer);
  const { user } = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    const id = router.query.id;
    dispatch(findBlog(id));

    return () => {
      // console.log("clean");
    };
  }, [dispatch, router]);

  console.log(blog);
  return (
    blog &&
    Object.prototype.hasOwnProperty.call(blog, "id") && (
      <LayoutFour tilte={blog.title}>
        <div className="post">
          <div className="post__cover">
            <img
              src={
                blog.photo
                  ? blog.photo
                  : "https://via.placeholder.com/770x335?text=www.menigtefarms.com"
              }
              alt={blog.title}
            />
          </div>
          <div className="post__body">
            <div className="container">
              <div className="row no-gutters justify-content-center">
                <div className="col-12 col-md-10">
                  <div className="post__content">
                    <PostContent
                      user={user}
                      data={blog}
                      posts={posts.results}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutFour>
    )
  );
}
