/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";

import moment from "moment";
import Input from "../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
const entryScheme = Yup.object().shape({
  message: Yup.string().required().label("Comment"),
});

export default function PostContent({
  data,
  toggle,
  user,
  posts,
  createComment,
}) {
  return (
    <div className="post-content">
      <div className="post-content__header">
        <div className="post-content__header__date">
          <h5>{moment(data.created_at).format("DD")}</h5>
          <p>{moment(data.created_at).format("MMM")}</p>
        </div>
        <div className="post-content__header__content">
          <div className="post_content__info">
            <p>
              by{" "}
              <span>
                {Object.prototype.hasOwnProperty.call(data.created_by, "id")
                  ? data.created_by.first_name
                  : ""}
              </span>
            </p>
          </div>
          <h2>{data.title}</h2>
        </div>
      </div>
      <div className="post-content__body">
        {data &&
        Object.prototype.hasOwnProperty.call(data, "id") &&
        data.description.includes("blocks") ? (
          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(JSON.parse(data.description)),
            }}
          />
        ) : data && Object.prototype.hasOwnProperty.call(data, "id") ? (
          data.description
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
