import { requestOptions } from "./request";

const sendMail = (data) => {
  return {
    url: "contact/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

export default {
  sendMail,
};
