import { TOPICS } from "../variables";

export const createTopic = (data) => ({
  type: TOPICS.ADD_TOPICS,
  payload: data,
});

export const createTopicSucess = (data) => ({
  type: TOPICS.ADD_TOPICS_SUCCESS,
  payload: data,
});

export const createTopicFailure = (data) => ({
  type: TOPICS.ADD_TOPICS_FAILURE,
  payload: data,
});

export const updateTopic = (data) => ({
  type: TOPICS.UPDATE_TOPICS,
  payload: data,
});

export const updateTopicSucess = (data) => ({
  type: TOPICS.UPDATE_TOPICS_SUCCESS,
  payload: data,
});

export const updateTopicFailure = (data) => ({
  type: TOPICS.UPDATE_TOPICS_FAILURE,
  payload: data,
});

export const removeTopic = (data) => ({
  type: TOPICS.REMOVE_TOPICS,
  payload: data,
});

export const removeTopicSucess = (data) => ({
  type: TOPICS.REMOVE_TOPICS_SUCCESS,
  payload: data,
});

export const removeTopicFailure = (data) => ({
  type: TOPICS.REMOVE_TOPICS_FAILURE,
  payload: data,
});

export const findTopic = (data) => ({
  type: TOPICS.FIND_TOPICS,
  payload: data,
});

export const findTopicSucess = (data) => ({
  type: TOPICS.FIND_TOPICS_SUCCESS,
  payload: data,
});

export const findTopicFailure = (data) => ({
  type: TOPICS.FIND_TOPICS_FAILURE,
  payload: data,
});

export const getTopic = (filters) => ({
  type: TOPICS.GET_TOPICS,
  payload: filters,
});

export const getTopicSucess = (data) => ({
  type: TOPICS.GET_TOPICS_SUCCESS,
  payload: data,
});

export const getTopicFailure = (data) => ({
  type: TOPICS.GET_FARMER_FAILURE,
  payload: data,
});
