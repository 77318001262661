import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { saveAs } from "file-saver";
import { API, genAPI } from "../../api";
import {
  fetchStatisticsSuccess,
  fetchStatisticsFailed,
  generateCsvSuccess,
  generateCsvFailed,
} from "../actions/generalActions";
import { STATS, GENERATE_CSV } from "../variables";

export function* getStatistic() {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const stats = yield call(API.request, genAPI.getStatistics(token));
    if (!stats) {
      throw new Error("Request failed");
    }

    yield put(fetchStatisticsSuccess(stats));
  } catch (error) {
    yield put(fetchStatisticsFailed(error.data));
  }
}

function* generateCsv({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const resp = yield call(API.request, genAPI.generateCsv(token, payload));
    if (!resp) {
      throw new Error("Request failed");
    }

    var BOM = "\uFEFF";
    var csvData = BOM + resp;
    var blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Extract.csv");
    yield put(generateCsvSuccess(resp));
  } catch (error) {
    yield put(generateCsvFailed(error));
  }
}

export function* getStatisticRequest() {
  yield takeLatest(STATS.FETCH_STATISTICS, getStatistic);
}
export function* generateCsvRequest() {
  yield takeLatest(GENERATE_CSV.GENERATE_CSV, generateCsv);
}

export default function* rootSaga() {
  yield all([fork(getStatisticRequest), fork(generateCsvRequest)]);
}
