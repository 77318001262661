import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, orderAPI } from "../../api";
import {
  createOrderFailure,
  createOrderSucess,
  updateOrderSucess,
  updateOrderFailure,
  findOrderSucess,
  removeOrderSucess,
  removeOrderFailure,
  findOrderFailure,
  getOrderSucess,
  getOrderFailure,
} from "../actions/orderActions";

import { ORDERS, CART } from "../variables";
import { push } from "connected-react-router";
function* createOrder({ payload }) {
  try {
    const { token } = yield select(({ authReducer }) => authReducer);
    const orderData = yield call(
      API.request,
      orderAPI.createOrder(token, payload)
    );
    if (!orderData) {
      throw new Error("Request failed");
    }

    yield put(createOrderSucess(orderData));

    yield put(push("/"));
    yield put(CART.REMOVE_ALL_FROM_CART);
    // yield call(getOrder, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(createOrderFailure());
  }
}

function* updateOrder({ payload }) {
  try {
    console.log("payload ", payload);
    const { token } = yield select(({ authReducer }) => authReducer);
    const orderData = yield call(
      API.request,
      orderAPI.updateOrder(token, payload)
    );
    if (!orderData) {
      throw new Error("Request failed");
    }

    yield put(updateOrderSucess(orderData));
    yield call(findOrder, { payload: payload.id });
  } catch (error) {
    console.log(error);
    yield put(updateOrderFailure());
  }
}

function* findOrder({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const orderData = yield call(
      API.request,
      orderAPI.findOrder(token, payload)
    );
    if (!orderData) {
      throw new Error("Request failed");
    }

    yield put(findOrderSucess(orderData));
  } catch (error) {
    yield put(findOrderFailure());
  }
}

function* getOrder({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const orderData = yield call(
      API.request,
      orderAPI.getOrders(token, payload)
    );
    if (!orderData) {
      throw new Error("Request failed");
    }

    yield put(getOrderSucess(orderData));
  } catch (error) {
    yield put(getOrderFailure());
  }
}

function* removeOrder({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    yield call(API.request, orderAPI.removeOrder(token, payload));

    yield put(removeOrderSucess(payload));
    yield call(getOrder, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(removeOrderFailure());
  }
}

export function* createOrderRequest() {
  yield takeLatest(ORDERS.ADD_ORDERS, createOrder);
}

export function* updateOrderRequest() {
  yield takeLatest(ORDERS.UPDATE_ORDERS, updateOrder);
}

export function* findOrderRequest() {
  yield takeLatest(ORDERS.FIND_ORDERS, findOrder);
}

export function* getOrderRequest() {
  yield takeLatest(ORDERS.GET_ORDERS, getOrder);
}

export function* removeOrderRequest() {
  yield takeLatest(ORDERS.REMOVE_ORDERS, removeOrder);
}

export default function* rootSaga() {
  yield all([
    fork(createOrderRequest),
    fork(findOrderRequest),
    fork(updateOrderRequest),
    fork(getOrderRequest),
    fork(removeOrderRequest),
  ]);
}
