import { STATS, GENERATE_CSV } from "../variables";

export const fetchStatistics = () => ({ type: STATS.FETCH_STATISTICS });
export const fetchStatisticsSuccess = (data) => ({
  type: STATS.FETCH_STATISTICS_SUCCESS,
  payload: data,
});
export const fetchStatisticsFailed = (error) => ({
  type: STATS.FETCH_STATISTICS_FAILURE,
  payload: error,
});

export const generateCsv = (payload) => ({
  type: GENERATE_CSV.GENERATE_CSV,
  payload,
});
export const generateCsvSuccess = () => ({
  type: GENERATE_CSV.GENERATE_CSV_SUCCESS,
});
export const generateCsvFailed = () => ({
  type: GENERATE_CSV.GENERATE_CSV_FAILURE,
});
