import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import ServiceItem from "../../components/Pages/Services/ServiceItem";

export default function Export() {
  const serviceData = {
    title: "Export Goats",
    image: "/assets/images/shop/export3.jpg",
    text: {
      blocks: [
        {
          key: "cuo1i",
          text:
            "Menigte Farms Ltd is among the top fastest-growing exporters of goats and goat meat. At Mefarm Ltd, we manage export logistics of pure goat breeds raised from both our farms and subscribed farmers and goat products to United Arab Emirates, Indonesia, United States of America, Caribbean, South Korea, Angola, Taiwan, China, India, Middle East, European Union, Malaysia, and Singapore.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "36re5",
          text:
            "\nJoin our Network today and skip the hustle to sell or access market information with less middle men involved. Prospective exporters can join us to benefit on future emerging markets, including but not limited to: obtaining better prices for farmers when selling their goats and better breeds. Hence, improving farmers’ lives.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
  };

  // const { products } = useSelector(({ productReducer }) => productReducer);
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   dispatch(getProduct({ is_for_sale: "True" }));
  //   return () => {};
  // }, [dispatch]);

  return (
    <LayoutFour>
      <Breadcrumb title="Shop">
        <BreadcrumbItem name="Shop" />
        <BreadcrumbItem name="Export" current />
      </Breadcrumb>
      <div className={`services__item`}>
        {serviceData && (
          <ServiceItem
            bigImgSrc={serviceData.image}
            title={serviceData.title}
            text={serviceData.text}
            li={serviceData.li}
          />
        )}
      </div>
      {/*<ProductSlideOne title="Local Market" data={products.results} />*/}
    </LayoutFour>
  );
}
