import { TOPICS } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  topics: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  topic: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function topicsReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case TOPICS.ADD_TOPICS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case TOPICS.ADD_TOPICS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case TOPICS.ADD_TOPICS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case TOPICS.FIND_TOPICS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case TOPICS.FIND_TOPICS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        topic: action.payload,
      };
    case TOPICS.FIND_TOPICS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case TOPICS.UPDATE_TOPICS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case TOPICS.UPDATE_TOPICS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case TOPICS.UPDATE_TOPICS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case TOPICS.GET_TOPICS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case TOPICS.GET_TOPICS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        topics: action.payload,
      };
    case TOPICS.GET_TOPICS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case TOPICS.REMOVE_TOPICS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case TOPICS.REMOVE_TOPICS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case TOPICS.REMOVE_TOPICS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
