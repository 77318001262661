import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";

export default function () {
  const tableData = [
    {
      title: "Goat farming manual (Getting started) ",
      link:
        "https://drive.google.com/file/d/1WZR-u-KiLlGFUfsN8CXMXPpifX3VI9kZ/view?usp=sharing",
    },
    {
      title: "Goat production and Identification in Africa",
      link:
        "https://drive.google.com/file/d/1C-hbjRP-k4hiSnD32MLm5kKYB6R2J4GX/view?usp=sharing",
    },
    {
      title: "Herd health management ",
      link:
        "https://drive.google.com/file/d/1Jpe1_W4keeRdMmj0yfvdOh998lIaMLB-/view?usp=sharing",
    },
    {
      title: "Management Practices for a Goat Dairy ",
      link:
        "https://drive.google.com/file/d/1vJj70cqECQ0CHNWBssp8bD8okg_IZnD6/view?usp=sharing",
    },
    {
      title: "Meat Goat Management ",
      link:
        "https://drive.google.com/file/d/11c5eutclnvafrkwSUJagCRil5XlE6atP/view?usp=sharing",
    },
    {
      title: "Goat Rearing and identification ",
      link:
        "https://drive.google.com/file/d/1qnVawqH-UKrUzByKnUlahqh1C9twB4Mf/view?usp=sharing",
    },
    {
      title: "Breeding Management ",
      link:
        "https://drive.google.com/file/d/1Oddg6oOjay_G_f2uVkxIfItqkeK42Az2/view?usp=sharing",
    },
    {
      title: "Feeding",
      link:
        "https://drive.google.com/file/d/1M9ivW5KuzjqmSCrGGIiawXDCcvkUjd6J/view?usp=sharing",
    },
    {
      title: "Care of Newborn Kids ",
      link:
        "https://drive.google.com/file/d/1CeiHrJvLPL4eX-xapGow_alAelvYrXzC/view?usp=sharing",
    },
    {
      title: "Herd health management",
      link:
        "https://drive.google.com/file/d/1Jpe1_W4keeRdMmj0yfvdOh998lIaMLB-/view?usp=sharing",
    },
    {
      title: "Managing during Kidding",
      link:
        "https://drive.google.com/file/d/1qcZXbr4m3XtQ8Wy6Mq2jamei31EIXkuT/view?usp=sharing",
    },
    {
      title: "Basic Goat Production ",
      link:
        "https://drive.google.com/file/d/1C-hbjRP-k4hiSnD32MLm5kKYB6R2J4GX/view?usp=sharing",
    },
    {
      title: "Record Keeping ",
      link:
        "https://drive.google.com/file/d/19P6LRF4uxu2o1wdrjpFCFzsDskOciU2v/view?usp=sharing",
    },
    {
      title: "District Veterinary Contacts ",
      link:
        "https://drive.google.com/file/d/1nHhaBegnXst93J8TZqrG8wAY3Jzktbwy/view?usp=sharing",
    },
    {
      title: "Abortion in Goats",
      link: `${process.env.PUBLIC_URL}/assets/files/Abortion_in_goats.pdf`,
    },
    {
      title: "Managing Perinatal Mortality in Goats",
      link: `${process.env.PUBLIC_URL}/assets/files/Managing_perinatal_mortality_in_goats.pdf`,
    },
  ];
  return (
    <LayoutFour>
      <Breadcrumb title="Resources">
        <BreadcrumbItem name="Resources" />
        <BreadcrumbItem name="Knowledge bank" current />
      </Breadcrumb>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <table className="">
              <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "1%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((_row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "1.0em .9375em",
                        paddingLeft: "0.9375em",
                        borderBottom: "1px solid #e1e1e1",
                      }}
                    >
                      {_row.title}
                    </td>
                    <td
                      style={{
                        padding: "1.0em .9375em",
                        paddingLeft: "0.9375em",
                        borderBottom: "1px solid #e1e1e1",
                      }}
                    >
                      {" "}
                      <a
                        href={_row.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read/Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </LayoutFour>
  );
}
