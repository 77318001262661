/* eslint-disable no-console */
import axios from "axios";
export const BASE_URL = "https://api.menigtefarms.com/api/";
// export const BASE_URL = "https://mefarm.herokuapp.com/api/";
axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log("axios error:", error.response);
    if (error.response.status === 401) {
      // console.log("axios error 401");
    }

    return Promise.reject(error);
  }
);

export const request = async ({ url, opt }, parseJSON = true) => {
  const request = axios({ ...opt, url: url });
  if (parseJSON) {
    return request
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // if (isFile) return response.;

          return response.data;
        } else {
          throw response;
        }
      })
      .catch((error) => {
        throw error.response;
      });
  }
  return request;
};

export const requestOptions = (
  method = "GET",
  token = "",
  customHeaders = {},
  opt = {},
  postFormData = false
) => {
  const reqHeaders = !postFormData
    ? {
        "Content-Type": "application/json",
      }
    : {};

  if (token) {
    reqHeaders["Authorization"] = `Bearer ${token}`;
  }
  return {
    method: method,
    headers: {
      ...reqHeaders,
      ...customHeaders,
    },
    ...opt,
  };
};

export default {
  request,
  requestOptions,
  BASE_URL,
};
