/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";
import FooterOne from "../Footer/FooterOne";
import withScrollFixed from "../../common/withScrollFixed";
import HeaderOne from "../Header/HeaderOne";

let ScrollFixedHeader = withScrollFixed(HeaderOne);

export default function LayoutOne(props) {
  return (
    <>
      <Helmet>
        <title>{props.title || "Mefarm | My Smart Farm Agent"}</title>
      </Helmet>
      <ScrollFixedHeader
        container={props.container}
        menuData={props.menuData}
        className={props.className}
        menuStyle={props.menuStyle}
        hide={props.hide}
      />
      {props.children}
      <FooterOne />
    </>
  );
}
