import { CATEGORY } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  category: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case CATEGORY.ADD_CATEGORY:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CATEGORY.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case CATEGORY.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case CATEGORY.FIND_CATEGORY:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CATEGORY.FIND_CATEGORY_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case CATEGORY.FIND_CATEGORY_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case CATEGORY.UPDATE_CATEGORY:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CATEGORY.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case CATEGORY.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case CATEGORY.GET_CATEGORY:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CATEGORY.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        category: action.payload,
      };
    case CATEGORY.GET_CATEGORY_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case CATEGORY.REMOVE_CATEGORY:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CATEGORY.REMOVE_CATEGORY_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case CATEGORY.REMOVE_CATEGORY_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
