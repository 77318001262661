import { GUPDATE } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  gupdates: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function gupdateReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case GUPDATE.ADD_GUPDATE:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case GUPDATE.ADD_GUPDATE_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case GUPDATE.ADD_GUPDATE_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case GUPDATE.FIND_GUPDATE:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case GUPDATE.FIND_GUPDATE_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case GUPDATE.FIND_GUPDATE_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case GUPDATE.UPDATE_GUPDATE:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case GUPDATE.UPDATE_GUPDATE_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case GUPDATE.UPDATE_GUPDATE_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case GUPDATE.GET_GUPDATE:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case GUPDATE.GET_GUPDATE_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        gupdates: action.payload,
      };
    case GUPDATE.GET_GUPDATE_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case GUPDATE.REMOVE_GUPDATE:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case GUPDATE.REMOVE_GUPDATE_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        // gupdates: state.gupdates.filter(
        //   (_gupdate) => _gupdate.id !== action.payload
        // ),
      };
    case GUPDATE.REMOVE_GUPDATE_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
