import { dataMachine } from "./dataMachine";
import { API, productAPI } from "../api";

export const fetchInvestmentStockMachine = dataMachine(
  "fetchInvestmentStockMachine"
).withConfig({
  services: {
    fetchData: (cxt, evt) => {
      return API.request(
        productAPI.getProducts(evt.data.token, evt.data.payload)
      );
    },
  },
});
