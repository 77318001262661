import { requestOptions } from "./request";

const createInvestor = (data) => {
  console.log("Data: ", data);
  return {
    url: "investors/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const updateInvestor = (token, data) => {
  return {
    url: "investors/",
    opt: requestOptions("PUT", token, {}, { data: JSON.stringify(data) }),
  };
};

const getInvestors = (token, data) => {
  return {
    url: "investors/",
    opt: requestOptions("GET", token, {}, { data: JSON.stringify(data) }),
  };
};

const findInvestor = (token, investorId) => {
  return {
    url: "investors/" + investorId,
    opt: requestOptions("GET", token),
  };
};

const removeInvestor = (token, investorId) => {
  return {
    url: "investors/" + investorId,
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createInvestor,
  updateInvestor,
  findInvestor,
  removeInvestor,
  getInvestors,
};
