import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, postsAPI } from "../../api";
import {
  createPostFailure,
  createPostSucess,
  updatePostSucess,
  updatePostFailure,
  findPostSucess,
  removePostSucess,
  removePostFailure,
  findPostFailure,
  getPostSucess,
  getPostFailure,
} from "../actions/postsActions";
import { POSTS } from "../variables";
function* createPost({ payload }) {
  try {
    const { token } = yield select(({ authReducer }) => authReducer);
    const postsData = yield call(
      API.request,
      postsAPI.createPost(token, payload)
    );
    if (!postsData) {
      throw new Error("Request failed");
    }

    yield put(createPostSucess(postsData));
    yield call(getPost, { payload: { topic: payload.topic } });
  } catch (error) {
    yield put(createPostFailure());
  }
}

function* updatePost({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const postsData = yield call(
      API.request,
      postsAPI.updatePost(token, payload)
    );
    if (!postsData) {
      throw new Error("Request failed");
    }

    yield put(updatePostSucess(postsData));
    yield call(getPost, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(updatePostFailure());
  }
}

function* findPost({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const postsData = yield call(
      API.request,
      postsAPI.findPost(token, payload)
    );
    if (!postsData) {
      throw new Error("Request failed");
    }

    yield put(findPostSucess(postsData));
  } catch (error) {
    yield put(findPostFailure());
  }
}

function* getPost({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const postsData = yield call(
      API.request,
      postsAPI.getPosts(token, payload)
    );
    if (!postsData) {
      throw new Error("Request failed");
    }

    yield put(getPostSucess(postsData));
  } catch (error) {
    yield put(getPostFailure());
  }
}

function* removePost({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    yield call(API.request, postsAPI.removePost(token, payload));

    yield put(removePostSucess(payload));
    yield call(getPost, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(removePostFailure());
  }
}

export function* createPostRequest() {
  yield takeLatest(POSTS.ADD_POSTS, createPost);
}

export function* updatePostRequest() {
  yield takeLatest(POSTS.UPDATE_POSTS, updatePost);
}

export function* findPostRequest() {
  yield takeLatest(POSTS.FIND_POSTS, findPost);
}

export function* getPostRequest() {
  yield takeLatest(POSTS.GET_POSTS, getPost);
}

export function* removePostRequest() {
  yield takeLatest(POSTS.REMOVE_POSTS, removePost);
}

export default function* rootSaga() {
  yield all([
    fork(createPostRequest),
    fork(findPostRequest),
    fork(updatePostRequest),
    fork(getPostRequest),
    fork(removePostRequest),
  ]);
}
