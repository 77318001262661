import { assign, createMachine, send, sendParent } from "xstate";
import { dataMachine } from "./dataMachine";
import { API } from "../api";
import { requestOptions } from "../api/request";

const computeReturnOnInvestment = (
  count: number,
  goatCost: number,
  roi: number,
  investmentYears: number
) => {
  let yearInv = (multiplier = 1) => {
    const profit = (goatCost * count * multiplier * roi) / 100;
    const invValue = goatCost * count * multiplier;
    return {
      year: multiplier,
      totalAssets: count,
      roi: roi,
      profit: profit,
      investmentValue: invValue,
    };
  };

  let yearInvestments = [];

  for (let i = 0; i < investmentYears; i++) {
    yearInvestments.push(yearInv(i + 1));
  }
  const ttInv = goatCost * count * investmentYears;
  return {
    totalInvestment: ttInv,
    totalReturn: (ttInv * roi) / 100,
    totalProfit: ((ttInv * roi) / ttInv) * 100,
    yearReturns: yearInvestments,
  };
};

const createInvestmentProductMachine = dataMachine(
  "createInvestmentProductMachine"
).withConfig({
  actions: {
    setResponse: sendParent((context, event) => ({
      ...event,
      type: "SET_RESPONSE",
    })),
    setMessage: sendParent((context, event) => ({
      ...event,
      type: "SET_MESSAGE",
    })),
  },
  services: {
    createData: async (cxt, evt) => {
      if (!Object.prototype.hasOwnProperty.call(evt, "data")) {
        return;
      }
      return await API.request({
        url: "investments/",
        opt: requestOptions(
          "POST",
          evt.data.token,
          {},
          { data: JSON.stringify(evt.data.payload) }
        ),
      }).then((res) => res);
    },
  },
});

export const investmentCalculatorMachine = createMachine(
  {
    // Machine identifier
    id: "investmentCalculator",

    // Initial state
    initial: "initial",

    // Local context for entire machine
    context: {
      selectedGoatType: "pregnant",
      count: 0,
      investmentYears: 0,
      roi: 30,
      goatCost: 2000000,
      totalInvestment: 0,
      totalReturn: 0, // totalInvestment + profit
      totalProfit: 0, // profit alone
      yearReturns: [],
      message: {},
      response: {},
    },

    // State definitions
    states: {
      initial: {},
      goatTypeSelected: {},
      goatCountChanged: {},
      investmentYearsChanged: {},
      goatCostSet: {},
      submitting: {
        invoke: {
          id: "createInvestmentProductMachine",
          src: createInvestmentProductMachine,
          autoForward: true,
        },
        entry: send((context, event) => ({ ...event, type: "CREATE" }), {
          to: "createInvestmentProductMachine",
        }),
        on: {
          SET_RESPONSE: {
            target: "success",
            actions: assign((ctx, event) => ({
              response: event.data,
            })),
          },
          SET_MESSAGE: {
            target: "failure",
            actions: assign((ctx, event: any) => {
              return { message: event.data.data };
            }),
          },
        },
      },

      success: {
        type: "final",
      },
      failure: {
        on: {
          SUBMIT: "submitting",
        },
      },
    },
    on: {
      SUBMIT: {
        target: ".submitting",
      },

      SET_GOAT_COST: {
        target: ".goatCostSet",
        actions: ["setGoatCost"],
      },
      SET_GOAT_COUNT: {
        target: ".goatCountChanged",
        actions: ["setCount"],
      },
      SET_INVESTMENT_YEARS: {
        target: ".investmentYearsChanged",
        actions: ["setYears"],
      },
      SELECT_GOAT_TYPE: {
        target: ".goatTypeSelected",
        actions: ["setGoatType"],
      },
    },
  },
  {
    actions: {
      setGoatCost: assign((context, event) => {
        return {
          goatCost: event.value,
          ...computeReturnOnInvestment(
            context.count,
            event.value,
            context.roi,
            context.investmentYears
          ),
        };
      }),
      setGoatType: assign((context, event) => {
        return {
          selectedGoatType: event.value,
          ...computeReturnOnInvestment(
            context.count,
            context.goatCost,
            context.roi,
            context.investmentYears
          ),
        };
      }),

      setYears: assign((context, event) => {
        return {
          investmentYears: event.value,
          ...computeReturnOnInvestment(
            context.count,
            context.goatCost,
            context.roi,
            event.value
          ),
        };
      }),
      setCount: assign((context, event) => {
        return {
          count: event.value,
          ...computeReturnOnInvestment(
            event.value,
            context.goatCost,
            context.roi,
            context.investmentYears
          ),
        };
      }),
    },
  }
);
