import { dataMachine } from "./dataMachine";
import { API } from "../api";
import { requestOptions } from "../api/request";
export const updateInvestmentProductsMachine = dataMachine(
  "updateInvestmentProductsMachine"
).withConfig({
  services: {
    updateData: (cxt, evt) => {
      return API.request({
        url: "investments/" + evt.data.payload.id + "/",
        opt: requestOptions(
          "PATCH",
          evt.data.token,
          {},
          { data: JSON.stringify(evt.data.payload) }
        ),
      });
    },
  },
});
