/* eslint-disable react/prop-types */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Input from "../../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";
const entryScheme = Yup.object().shape({
  name: Yup.string().required().label("Name"),
});

const EntryForm = ({ toggle, className, isOpen, onSubmit, initialValues }) => {
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal centered isOpen={isOpen} className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={entryScheme}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          setTimeout(() => {
            onSubmit(values);
            actions.setSubmitting(false);
            toggle();
          }, 1000);
        }}
      >
        {(props) => {
          return (
            <div className="entry-form">
              <form onSubmit={props.handleSubmit}>
                <ModalHeader toggle={toggle} close={closeBtn}>
                  {Object.prototype.hasOwnProperty.call(initialValues, "id")
                    ? " Update Category"
                    : "Create Category"}
                </ModalHeader>
                <ModalBody>
                  <Input
                    type="text"
                    name="name"
                    value={props.values.name}
                    placeholder="Name"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    type="submit"
                    className="btn btn-sm p-2"
                    disabled={props.isSubmitting}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn btn-sm p-2"
                    color="secondary"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EntryForm;
