import { PRODUCT } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  products: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function farmerReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case PRODUCT.ADD_PRODUCT:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case PRODUCT.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case PRODUCT.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case PRODUCT.FIND_PRODUCT:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case PRODUCT.FIND_PRODUCT_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case PRODUCT.FIND_PRODUCT_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case PRODUCT.UPDATE_PRODUCT:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case PRODUCT.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case PRODUCT.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case PRODUCT.GET_PRODUCT:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case PRODUCT.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        products: action.payload,
      };
    case PRODUCT.GET_PRODUCT_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case PRODUCT.REMOVE_PRODUCT:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case PRODUCT.REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case PRODUCT.REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
