/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import jwtDecode from "jwt-decode";
// import menuData from "../../data/header/navigation.json";
import LayoutFour from "../../components/Layout/LayoutFour";
import GeneralUpdatesPage from "./gupdates";
import FarmerPage from "./farmers";
import StockPage from "./stock";
import UserPage from "./users";
import TransactionsPage from "./transactions";
import UserProfilePage from "./profile";
import CategoryPage from "./category";
import OrdersPage from "./transactions";
import InvestorPage from "./investors";
import InvestmentStockPage from "./investment/stock";
import InvestmentProductPage from "./investment/product";
import BlogPage from "./blog";
import BlogCreatePage from "./blog/New";
import BlogEditPage from "./blog/Edit";
import OrdersDetailPage from "./transactions/OrderDetail";
import { createTokenHash } from "../../utils/generateHash";

// eslint-disable-next-line react/prop-types
const adminData = [
  { title: "MeUpdates", to: "/admin" },
  { title: "Blog", to: "/admin/blog" },
  { title: "Stock", to: "/admin/stock" },
  { title: "Farmers", to: "/admin/farmers" },
  { title: "Orders", to: "/admin/orders" },
  { title: "Investors", to: "/admin/investors" },
  { title: "Investments", to: "/admin/investments/orders" },
];

const farmerData = [
  { title: "MeUpdates", to: "/admin" },
  { title: "Stock", to: "/admin/stock" },
  { title: "Orders", to: "/admin/orders" },
];

const investorData = [
  { title: "MeUpdates", to: "/admin" },
  { title: "Investments", to: "/admin/investments" },
  { title: "Stock", to: "/admin/investment/stock" },
];

const investorFarmerData = [
  { title: "MeUpdates", to: "/admin" },
  { title: "MeStock", to: "/admin/stock" },
  { title: "MeOrders", to: "/admin/orders" },
  { title: "MeInvestments", to: "/admin/investments" },
  { title: "Investment Stock", to: "/admin/investment/stock" },
];

const generalData = [{ title: "General Updates", to: "/admin" }];

const EGOAT_PORTAL = "https://portal.menigtefarms.com/";
const AdminPage = ({ match, token, push, location, user }) => {
  useEffect(() => {
    if (token !== "" && token !== undefined) {
      const isTokenValid = jwtDecode(token).exp > Date.now() / 1000;
      if (!isTokenValid) {
        push("/login");
      }

      // push(`${location.pathname}${location.search}`);
    } else {
      push("/login");
    }
    return () => {};
  }, [push, token, location.pathname, location.search]);

  const menuData = () => {
    const {
      is_admin,
      is_superuser,

      is_a_farmer,
      is_an_investor,
      is_public_user,
    } = user;

    if (is_public_user) return generalData;
    else if (is_an_investor && is_a_farmer) {
      const cipherText = createTokenHash(JSON.stringify({ token, user }));
      return [
        ...investorFarmerData,
        {
          title: "eGoat",
          to: `${EGOAT_PORTAL}?acr=${cipherText}`,
          isExternal: true,
        },
      ];
    } else if (is_a_farmer) {
      const cipherText = createTokenHash(JSON.stringify({ token, user }));
      return [
        ...farmerData,
        {
          title: "eGoat",
          to: `${EGOAT_PORTAL}?acr=${cipherText}`,
          isExternal: true,
        },
      ];
    } else if (is_admin || is_superuser) return adminData;
    else if (is_an_investor) return investorData;
    return generalData;
  };
  return (
    <LayoutFour
      title="Mefarms | Home"
      menuData={menuData()}
      className="-white"
      menuStyle="-style-3"
      hide="search cart"
      isAdmin
    >
      <div className="container">
        <Switch>
          <Route exact path={`${match.url}/`} component={GeneralUpdatesPage} />
          <Route path={`${match.url}/farmers`} component={FarmerPage} />
          <Route
            path={`${match.url}/transactions`}
            component={TransactionsPage}
          />
          <Route path={`${match.url}/user`} component={UserPage} />
          <Route exact path={`${match.url}/stock`} component={StockPage} />
          <Route
            path={`${match.url}/profile/:id`}
            component={UserProfilePage}
          />
          <Route
            path={`${match.url}/stock/categories`}
            component={CategoryPage}
          />
          <Route exact path={`${match.url}/orders`} component={OrdersPage} />
          <Route
            exact
            path={`${match.url}/investors`}
            component={InvestorPage}
          />
          <Route
            path={`${match.url}/investment/stock`}
            component={InvestmentStockPage}
          />
          <Route
            path={`${match.url}/investments`}
            component={InvestmentProductPage}
          />
          <Route
            path={`${match.url}/investments/orders`}
            component={InvestmentProductPage}
          />
          <Route
            path={`${match.url}/orders/:id`}
            component={OrdersDetailPage}
          />

          <Route exact path={`${match.url}/blog`} component={BlogPage} />
          <Route path={`${match.url}/blog/new`} component={BlogCreatePage} />
          <Route path={`${match.url}/blog/edit/:id`} component={BlogEditPage} />
        </Switch>
      </div>
    </LayoutFour>
  );
};

AdminPage.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = ({ authReducer, router }) => {
  const { token, user } = authReducer;
  const { location } = router;
  return {
    token,
    location,
    user,
  };
};

export default connect(mapStateToProps, { push })(AdminPage);
