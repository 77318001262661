import { requestOptions } from "./request";

const createUser = (token, data) => {
  return {
    url: "users/",
    opt: requestOptions("POST", token, {}, { data: JSON.stringify(data) }),
  };
};

const updateUser = (token, data) => {
  return {
    url: "users/" + data.id + "/",
    opt: requestOptions("PATCH", token, {}, { data: JSON.stringify(data) }),
  };
};

const getUsers = (token, data) => {
  return {
    url: "users/",
    opt: requestOptions("GET", token, {}, { data: JSON.stringify(data) }),
  };
};

const findUser = (token, userId) => {
  return {
    url: "users/" + userId + "/",
    opt: requestOptions("GET", token),
  };
};

const findAssocFarmer = (token, userId) => {
  console.log("userId", userId);
  return {
    url: "users/farmer/" + userId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeUser = (token, userId) => {
  return {
    url: "users/" + userId + "/",
    opt: requestOptions("DELETE", token),
  };
};

const addInvestorProfileToUser = (token, farmerId) => {
  return {
    url: "users/" + farmerId + "/become-an-invester/",
    opt: requestOptions("POST", token),
  };
};

export default {
  createUser,
  updateUser,
  findUser,
  removeUser,
  getUsers,
  findAssocFarmer,
  addInvestorProfileToUser,
};
