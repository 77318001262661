import { FARMER } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  farmers: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  farmer: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function farmerReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: initialState.is,
      };
    case FARMER.ADD_FARMER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case FARMER.ADD_FARMER_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case FARMER.ADD_FARMER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case FARMER.FIND_FARMER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case FARMER.FIND_FARMER_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        farmer: action.payload,
      };
    case FARMER.FIND_FARMER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case FARMER.UPDATE_FARMER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case FARMER.UPDATE_FARMER_SUCCESS:
      return {
        ...state,
        farmer: action.payload,
        is: { ...initialState.is, ok: true },
      };
    case FARMER.UPDATE_FARMER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case FARMER.GET_FARMER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case FARMER.GET_FARMER_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        farmers: action.payload,
      };
    case FARMER.GET_FARMER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case FARMER.REMOVE_FARMER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case FARMER.REMOVE_FARMER_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        // farmers: state.farmers.filter(
        //   (_farmer) => _farmer.id !== action.payload
        // ),
      };
    case FARMER.REMOVE_FARMER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
