import { CATEGORY } from "../variables";

export const createCategory = (data) => ({
  type: CATEGORY.ADD_CATEGORY,
  payload: data,
});

export const createCategorySucess = (data) => ({
  type: CATEGORY.ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const createCategoryFailure = (data) => ({
  type: CATEGORY.ADD_CATEGORY_FAILURE,
  payload: data,
});

export const updateCategory = (data) => ({
  type: CATEGORY.UPDATE_CATEGORY,
  payload: data,
});

export const updateCategorySucess = (data) => ({
  type: CATEGORY.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export const updateCategoryFailure = (data) => ({
  type: CATEGORY.UPDATE_CATEGORY_FAILURE,
  payload: data,
});

export const removeCategory = (data) => ({
  type: CATEGORY.REMOVE_CATEGORY,
  payload: data,
});

export const removeCategorySucess = (data) => ({
  type: CATEGORY.REMOVE_CATEGORY_SUCCESS,
  payload: data,
});

export const removeCategoryFailure = (data) => ({
  type: CATEGORY.REMOVE_CATEGORY_FAILURE,
  payload: data,
});

export const findCategory = (data) => ({
  type: CATEGORY.FIND_CATEGORY,
  payload: data,
});

export const findCategorySucess = (data) => ({
  type: CATEGORY.FIND_CATEGORY_SUCCESS,
  payload: data,
});

export const findCategoryFailure = (data) => ({
  type: CATEGORY.FIND_CATEGORY_FAILURE,
  payload: data,
});

export const getCategory = (filters) => ({
  type: CATEGORY.GET_CATEGORY,
  payload: filters,
});

export const getCategorySucess = (data) => ({
  type: CATEGORY.GET_CATEGORY_SUCCESS,
  payload: data,
});

export const getCategoryFailure = (data) => ({
  type: CATEGORY.GET_CATEGORY_FAILURE,
  payload: data,
});
