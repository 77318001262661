import { requestOptions } from "./request";

const loginUser = (data) => {
  return {
    url: "auth/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const registerPublicUser = (data) => {
  return {
    url: "auth/public/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const authChangePwd = (data) => {
  return {
    url: "auth/password/change/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const authforgotPwd = (data) => {
  return {
    url: "auth/password/request/reset/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const authResetPwd = (data) => {
  return {
    url: "auth/password/reset/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const authSetPwd = (data) => {
  return {
    url: `auth/password/set/${data.userId}/`,
    opt: requestOptions(
      "POST",
      null,
      {},
      { data: JSON.stringify({ password: data.password }) }
    ),
  };
};

export default {
  loginUser,
  authChangePwd,
  authResetPwd,
  authSetPwd,
  authforgotPwd,
  registerPublicUser,
};
