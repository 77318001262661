import { PRODUCT } from "../variables";

export const createProduct = (data) => ({
  type: PRODUCT.ADD_PRODUCT,
  payload: data,
});

export const createProductSucess = (data) => ({
  type: PRODUCT.ADD_PRODUCT_SUCCESS,
  payload: data,
});

export const createProductFailure = (data) => ({
  type: PRODUCT.ADD_PRODUCT_FAILURE,
  payload: data,
});

export const updateProduct = (data) => ({
  type: PRODUCT.UPDATE_PRODUCT,
  payload: data,
});

export const updateProductSucess = (data) => ({
  type: PRODUCT.UPDATE_PRODUCT_SUCCESS,
  payload: data,
});

export const updateProductFailure = (data) => ({
  type: PRODUCT.UPDATE_PRODUCT_FAILURE,
  payload: data,
});

export const removeProduct = (data) => ({
  type: PRODUCT.REMOVE_PRODUCT,
  payload: data,
});

export const removeProductSucess = (data) => ({
  type: PRODUCT.REMOVE_PRODUCT_SUCCESS,
  payload: data,
});

export const removeProductFailure = (data) => ({
  type: PRODUCT.REMOVE_PRODUCT_FAILURE,
  payload: data,
});

export const findProduct = (data) => ({
  type: PRODUCT.FIND_PRODUCT,
  payload: data,
});

export const findProductSucess = (data) => ({
  type: PRODUCT.FIND_PRODUCT_SUCCESS,
  payload: data,
});

export const findProductFailure = (data) => ({
  type: PRODUCT.FIND_PRODUCT_FAILURE,
  payload: data,
});

export const getProduct = (data) => ({
  type: PRODUCT.GET_PRODUCT,
  payload: data,
});

export const getProductSucess = (data) => ({
  type: PRODUCT.GET_PRODUCT_SUCCESS,
  payload: data,
});

export const getProductFailure = (data) => ({
  type: PRODUCT.GET_PRODUCT_FAILURE,
  payload: data,
});
