import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getTopic } from "../../redux/actions/topicActions";
// import SubcribeEmail from "../Other/SubcribeEmail";
import { useSelector, useDispatch } from "react-redux";
import SocialIcons from "../Other/SocialIcons";
import footerLinks from "../../data/footer/links.json";
import footerInfomation from "../../data/footer/info.json";

export default function FooterOne() {
  const { topics } = useSelector(({ topicsReducer }) => topicsReducer);
  const token = useSelector(({ authReducer }) => authReducer.token);
  const dispatch = useDispatch();

  const pageLimit = 4;

  useEffect(() => {
    dispatch(
      getTopic({
        limit: pageLimit,
        sort: "newest",
      })
    );
    return () => {};
  }, [dispatch]);
  return (
    <div className="footer-one">
      <div className="container">
        <div className="footer-one__header">
          {token === "" && (
            <>
              <div className="footer-one__header__newsletter">
                <span>
                  Have no account yet? <Link to="/register">subscribe</Link>
                </span>
              </div>
              <div className="footer-one__header__social">
                <SocialIcons className="-border" />
              </div>
            </>
          )}
        </div>

        <div className="footer-one__body">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="footer__section -info">
                <h5 className="footer-title">Contact info</h5>
                <div className="row">
                  <div className="col-3">
                    <p>Address: </p>
                  </div>
                  <div className="col-9">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: footerInfomation.address,
                      }}
                    ></span>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-3">
                    <p>Phone: </p>
                  </div>
                  <div className="col-9">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: footerInfomation.phone,
                      }}
                    ></span>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-3">
                    <p>Email: </p>
                  </div>
                  <div className="col-9">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: footerInfomation.email,
                      }}
                    ></span>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-3">
                    <p>Opentime: </p>
                  </div>
                  <div className="col-9">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: footerInfomation.open,
                      }}
                    ></span>
                  </div>
                </div>
                {/* <p>
                  Email: <span>{footerInfomation.email}</span>
                </p>
                <p>
                  Opentime: <span>{footerInfomation.open}</span>
                </p> */}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="footer__section -links">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <h5 className="footer-title">Account</h5>
                    <ul>
                      {footerLinks.accountLinks.map((link, index) => (
                        <li key={index}>
                          {link.to ? (
                            <Link to={link.to}>{link.name}</Link>
                          ) : (
                            <a
                              href={link.href}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {link.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-12 col-sm-6">
                    <h5 className="footer-title">Infomation</h5>
                    <ul>
                      {footerLinks.informationLinks.map((link, index) => (
                        <li key={index}>
                          {link.to ? (
                            <Link to={link.to}>{link.name}</Link>
                          ) : (
                            <a
                              href={link.href}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {link.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="footer__section -payment">
                <h5 className="footer-title">Forum Posts</h5>
                {topics.count ? (
                  topics.results.map((topic) => (
                    <Link key={topic.id} to={`/forum/${topic.id}/discussion`}>
                      <p>{topic.subject}</p>
                    </Link>
                  ))
                ) : (
                  <div>
                    <p>Content not yet available!</p>
                    <p>...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-one__footer">
        <div className="container">
          <div className="footer-one__footer__wrapper">
            <p>© Copyright 2020 Hive Technologies Ltd</p>
            <ul>
              {footerLinks.creditLinks.map((link, index) => (
                <li key={index}>
                  {link.to ? (
                    <Link to={link.to}>{link.name}</Link>
                  ) : (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {link.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
