import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

import menuData from "../../../data/header/navigation.json";

export default function Navigator() {
  const [dropdownItem, setDropdownItem] = useState();
  function renderMenu() {
    return menuData.map((item, index) => {
      if (item.title === "Services") {
        return (
          <li className="relative" key={index}>
            <Link
              onClick={() => {
                if (dropdownItem === "services") {
                  setDropdownItem("");
                  return;
                }
                setDropdownItem("services");
              }}
            >
              {item.title}
              <span className="dropable-icon">
                <i
                  className={`fas ${
                    dropdownItem === "services"
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  }`}
                ></i>
              </span>
            </Link>
            <CSSTransition
              in={dropdownItem === "services"}
              unmountOnExit
              timeout={200}
              classNames="dropdown-menu-custom-mobile"
            >
              <ul className="dropdown-menu-custom">
                {item.subMenu.map((i, index) => (
                  <li key={index}>
                    <Link to={`${process.env.PUBLIC_URL}${i.to}`}>
                      {i.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </CSSTransition>
          </li>
        );
      }
      if (item.title === "Shop") {
        return (
          <li className="relative" key={index}>
            <Link
              onClick={() => {
                if (dropdownItem === "shop") {
                  setDropdownItem("");
                  return;
                }
                setDropdownItem("shop");
              }}
            >
              {item.title}
              <span className="dropable-icon">
                <i
                  className={`fas ${
                    dropdownItem === "shop" ? "fa-angle-up" : "fa-angle-down"
                  }`}
                ></i>
              </span>
            </Link>
            <CSSTransition
              in={dropdownItem === "shop"}
              unmountOnExit
              timeout={200}
              classNames="dropdown-menu-custom-mobile"
            >
              <ul className="dropdown-menu-custom">
                {item.subMenu.map((i, index) => (
                  <li key={index}>
                    <Link to={`${process.env.PUBLIC_URL}${i.to}`}>
                      {i.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </CSSTransition>
          </li>
        );
      }

      return (
        <li key={index}>
          <Link to={item.to}>{item.title}</Link>
        </li>
      );
    });
  }
  return (
    <div className="navigator-mobile">
      <ul>{renderMenu()}</ul>
    </div>
  );
}
