/* eslint-disable react/prop-types */
import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { createFarmer } from "../../redux/actions/farmerActions";
import { registerPublicUser } from "../../redux/actions/authActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import Input from "../../components/Control/Input";
import { createInvestor } from "../../redux/actions/investorActions";

const REGISTER_INITIAL = {
  email: "",
  first_name: "",
  last_name: "",
  address: "",
  town_village: "",
  phone: "",
  as: "farmer",
  password: "",
  gender: "",
};

const registerScheme = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  first_name: Yup.string().required().label("First Name"),
  last_name: Yup.string().required().label("Last Name"),
  phone: Yup.string().required().label("Contact"),
  address: Yup.string().required().label("Address"),
  town_village: Yup.string().required().label("Village"),
  as: Yup.string().required(),
  password: Yup.string(),
  gender: Yup.string().required().label("Gender"),
});

const messages = {
  processing: "Processing request! ...",
  ok:
    "Request successful! A link to set password for your account has  been sent to your email",
  error: "Request failed, try again!",
};

function Register({
  processing,
  ok,
  error,
  createFarmer,
  createInvestor,
  registerPublicUser,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const history = useHistory();
  const investorState = useSelector((state) => state.investorReducer);

  const changeQueryPath = (value) => {
    history.push({
      pathname: location.pathname,
      search: `?as=${value}`,
    });
  };

  // useEffect(() => {
  //   if (investorState.is.ok && !investorState.is.error) {
  //     history.push("/login");
  //   }
  // }, [history, investorState]);

  return (
    <LayoutFour>
      <Breadcrumb title="Subscribe" />
      <div className="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body mt-2">
                  <h2 className="card-title mb-3">Register form</h2>
                  <p className="card-text mb-2 text-muted">
                    Create account as a:
                  </p>
                  <br />
                  <div className="entry-form">
                    {(processing || investorState.is.processing) && (
                      <div className="alert alert-primary" role="alert">
                        {messages.processing}
                      </div>
                    )}

                    {(error || investorState.is.error) && (
                      <div className="alert alert-danger" role="alert">
                        {messages.error}
                      </div>
                    )}

                    {(ok || investorState.is.ok) && (
                      <div className="alert alert-success" role="alert">
                        {messages.ok}
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        ...REGISTER_INITIAL,
                        as: searchParams.get("as") ?? "farmer",
                      }}
                      validationSchema={registerScheme}
                      onSubmit={(values, actions) => {
                        if (values.as === "other" && values.password === "") {
                          actions.setFieldError(
                            "password",
                            "Password is Required!"
                          );
                        }
                        setTimeout(() => {
                          if (values.as === "other") {
                            delete values.as;
                            registerPublicUser(
                              { ...values, is_active: true },
                              "/blog"
                            );
                          } else {
                            delete values.password;
                            if (values.as === "farmer") {
                              delete values.as;
                              createFarmer(values);
                            } else if (values.as === "investor") {
                              delete values.as;
                              createInvestor({
                                ...values,
                                password: "password",
                                country: "Uganda",
                              });
                            }
                          }
                          actions.setSubmitting(false);
                        }, 1000);
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <FormGroup tag="fieldset" check>
                            <Input
                              type="radio"
                              name="as"
                              value="farmer"
                              checked={props.values.as === "farmer"}
                              label="Farmer"
                              onChange={(evt) => {
                                props.setFieldValue("as", evt.target.value);
                                changeQueryPath(evt.target.value);
                              }}
                            />
                            <Input
                              type="radio"
                              name="as"
                              checked={props.values.as === "investor"}
                              value="investor"
                              label="Investor"
                              onChange={(evt) => {
                                props.setFieldValue("as", evt.target.value);
                                changeQueryPath(evt.target.value);
                              }}
                            />
                            <Input
                              type="radio"
                              name="as"
                              value="other"
                              checked={props.values.as === "other"}
                              label="Other - Contributor (Forum) / MeMarket Buyer"
                              onChange={(evt) => {
                                props.setFieldValue("as", evt.target.value);
                                changeQueryPath(evt.target.value);
                              }}
                            />
                          </FormGroup>
                          <div className="row form">
                            <div className="col-6">
                              <Input
                                type="text"
                                name="first_name"
                                className="form-control"
                                placeholder="First name"
                              />
                            </div>
                            <div className="col-6">
                              <Input
                                type="text"
                                name="last_name"
                                className="form-control"
                                placeholder="Last name"
                              />
                            </div>
                          </div>
                          <Input
                            type="select"
                            name="gender"
                            className="form-control"
                            style={{ borderRadius: 0, fontSize: "0.8125rem" }}
                            value={props.values.gender}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Input>
                          <div className="row form">
                            <div className="col-6">
                              <Input
                                type="text"
                                name="address"
                                className="form-control"
                                placeholder="Address"
                              />
                            </div>
                            <div className="col-6">
                              <Input
                                type="text"
                                name="town_village"
                                className="form-control"
                                placeholder="Village"
                              />
                            </div>
                          </div>

                          <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                          />
                          <FormGroup>
                            <PhoneInput
                              inputProps={{
                                name: "phone",
                                required: true,
                                // autoFocus: true,
                              }}
                              value={props.values.phone}
                              onChange={(phone) =>
                                props.setFieldValue("phone", "+" + phone)
                              }
                              onBlur={props.handleBlur}
                              autocompleteSearch
                              enableSearch
                              inputStyle={{ paddingLeft: "48px" }}
                              placeholder="256 775 778 778 888"
                            />
                          </FormGroup>
                          {props.values.as === "other" && (
                            <Input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Password"
                            />
                          )}
                          <button
                            className="btn -dark"
                            disabled={props.isSubmitting}
                          >
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-12 mt-4">
                      Have an account ? <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </LayoutFour>
  );
}
const mapStateToProps = ({ farmerReducer }) => {
  const { is } = farmerReducer;
  return {
    ...is,
  };
};
export default connect(mapStateToProps, {
  createFarmer,
  createInvestor,
  registerPublicUser,
})(Register);
