import React from "react";
import { useMachine } from "@xstate/react";
import { Container } from "reactstrap";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import { investmentCalculatorMachine } from "../../machines/investmentCalculatorMachine";
import { formatCurrency } from "../../common/utils";
import { Link } from "react-router-dom";

const InvestorPage = () => {
  const [state, send] = useMachine(investmentCalculatorMachine, {
    devTools: false,
  });
  return (
    <LayoutFour title="Mefarms | Invest">
      <Breadcrumb title="Investment">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="invest" current />
      </Breadcrumb>

      <Container className="text-center">
        <h1 className="display-1">How much profits would want to earn</h1>
        <p className="lead">Try our NEW investment calculator…</p>
        <div className="container">
          <div className="row mt-5">
            <div className="col">
              <ol className="list-group list-group-numbered">
                <li className="list-group-item  d-flex flex-column justify-content-between align-items-start">
                  <h4 className="fw-bold">Choose Goat to invest in</h4>
                  <select
                    className="form-control form-control-md  form-select"
                    aria-label="Default select example"
                    onChange={(evt) =>
                      send({
                        type: "SELECT_GOAT_TYPE",
                        value: evt.target.value,
                      })
                    }
                    value={state.context.selectedGoatType}
                  >
                    <option value="pregnant">Pregnant Goat</option>
                    {/*<option value="regular">Regular Goat</option>*/}
                  </select>
                </li>
                <li className="list-group-item  d-flex flex-column justify-content-start align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">
                      How many goats would you like to start with?
                    </h4>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    onChange={(evt) =>
                      send({
                        type: "SET_GOAT_COUNT",
                        value: evt.target.value,
                      })
                    }
                    value={state.context.count}
                    className="form-control form-control-lg form-range"
                    id="goatCount"
                  />
                </li>
                <li className="list-group-item d-flex flex-column justify-content-start align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">
                      For how many years will you invest?
                    </h4>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    onChange={(evt) =>
                      send({
                        type: "SET_INVESTMENT_YEARS",
                        value: evt.target.value,
                      })
                    }
                    value={state.context.investmentYears}
                    className="form-control form-control-lg form-range"
                    id="investmentYears"
                  />
                </li>
                <li className="list-group-item d-flex flex-row justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">Rate of Return</h4>
                  </div>

                  <span className="badge bg-primary rounded-pill">
                    {state.context.roi}%
                  </span>
                </li>
              </ol>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">RETURNS (What I get back)</h4>
                </div>
                <ol className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Investment per Goat</div>
                    <span>{formatCurrency(state.context.goatCost)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Number of Goats</div>
                    <span>{state.context.count}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Years</div>
                    <span>{state.context.investmentYears}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Investment:</div>
                    <span>{formatCurrency(state.context.totalInvestment)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Return:</div>
                    <span>{formatCurrency(state.context.totalReturn)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Profit (%):</div>
                    <span>{state.context.roi}%</span>
                  </li>
                  {/*<li className="list-group-item d-flex justify-content-between align-items-start" />*/}
                </ol>

                {/*<button className="btn btn-sm btn-primary">*/}
                {/*  See break down*/}
                {/*</button>*/}
              </div>
            </div>
          </div>

          <div className={"row"}>
            {/*    table for assets breakdown per year */}
            <div className="col mt-5">
              <table className="table table-striped table-hover table-bordered">
                <caption>Investment breakdown per year</caption>
                <thead>
                  <tr>
                    <th>Years</th>
                    <th>Number of Goats</th>
                    <th>ROI</th>
                    <th>Profit</th>
                    <th>Investment Value</th>
                  </tr>
                </thead>

                <tbody id="tblBody">
                  {state.context.yearReturns.map((item) => (
                    <tr key={item.year}>
                      <td>{item.year}</td>
                      <td>{item.totalAssets}</td>
                      <td>{item.roi}%</td>
                      <td>{formatCurrency(item.profit)}</td>
                      <td>{formatCurrency(item.investmentValue)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*    register section */}
        <div>Invest in less than five minutes.</div>
        <div>
          Our Platform enables you to sign up, choose products you’d like to
          invest in and pay securely. You can pay via bank transfer, or mobile
          money.
        </div>
        <Link to={"/register?as=investor"} className="btn btn-light">
          Register to invest
        </Link>
      </Container>
    </LayoutFour>
  );
};

export default InvestorPage;
