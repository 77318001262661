/* eslint-disable react/prop-types */

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import truncateHtml from "truncate-html";
import draftToHtml from "draftjs-to-html";
import md5 from "md5";
const Topic = ({ data, removeTopicHandler }) => {
  const imgHash = md5(data.starter.email);
  const { user } = useSelector(({ authReducer }) => authReducer);
  return (
    <div className="element">
      <div className="discussion-summary">
        <div className="stats">
          <div className="answers">
            <strong>{data.comments}</strong> replies
          </div>
        </div>
        <div className="summary">
          <h3>
            <Link to={`/forum/${data.id}/discussion`}>{data.subject}</Link>
          </h3>
          <div className="excerpt">
            {data.body.includes("blocks") ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: truncateHtml(draftToHtml(JSON.parse(data.body)), 260),
                }}
              />
            ) : (
              data.body
            )}
          </div>
          <div className="topic">
            {/* <button
              type="button"
              className="btn btn-link btn-sm p-0 mt-3 mr-2"
              style={{ textTransform: "capitalize" }}
            >
              Edit
            </button> */}
            {user &&
            Object.prototype.hasOwnProperty.call(user, "id") &&
            (user.is_admin ||
              user.is_superuser ||
              user.id === data.starter.id) ? (
              <button
                type="button"
                className="btn btn-link btn-sm  p-0 mt-3"
                onClick={() => removeTopicHandler(data)}
                style={{ textTransform: "capitalize", color: "red" }}
              >
                Delete
              </button>
            ) : null}
            {/* <a className="tag" href="/view/discussions/tagged/porta">
              porta
            </a> */}
          </div>
          <div className="user-info">
            <div className="when">
              {`${moment(data.created_at).format("MMM DD")} at ${moment(
                data.created_at
              ).format("HH:mm")}`}
            </div>

            <div className="p-info">
              <img
                src={`https://www.gravatar.com/avatar/${imgHash}?s=64&d=identicon&r=PG`}
                alt=""
                width="32"
                height="32"
              />

              <div
                className="details pl-2"
                style={{ textTransform: "capitalize" }}
              >
                {Object.prototype.hasOwnProperty.call(data.starter, "id")
                  ? data.starter.first_name
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topic;
