/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import Paginator from "react-hooks-paginator";
import {
  getInvestor,
  removeInvestor,
} from "../../../redux/actions/investorActions";
import { updateUser } from "../../../redux/actions/userActions";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
// eslint-disable-next-line react/prop-types
const InvestorPages = ({
  investors,
  processing,
  ok,
  error,
  getInvestor,
  updateUser,
  userReducer,
  is_admin,
  removeInvestor,
  is_a_investor,
}) => {
  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [data, setData] = useState([]);

  useEffect(() => {
    getInvestor({ limit: pageLimit, offset });
    return () => {};
  }, [getInvestor, offset]);

  const handleRemoveInvestor = useCallback((userId) => removeInvestor(userId), [
    removeInvestor,
  ]);

  return (
    <LoadingOverlay
      active={processing || userReducer.is.processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="List of Registered Investors"
        >
          Investors
        </SectionTitleOne>
        <div className="col-md-4" />
      </div>
      {investors.results.length === 0 ? (
        <div className="wishlist__empty">
          <h3> You have no Investors!</h3>
        </div>
      ) : (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12 col-md-12 ">
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Address</th>
                  <th scope="col">Country</th>
                  <th scope="col">Contact</th>
                  {is_admin && <th scope="col">Actions</th>}
                </tr>
              </thead>
              <tbody>
                {investors &&
                  investors.results.map((_investor) => (
                    <tr key={_investor.id}>
                      {/* <th scope="row">1</th> */}
                      <td>{`${_investor.first_name} ${_investor.last_name}`}</td>
                      <td>{_investor.email}</td>
                      <td>{_investor.address}</td>
                      <td>{_investor.country}</td>
                      <td>{_investor.phone}</td>
                      {is_admin && (
                        <td>
                          <div className="btn-group" role="group">
                            <Link
                              type="button"
                              to={`/admin/profile/${_investor.id}?view=investor`}
                              className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                            >
                              Profile
                            </Link>
                            |
                            <button
                              type="button"
                              onClick={(evt) =>
                                handleRemoveInvestor(_investor.id)
                              }
                              className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={investors.count}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ investorReducer, userReducer, authReducer }) => {
  const { investors, is } = investorReducer;
  const { is_admin, is_a_investor } = authReducer.user;
  return {
    investors,
    ...is,
    userReducer,
    is_admin,
    is_a_investor,
  };
};
export default connect(mapStateToProps, {
  getInvestor,
  updateUser,
  removeInvestor,
})(InvestorPages);
