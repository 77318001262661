/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

import Navigator from "../Elements/Navigator";
import MenuFunctionIcons from "../Elements/MenuFunctionIcons";
import { renderContainer } from "../../../common/utils";
import menuDataDefault from "../../../data/header/navigation.json";
export default function MenuThree({
  hide,
  container,
  isAdmin = false,
  menuData = menuDataDefault,
}) {
  return (
    <div className="menu -style-3">
      <div className={renderContainer(container)}>
        <div className="menu__wrapper">
          <Link to="/" className="menu__wrapper__logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/logo-white.png"}
              alt="Logo"
            />
          </Link>
          <Navigator className="-white" menuData={menuData} />
          <MenuFunctionIcons
            hide={hide}
            white
            className="-white"
            isAdmin={isAdmin}
          />
        </div>
      </div>
    </div>
  );
}
