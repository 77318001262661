import React, { useEffect, useCallback } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { useRouter } from "../../../common/useRouter";
import { findOrder, updateOrder } from "../../../redux/actions/orderActions";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
const OrderDetail = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { order, is } = useSelector(({ ordersReducer }) => ordersReducer);
  const { user } = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    const id = router.query.id;
    dispatch(findOrder(id));
    return () => {};
  }, [dispatch, router]);

  const completeOrder = useCallback(
    (txn_status) => {
      // let newOrder = order;
      // newOrder.txn_status = "complete";
      dispatch(updateOrder({ id: order.id, txn_status }));
    },
    [dispatch, order]
  );

  return (
    <LoadingOverlay
      active={is.processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="Order Details"
        >
          Order {order.order_id}
        </SectionTitleOne>

        <div className="col-md-4">
          {(user.is_admin || user.is_superuser) &&
            order.txn_status !== "complete" && (
              <div
                className="btn-group"
                role="group"
                style={{ float: "right" }}
              >
                <button
                  onClick={() => completeOrder("complete")}
                  disabled={
                    order.txn_status === "complete" ||
                    order.txn_status === "cancelled"
                  }
                  className="btn btn-info btn-md pl-3 pr-3 pt-2 pb-2 mt-4 mr-2"
                >
                  Complete
                </button>
                <button
                  onClick={() => completeOrder("cancelled")}
                  disabled={
                    order.txn_status === "complete" ||
                    order.txn_status === "cancelled"
                  }
                  className="btn btn-warning btn-md pl-3 pr-3 pt-2 pb-2 mt-4 mr-2"
                >
                  Cancel
                </button>
              </div>
            )}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="table-responsive mb-4">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ border: "none", textTransform: "capitalize" }}>
                    <span style={{ color: "#6c757d" }}>Owner: </span>
                    <Link
                      to={`/admin/profile/${
                        order.owner && order.owner.id
                      }?view=user`}
                      className=" "
                    >
                      {order.owner &&
                        order.owner.first_name + " " + order.owner.last_name}
                    </Link>
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Coupon: </span>
                    {order.coupon}
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
                <tr>
                  <td style={{ border: "none", textTransform: "capitalize" }}>
                    <span style={{ color: "#6c757d" }}>Status: </span>{" "}
                    {order.txn_status}
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Order Note: </span>{" "}
                    {order.order_note}
                  </td>
                  <td style={{ border: "none" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-10">
          <div className="mb-4">
            <h4>Order Items</h4>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  #Code
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Name
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Quantity
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Price
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Breed
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Age
                </th>
                <th scope="col" style={{ color: "#6c757d" }}>
                  Pregnant
                </th>
                {(user.is_admin || user.is_superuser) && (
                  <th scope="col" style={{ color: "#6c757d" }}>
                    Farmer
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {order.products instanceof Array &&
                order.products.map((_product) => (
                  <tr key={_product.id}>
                    <td>
                      <img src={_product.photo} alt="ads" width="90" />
                    </td>
                    <td style={{ verticalAlign: "inherit" }}>
                      {_product.product_code}
                    </td>
                    <td
                      style={{ verticalAlign: "inherit" }}
                    >{`${_product.title}`}</td>
                    <td
                      style={{ verticalAlign: "inherit" }}
                    >{`${_product.quantity}`}</td>
                    <td style={{ verticalAlign: "inherit" }}>
                      UGX {_product.price}
                    </td>
                    <td style={{ verticalAlign: "inherit" }}>
                      {_product.breed}
                    </td>
                    <td style={{ verticalAlign: "inherit" }}>
                      {_product.age} months
                    </td>
                    <td style={{ verticalAlign: "inherit" }}>
                      {_product.is_pregnant ? "Yes" : "No"}
                    </td>

                    {(user.is_admin || user.is_superuser) && (
                      <td style={{ verticalAlign: "inherit" }}>
                        <Link
                          type="button"
                          to={`/admin/profile/${
                            Object.prototype.hasOwnProperty.call(
                              _product.user,
                              "id"
                            )
                              ? _product.user.id
                              : _product.user
                          }`}
                        >
                          Profile
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default OrderDetail;
