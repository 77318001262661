import queryString from "query-string";
import { requestOptions } from "./request";

const createProduct = (token, data) => {
  return {
    url: "products/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updateProduct = (token, data) => {
  return {
    url: "products/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getProducts = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "products/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findProduct = (token, productId) => {
  return {
    url: "products/" + productId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeProduct = (token, productId) => {
  return {
    url: "products/" + productId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createProduct,
  updateProduct,
  findProduct,
  removeProduct,
  getProducts,
};
