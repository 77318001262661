import { FARMER } from "../variables";

export const createFarmer = (data) => ({
  type: FARMER.ADD_FARMER,
  payload: data,
});

export const createFarmerSucess = (data) => ({
  type: FARMER.ADD_FARMER_SUCCESS,
  payload: data,
});

export const createFarmerFailure = (data) => ({
  type: FARMER.ADD_FARMER_FAILURE,
  payload: data,
});

export const updateFarmer = (data) => ({
  type: FARMER.UPDATE_FARMER,
  payload: data,
});

export const updateFarmerSucess = (data) => ({
  type: FARMER.UPDATE_FARMER_SUCCESS,
  payload: data,
});

export const updateFarmerFailure = (data) => ({
  type: FARMER.UPDATE_FARMER_FAILURE,
  payload: data,
});

export const removeFarmer = (data) => ({
  type: FARMER.REMOVE_FARMER,
  payload: data,
});

export const removeFarmerSucess = (data) => ({
  type: FARMER.REMOVE_FARMER_SUCCESS,
  payload: data,
});

export const removeFarmerFailure = (data) => ({
  type: FARMER.REMOVE_FARMER_FAILURE,
  payload: data,
});

export const findFarmer = (data) => ({
  type: FARMER.FIND_FARMER,
  payload: data,
});

export const findFarmerSucess = (data) => ({
  type: FARMER.FIND_FARMER_SUCCESS,
  payload: data,
});

export const findFarmerFailure = (data) => ({
  type: FARMER.FIND_FARMER_FAILURE,
  payload: data,
});

export const getFarmer = (filters) => ({
  type: FARMER.GET_FARMER,
  payload: filters,
});

export const getFarmerSucess = (data) => ({
  type: FARMER.GET_FARMER_SUCCESS,
  payload: data,
});

export const getFarmerFailure = (data) => ({
  type: FARMER.GET_FARMER_FAILURE,
  payload: data,
});
