import { POSTS } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  posts: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function postsReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case POSTS.ADD_POSTS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case POSTS.ADD_POSTS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case POSTS.ADD_POSTS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case POSTS.FIND_POSTS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case POSTS.FIND_POSTS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case POSTS.FIND_POSTS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case POSTS.UPDATE_POSTS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case POSTS.UPDATE_POSTS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case POSTS.UPDATE_POSTS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case POSTS.GET_POSTS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case POSTS.GET_POSTS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        posts: action.payload,
      };
    case POSTS.GET_POSTS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case POSTS.REMOVE_POSTS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case POSTS.REMOVE_POSTS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case POSTS.REMOVE_POSTS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
