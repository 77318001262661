import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useRouter } from "../../common/useRouter";
import { resetPwd } from "../../redux/actions/authActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import Input from "../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";

// https://mefarmapp.herokuapp.com/reset-pwd/0759e117-d4bd-4353-8bc6-06ee4c3853f4
const resetPwdScheme = Yup.object().shape({
  password: Yup.string()
    .matches(
      /(?=.*[a-z])/,
      "The string must contain at least 1 lowercase alphabetical character"
    )
    .matches(
      /(?=.*[A-Z])/,
      " The string must contain at least 1 uppercase alphabetical character"
    )
    .matches(
      /(?=.*[0-9])/,
      "The string must contain at least 1 numeric character"
    )
    .matches(
      /(?=.[!@#$%^&])/,
      "The string must contain at least one special character"
    )
    .matches(/(?=.{6,})/, "The string must be six characters or longer")
    .required("Password is required"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const REGISTER_INITIAL = {
  password: "",
  confirm_password: "",
  code: "",
};

const messages = {
  processing: "Processing request! ...",
  ok: "Request successful!",
  error: "Request failed, try again!",
};

function Register({ processing, ok, error, resetPwd }) {
  const { query } = useRouter();

  return (
    <LayoutFour>
      <Breadcrumb title="Reset Password" />
      <div className="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              {/* <h6 className="contact-title mb-4 f-10">
                Provide information below!
              </h6> */}
              <div className="card">
                <div className="card-body mt-2">
                  <h2 className="card-title mb-3">Reset Password form</h2>
                  {/* <p className="card-text mb-2 text-muted">
                    Provide the information below, a link to access your account
                    will be sent to your email
                  </p> */}
                  <br />
                  <div className="entry-form">
                    {processing && (
                      <div className="alert alert-primary" role="alert">
                        {messages.processing}
                      </div>
                    )}

                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {messages.error}
                      </div>
                    )}

                    {ok && (
                      <div className="alert alert-success" role="alert">
                        {messages.ok}
                      </div>
                    )}
                    <Formik
                      initialValues={REGISTER_INITIAL}
                      validationSchema={resetPwdScheme}
                      onSubmit={(values, actions) => {
                        // console.log(values);
                        setTimeout(() => {
                          resetPwd({
                            password: values.password,
                            code: query.code,
                          });
                          actions.setSubmitting(false);
                        }, 1000);
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <Input
                            type="password"
                            name="password"
                            errDivClass="form-text text-muted"
                            placeholder="Password"
                          />

                          <Input
                            type="password"
                            name="confirm_password"
                            errDivClass="form-text text-muted"
                            placeholder="Confirm Password"
                          />

                          <button
                            className="btn -dark"
                            disabled={props.isSubmitting}
                          >
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-12 mt-4">
                      Remember password ? <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </LayoutFour>
  );
}
const mapStateToProps = ({ authReducer }) => {
  const { is } = authReducer;
  return {
    ...is,
  };
};
export default connect(mapStateToProps, { resetPwd })(Register);
