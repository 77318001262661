/* eslint-disable react/prop-types */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import Input from "../../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";
const entryScheme = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  sub_title: Yup.string().nullable().label("Sub title"),
  content: Yup.string().required().label("Content"),
  created_by: Yup.string(),
});

const LOGIN_INITIAL = {
  title: "",
  sub_title: "",
  content: "",
  created_by: "",
};

const EntryForm = ({
  toggle,
  className,
  isOpen,
  onSubmit,
  initialValues = LOGIN_INITIAL,
}) => {
  const userId = useSelector(({ authReducer }) => authReducer.user.id);
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal centered isOpen={isOpen} className={className}>
      <Formik
        initialValues={
          Object.prototype.hasOwnProperty.call(initialValues, "id")
            ? initialValues
            : { ...initialValues, created_by: userId }
        }
        validationSchema={entryScheme}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            onSubmit(values);
            actions.setSubmitting(false);
            toggle();
          }, 1000);
        }}
      >
        {(props) => (
          <div className="entry-form">
            <form onSubmit={props.handleSubmit}>
              <ModalHeader toggle={toggle} close={closeBtn}>
                {Object.prototype.hasOwnProperty.call(initialValues, "id")
                  ? " Update Content"
                  : "Create Update"}
              </ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  name="title"
                  value={props.values.title}
                  errDivClass="form-text text-muted"
                  placeholder="Title"
                />

                <Input
                  type="text"
                  name="sub_title"
                  value={props.values.sub_title}
                  placeholder="Sub Title (if any)"
                  errDivClass="form-text text-muted"
                />

                <Input
                  type="textarea"
                  name="content"
                  rows="8"
                  value={props.values.content}
                  placeholder="Content"
                  errDivClass="form-text text-muted"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  className="btn btn-sm p-2"
                  disabled={props.isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  className="btn btn-sm p-2"
                  color="secondary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default EntryForm;
