import queryString from "query-string";
import { requestOptions } from "./request";

const createGupdate = (data) => {
  return {
    url: "blog/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const updateGupdate = (token, data) => {
  return {
    url: "blog/" + data.id + "/",
    opt: requestOptions("PATCH", token, {}, { data: JSON.stringify(data) }),
  };
};

const getGupdates = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "blog/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findGupdate = (token, productId) => {
  return {
    url: "blog/" + productId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeGupdate = (token, productId) => {
  return {
    url: "blog/" + productId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createGupdate,
  updateGupdate,
  findGupdate,
  removeGupdate,
  getGupdates,
};
