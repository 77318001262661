import { ORDERS } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  orders: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  order: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: initialState.is,
      };
    case ORDERS.ADD_ORDERS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case ORDERS.ADD_ORDERS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case ORDERS.ADD_ORDERS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case ORDERS.FIND_ORDERS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case ORDERS.FIND_ORDERS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        order: action.payload,
      };
    case ORDERS.FIND_ORDERS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case ORDERS.UPDATE_ORDERS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case ORDERS.UPDATE_ORDERS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case ORDERS.UPDATE_ORDERS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case ORDERS.GET_ORDERS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case ORDERS.GET_ORDERS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        orders: action.payload,
      };
    case ORDERS.GET_ORDERS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case ORDERS.REMOVE_ORDERS:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case ORDERS.REMOVE_ORDERS_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case ORDERS.REMOVE_ORDERS_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
