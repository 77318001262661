/* eslint-disable react/prop-types */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup } from "reactstrap";
// import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Input from "../../../components/Control/Input";

const FILE_SIZE = 1000000;
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const farmerEntryScheme = Yup.object().shape({
  email: Yup.string().required().label("Email"),
  first_name: Yup.string().required().label("First Name").default(""),
  last_name: Yup.string().required().label("Last Name"),
  farm_name: Yup.string().label("Farm Name").nullable().default(""),
  address: Yup.string().label("Address"),
  photo: Yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format! only upload .png, .jpg and .jpeg images",
      (value) => {
        let result = false;
        if (value instanceof File) {
          result = value && SUPPORTED_IMAGE_FORMATS.includes(value.type);
        } else {
          result = true;
        }

        return result;
      }
    )
    .test("fileSize", "File too large, should be less than 468", (value) => {
      let result = false;
      if (value instanceof File) {
        result = value && value.size <= FILE_SIZE;
      } else {
        result = true;
      }
      return result;
    }),
  number_of_branch: Yup.number().label("No. of Branches").nullable().default(0),
  town_village: Yup.string().label("Village").nullable().default(""),
  farm_manager: Yup.string().label("Farm Manager").nullable().default(""),
  is_legally_registered: Yup.bool().label("Is registered").default(true),
  registration_no: Yup.string()
    .label("Registration No.")
    .nullable()
    .default(""),
  total_acreage: Yup.number().label("Total Acreage").nullable().default(0),
  grazing_capacity: Yup.number()
    .label("Grazing Capacity")
    .nullable()
    .default(0),
  availability_of_labour: Yup.string()
    .label("Availability of Labour")
    .nullable()
    .default(""),
  matured_males: Yup.number().label("Matured Males"),
  matured_females: Yup.number().label("Matured Females"),
  offspring_males: Yup.number().label("Offspring Males"),
  offspring_females: Yup.number().label("Offspring Females"),
  wethers: Yup.number().label("Wethers").nullable().default(0),
  phone: Yup.string().required().label("Phone"),
  long: Yup.number().label("Longitude").nullable().default(0),
  lat: Yup.number().label("Latitude").nullable().default(0),
  gender: Yup.string().required().label("Gender"),
});

const userEntryScheme = Yup.object().shape({
  email: Yup.string().required(),
  first_name: Yup.string().required().label("First Name").default(""),
  last_name: Yup.string().required().label("Last Name"),
  address: Yup.string().label("Address").required().default(""),
  town_city: Yup.string().label("Town/City").required().default(""),
  country: Yup.string().label("Country").required().default(""),
  phone_number: Yup.string().label("Phone").required().default(""),
  // gender: Yup.string().required().label("Gender"),
});

const UserModalBody = ({ props }) => (
  <ModalBody>
    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="first_name"
          className="form-control"
          placeholder="First name"
          value={props.values.first_name ? props.values.first_name : ""}
        />
      </div>
      <div className="col-6">
        <Input
          type="text"
          name="last_name"
          className="form-control"
          placeholder="Last name"
          value={props.values.last_name ? props.values.last_name : ""}
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={props.values.email ? props.values.email : ""}
        />
      </div>
      <div className="col-6">
        <FormGroup>
          <PhoneInput
            inputProps={{
              name: "phone_number",
              required: true,
              // autoFocus: true,
            }}
            value={props.values.phone_number ? props.values.phone_number : ""}
            onChange={(phone) =>
              props.setFieldValue("phone_number", "+" + phone)
            }
            onBlur={props.handleBlur}
            autocompleteSearch
            enableSearch
            inputStyle={{ paddingLeft: "48px" }}
            placeholder="256 775 778 778 888"
          />
        </FormGroup>
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="address"
          className="form-control"
          placeholder="Address"
          value={props.values.address ? props.values.address : ""}
        />
      </div>
      <div className="col-6">
        <Input
          type="text"
          name="town_city"
          className="form-control"
          placeholder="Town/City"
          value={props.values.town_city ? props.values.town_city : ""}
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="country"
          className="form-control"
          placeholder="Country"
          value={props.values.country ? props.values.country : ""}
        />
      </div>
      {/* <div className="col-6">
        <Input
          type="select"
          name="gender"
          className="form-control"
          style={{ borderRadius: 0, fontSize: "0.8125rem" }}
          value={props.values.gender ? props.values.gender : ""}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </Input>
      </div> */}
    </div>
  </ModalBody>
);

const FarmerModalBody = ({ props }) => (
  <ModalBody>
    <label>Farmer Particulars</label>

    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="first_name"
          className="form-control"
          placeholder="First name"
          value={props.values.first_name ? props.values.first_name : ""}
        />
      </div>
      <div className="col-6">
        <Input
          type="text"
          name="last_name"
          className="form-control"
          placeholder="Last name"
          value={props.values.last_name ? props.values.last_name : ""}
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="select"
          name="gender"
          className="form-control"
          style={{ borderRadius: 0, fontSize: "0.8125rem" }}
          value={props.values.gender ? props.values.gender : ""}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </Input>
      </div>
      <div className="col-6">
        <FormGroup>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              // autoFocus: true,
            }}
            value={props.values.phone ? props.values.phone : ""}
            onChange={(phone) => props.setFieldValue("phone", "+" + phone)}
            onBlur={props.handleBlur}
            autocompleteSearch
            enableSearch
            inputStyle={{ paddingLeft: "48px" }}
            placeholder="256 775 778 778 888"
          />
        </FormGroup>
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="address"
          className="form-control"
          placeholder="Address"
          value={props.values.address ? props.values.address : ""}
        />
      </div>
      <div className="col-6">
        <Input
          type="text"
          name="town_village"
          className="form-control"
          placeholder="Village"
          value={props.values.town_village ? props.values.town_village : ""}
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={props.values.email ? props.values.email : ""}
        />
      </div>
      <div className="col-6">
        <div className="form-group p-0">
          <input
            type="file"
            name="photo"
            onBlur={props.handleBlur}
            label="Photo"
            className="pt-0"
            onChange={(evt) => {
              props.setFieldValue("photo", evt.target.files[0]);
            }}
          />
          {props.errors.photo && (
            <div className="input-error">{props.errors.photo}</div>
          )}
        </div>
      </div>
    </div>

    <label>Farm Details</label>

    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="farm_name"
          className="form-control"
          value={props.values.farm_name ? props.values.farm_name : ""}
          placeholder="Farm Name"
        />
      </div>
      <div className="col-6">
        <Input
          type="text"
          name="farm_manager"
          value={props.values.farm_manager ? props.values.farm_manager : ""}
          className="form-control"
          placeholder="Farm Manager "
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="text"
          name="registration_no"
          value={
            props.values.registration_no ? props.values.registration_no : ""
          }
          className="form-control"
          placeholder="Registration No. : "
        />
      </div>
      <div className="col-6">
        <Input
          type="number"
          name="number_of_branch"
          value={
            props.values.number_of_branch ? props.values.number_of_branch : 0
          }
          className="form-control"
          placeholder="No of Branches "
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="number"
          name="total_acreage"
          value={props.values.total_acreage ? props.values.total_acreage : 0}
          className="form-control"
          placeholder="Total Acreage"
        />
      </div>
      <div className="col-6">
        <Input
          type="number"
          name="grazing_capacity"
          className="form-control"
          value={
            props.values.grazing_capacity ? props.values.grazing_capacity : 0
          }
          placeholder="Grazing Capacity"
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="number"
          name="matured_males"
          value={props.values.matured_males ? props.values.matured_males : 0}
          className="form-control"
          placeholder="Mature males"
        />
      </div>
      <div className="col-6">
        <Input
          type="number"
          name="matured_females"
          className="form-control"
          value={
            props.values.matured_females ? props.values.matured_females : 0
          }
          placeholder="Mature Females"
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="number"
          name="offspring_males"
          value={
            props.values.offspring_males ? props.values.offspring_males : 0
          }
          className="form-control"
          placeholder="Male Offsprings"
        />
      </div>
      <div className="col-6">
        <Input
          type="number"
          name="offspring_females"
          className="form-control"
          value={
            props.values.offspring_females ? props.values.offspring_females : 0
          }
          placeholder="Female Offsprings"
        />
      </div>
    </div>
    <div className="row form">
      <div className="col-6">
        <Input
          type="number"
          name="wethers"
          value={props.values.wethers ? props.values.wethers : 0}
          className="form-control"
          placeholder="Wethers"
        />
      </div>
    </div>
  </ModalBody>
);

const EntryForm = ({
  toggle,
  className,
  isOpen,
  onSubmit,
  view,
  selectedUser,
}) => {
  //   const userId = useSelector(({ authReducer }) => authReducer.user.id);
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal
      centered
      size={view === "farmer" ? "lg" : "md"}
      isOpen={isOpen}
      className={className}
    >
      <ModalHeader toggle={toggle} close={closeBtn}>
        Edit Profile
      </ModalHeader>
      <Formik
        initialValues={selectedUser}
        validationSchema={view === "user" ? userEntryScheme : farmerEntryScheme}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            onSubmit(values);
            actions.setSubmitting(false);
            toggle();
          }, 1000);
        }}
      >
        {(props) => {
          return (
            <div className="entry-form">
              <form onSubmit={props.handleSubmit}>
                {props && view === "user" && <UserModalBody props={props} />}
                {props && view === "farmer" && (
                  <FarmerModalBody props={props} />
                )}
                <ModalFooter>
                  <Button
                    color="primary"
                    type="submit"
                    className="btn btn-sm p-2"
                    disabled={props.isSubmitting}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn btn-sm p-2"
                    type="button"
                    color="secondary"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EntryForm;
