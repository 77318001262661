import { AUTH, USER, STATS } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  token: "",
  user: {},
  authFarmer: {},
  stats: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };

    case AUTH.LOGOUT_USER_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case STATS.FETCH_STATISTICS: {
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };
    }

    case STATS.FETCH_STATISTICS_SUCCESS: {
      return {
        ...state,
        stats: action.payload,
        is: { ...initialState.is, ok: true },
      };
    }

    case STATS.FETCH_STATISTICS_FAILURE: {
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    }

    case AUTH.FIND_ASSOC_FARMER: {
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };
    }

    case AUTH.FIND_ASSOC_FARMER_SUCCESS: {
      return {
        ...state,
        authFarmer: action.payload,
        is: { ...initialState.is, ok: true },
      };
    }

    case AUTH.FIND_ASSOC_FARMER_FAILURE: {
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    }

    case USER.FIND_USER_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }

    case USER.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case AUTH.FORGOT_PASSWORD:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case AUTH.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };

    case AUTH.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case AUTH.RESET_PASSWORD:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case AUTH.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };

    case AUTH.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case AUTH.LOGIN_USER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case AUTH.LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        is: { ...initialState.is, ok: true },
      };
    case AUTH.LOGIN_USER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case AUTH.REGISTER_USER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case AUTH.REGISTER_USER_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case AUTH.REGISTER_USER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case AUTH.REGISTER_PUBLIC_USER:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case AUTH.REGISTER_PUBLIC_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        is: { ...initialState.is, ok: true },
      };
    case AUTH.REGISTER_PUBLIC_USER_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    default:
      return state;
  }
}
