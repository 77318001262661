import queryString from "query-string";
import { requestOptions } from "./request";

const createTopic = (token, data) => {
  return {
    url: "topics/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updateTopic = (token, data) => {
  return {
    url: "topics/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getTopics = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "topics/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findTopic = (token, topicId) => {
  return {
    url: "topics/" + topicId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeTopic = (token, topicId) => {
  return {
    url: "topics/" + topicId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createTopic,
  updateTopic,
  findTopic,
  removeTopic,
  getTopics,
};
