import { dataMachine } from "./dataMachine";
import { API, userAPI } from "../api";

export const addInvestorProfileMachine = dataMachine(
  "addInvestorProfileMachine"
).withConfig({
  services: {
    createData: async (cxt, evt) => {
      if (!Object.prototype.hasOwnProperty.call(evt, "data")) {
        return;
      }

      const { token, farmerId } = evt.data;

      console.log(token, farmerId);
      return await API.request(
        userAPI.addInvestorProfileToUser(token, farmerId)
      );
    },
  },
});
