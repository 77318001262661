import { requestOptions } from "./request";

const generateCsv = (token, data) => {
  return {
    url: "farmers/export",
    opt: requestOptions("POST", token, {}, { data: JSON.stringify(data) }),
  };
};

const getStatistics = (token) => {
  return {
    url: "stats/",
    opt: requestOptions("GET", token),
  };
};

export default {
  generateCsv,
  getStatistics,
};
