import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from "redux";
import { createBrowserHistory } from "history";

import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2, // Xem thêm tại mục "Quá trình merge".
};

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routeMiddleware, sagaMiddleware];

const pReducer = persistReducer(persistConfig, rootReducer(history));
const createdStore = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export const initializeStore = () => {
  return createdStore;
};
export const persistor = persistStore(createdStore);
