/* eslint-disable react/prop-types */
import React from "react";

import { Helmet } from "react-helmet";
import withScrollFixed from "../../common/withScrollFixed";
import FooterOne from "../Footer/FooterOne";
import HeaderFour from "../Header/HeaderFour";

let ScrollFixedHeader = withScrollFixed(HeaderFour);

export default function LayoutFour(props) {
  return (
    <>
      <Helmet>
        <title>{props.title || "Mefarm | My Smart Farm Agent"}</title>
      </Helmet>
      <ScrollFixedHeader
        container={props.container}
        menuData={props.menuData}
        hide={props.hide}
        isAdmin={props.isAdmin}
      />
      {props.children}
      <FooterOne />
    </>
  );
}
