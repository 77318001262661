/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

import Navigator from "../Elements/Navigator";
import MenuFunctionIcons from "../Elements/MenuFunctionIcons";
import { renderContainer } from "../../../common/utils";

export default function MenuOne({
  container,
  menuStyle,
  menuFuncStyle,
  menuData,
  className,
  hide,
}) {
  return (
    <div className={`menu ${menuStyle}`}>
      <div className={renderContainer(container)}>
        <div className="menu__wrapper">
          <Link to="/" className="menu__wrapper__logo">
            <img src="/assets/images/logo.png" alt="Logo" />
          </Link>
          <Navigator className={className} menuData={menuData} />
          <MenuFunctionIcons hide={hide} />
        </div>
      </div>
    </div>
  );
}
