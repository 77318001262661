/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPwd } from "../../../redux/actions/authActions";
import { useMachine } from "@xstate/react";
import { logout } from "../../../redux/actions/authActions";
import { addInvestorProfileMachine } from "../../../machines/addInvestorProfileMachine";

const messages = {
  processing: "Processing request! ...",
  ok: "Request successful, Check your email for the password reset link!",
  error: "Request failed, try again!",
};

const UserInfo = ({ farmer, view, user, investor }) => {
  const dispatch = useDispatch();
  const { token, is } = useSelector(({ authReducer }) => authReducer);
  const [current, send] = useMachine(addInvestorProfileMachine, {
    devTools: true,
  });

  console.log("current", current);

  useEffect(() => {
    if (current.matches("success.withoutData")) {
      dispatch(logout());
    }
  }, [current, dispatch]);

  const handleReset = useCallback(() => {
    if (view === "investor") {
      dispatch(forgotPwd({ email: investor.email }));
    } else {
      dispatch(forgotPwd({ email: user.email }));
    }
  }, [dispatch, user.email, view, investor]);
  return (
    <div className="col-12">
      <div className="table-responsive card p-2 mb-4">
        {view === "investor" && (
          <table className="table">
            <caption className="pl-2" style={{ captionSide: "top" }}>
              <h4>User Info</h4>
            </caption>

            <tbody>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>FirstName:</span>{" "}
                  {investor.first_name}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>LastName:</span>{" "}
                  {investor.last_name}
                </td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Email:</span>{" "}
                  {investor.email}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Contact:</span>{" "}
                  {investor.phone_number}
                </td>
              </tr>

              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Town/City:</span>{" "}
                  {investor.town_city}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Address:</span>{" "}
                  {investor.address}
                </td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Country:</span>{" "}
                  {investor.country}
                </td>
                <td style={{ border: "none" }} />
              </tr>
            </tbody>
          </table>
        )}
        {view === "user" && (
          <table className="table">
            <caption className="pl-2" style={{ captionSide: "top" }}>
              <h4>User Info</h4>
            </caption>

            <tbody>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>FirstName:</span>{" "}
                  {user.first_name}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>LastName:</span>{" "}
                  {user.last_name}
                </td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Email:</span> {user.email}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Contact:</span>{" "}
                  {user.phone_number}
                </td>
              </tr>

              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Town/City:</span>{" "}
                  {user.town_city}
                </td>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Address:</span>{" "}
                  {user.address}
                </td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  <span style={{ color: "#6c757d" }}>Country:</span>{" "}
                  {user.country}
                </td>
                <td style={{ border: "none" }} />
              </tr>
            </tbody>
          </table>
        )}
        {view === "farmer" && (
          <React.Fragment>
            <table className="table">
              <caption className="pl-2" style={{ captionSide: "top" }}>
                <h4>User Info</h4>
              </caption>

              <tbody>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>FirstName:</span>{" "}
                    {farmer.first_name}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>LastName:</span>{" "}
                    {farmer.last_name}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Email:</span>{" "}
                    {farmer.email}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Contact:</span>{" "}
                    {farmer.phone}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Gender:</span>{" "}
                    {farmer.gender}
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Village:</span>{" "}
                    {farmer.town_village}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Address:</span>{" "}
                    {farmer.address}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Country:</span> Uganda
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
              </tbody>
            </table>
            <hr style={{ border: "1px solid rgba(0, 0, 0, 0.125)" }} />
            <table className="table">
              <caption className="pl-2" style={{ captionSide: "top" }}>
                Farm Particulars
              </caption>

              <tbody>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Farm Name :</span>{" "}
                    {farmer.farm_name}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Registration No. :</span>{" "}
                    {farmer.is_legally_registered ? farmer.registration_no : ""}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Manager:</span>{" "}
                    {farmer.farm_manager}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>No of Branches:</span>{" "}
                    {farmer.number_of_branch}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Total Acreage:</span>{" "}
                    {farmer.total_acreage}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Grazing Capacity:</span>{" "}
                    {farmer.grazing_capacity}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Mature males:</span>{" "}
                    {farmer.matured_males}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Mature Females:</span>{" "}
                    {farmer.matured_females}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Male Offsprings:</span>{" "}
                    {farmer.offspring_males}
                  </td>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Female Offsprings:</span>{" "}
                    {farmer.offspring_females}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "none" }}>
                    <span style={{ color: "#6c757d" }}>Wethers:</span>{" "}
                    {farmer.wethers}
                  </td>
                  <td style={{ border: "none" }} />
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
      {(view === "user" ||
        user.id === farmer.user ||
        (view === "investor" && user.id === investor.user)) && (
        <div className="card">
          {is.processing && (
            <div className="alert alert-primary" role="alert">
              {messages.processing}
            </div>
          )}

          {is.error && (
            <div className="alert alert-danger" role="alert">
              {messages.error}
            </div>
          )}

          {is.ok && (
            <div className="alert alert-success" role="alert">
              {messages.ok}
            </div>
          )}
          <h4 className={"mb-3 mt-3 ml-3"}>Extra Actions</h4>
          <ul className="list-group list-group-flush">
            {user.id === farmer.user && !user.is_an_investor && (
              <li className="list-group-item flex-column  text-center align-items-start">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Profile Upgrade</h5>
                  {/*<small>3 days ago</small>*/}
                </div>

                <div className={"mb-1 text-center"}>
                  <button
                    onClick={() =>
                      send({
                        type: "CREATE",
                        data: {
                          token: token,
                          farmerId: user.id,
                        },
                      })
                    }
                    className="btn btn-sm card-link"
                    style={{ textTransform: "capitalize" }}
                  >
                    Click here to activate Investor profile
                  </button>
                </div>
                {/*<p className="mb-1">*/}
                {/*  Click on the button */}
                {/*</p>*/}

                <small>
                  Note: You might be automatically logged out after request is
                  successful
                </small>
              </li>
            )}
            <li className="list-group-item flex-column  text-center align-items-start">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">Change Password</h5>
                {/*<small>3 days ago</small>*/}
              </div>

              <div className={"mb-1 text-center"}>
                <button
                  onClick={handleReset}
                  className="btn btn-sm card-link"
                  style={{ textTransform: "capitalize" }}
                >
                  Click here to reset your password
                </button>
              </div>
              {/*<p className="mb-1">*/}
              {/*  Click on the button */}
              {/*</p>*/}

              <small>
                Note: You might be automatically logged out after request is
                successful
              </small>
            </li>
            {/*<li className="list-group-item">*/}
            {/*  <button*/}
            {/*    onClick={handleReset}*/}
            {/*    className="btn btn-md card-link"*/}
            {/*    style={{ textTransform: "capitalize" }}*/}
            {/*  >*/}
            {/*    Change Password*/}
            {/*  </button>*/}
            {/*  (Reset link will be set to you email)*/}
            {/*</li>*/}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
