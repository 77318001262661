import { ORDERS } from "../variables";

export const createOrder = (data) => ({
  type: ORDERS.ADD_ORDERS,
  payload: data,
});

export const createOrderSucess = (data) => ({
  type: ORDERS.ADD_ORDERS_SUCCESS,
  payload: data,
});

export const createOrderFailure = (data) => ({
  type: ORDERS.ADD_ORDERS_FAILURE,
  payload: data,
});

export const updateOrder = (data) => ({
  type: ORDERS.UPDATE_ORDERS,
  payload: data,
});

export const updateOrderSucess = (data) => ({
  type: ORDERS.UPDATE_ORDERS_SUCCESS,
  payload: data,
});

export const updateOrderFailure = (data) => ({
  type: ORDERS.UPDATE_ORDERS_FAILURE,
  payload: data,
});

export const removeOrder = (data) => ({
  type: ORDERS.REMOVE_ORDERS,
  payload: data,
});

export const removeOrderSucess = (data) => ({
  type: ORDERS.REMOVE_ORDERS_SUCCESS,
  payload: data,
});

export const removeOrderFailure = (data) => ({
  type: ORDERS.REMOVE_ORDERS_FAILURE,
  payload: data,
});

export const findOrder = (data) => ({
  type: ORDERS.FIND_ORDERS,
  payload: data,
});

export const findOrderSucess = (data) => ({
  type: ORDERS.FIND_ORDERS_SUCCESS,
  payload: data,
});

export const findOrderFailure = (data) => ({
  type: ORDERS.FIND_ORDERS_FAILURE,
  payload: data,
});

export const getOrder = (filters) => ({
  type: ORDERS.GET_ORDERS,
  payload: filters,
});

export const getOrderSucess = (data) => ({
  type: ORDERS.GET_ORDERS_SUCCESS,
  payload: data,
});

export const getOrderFailure = (data) => ({
  type: ORDERS.GET_ORDERS_FAILURE,
  payload: data,
});
