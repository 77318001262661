/* eslint-disable react/prop-types */
import React from "react";
import { useState } from "react";
import classNames from "classnames";

// import Button from "../Control/Button";
import Modal from "../Control/Modal";

export default function VideoFrame(props) {
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let { poster, height, width, src, className } = props;
  height = height || 1080;
  return (
    <>
      <div
        className={`video-frame ${classNames(className)}`}
        style={{ height: height, width: width ? width : (height * 16) / 9 }}
      >
        <div className="video-frame__poster">
          {/* <img src={poster} alt="Video poster" /> */}
          <div
            className="embed-responsive embed-responsive-16by9"
            // style={{ marginTop: "3em" }}
          >
            <iframe
              title="video"
              src="https://www.youtube.com/embed/EeRz6GAQ9og?controls=0&rel=0"
              // frameBorder="0"
              allow="accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="embed-responsive-item"

              // style={{ width: (1080 * 16) / 9 }}
            />
          </div>
        </div>
        {/* <Button
          action="#"
          color="white"
          height="50px"
          width="50px"
          className="-round"
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
          content={<i className="fas fa-play"></i>}
        ></Button> */}
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        height={400}
        width={700}
      >
        <iframe
          title="video"
          src="https://www.youtube.com/embed/EeRz6GAQ9og?controls=0&rel=0"
          frameBorder="0"
          allow="accelerometer; modestbranding; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    </>
  );
}
