import React from "react";
import { useRouter } from "../../../common/useRouter";
import { useSelector } from "react-redux";
// import products from "../../../data/products.json";
import { getProductBySlug } from "../../../common/productSelect";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";
import LayoutFour from "../../../components/Layout/LayoutFour";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/Other/Breadcrumb";
import ProductSlideTwo from "../../../components/Sections/ProductThumb/ProductSlide/ProductSlideTwo";

export default function Product() {
  const router = useRouter();

  const { slug } = router.query;

  const { products } = useSelector(({ productReducer }) => productReducer);
  console.log(slug, products);
  let foundProduct = getProductBySlug(products.results, slug);
  const onReviewSubmit = (data) => {
    console.log(data);
  };

  const relatedProducts = products.results.filter(
    (_product) =>
      _product.id !== foundProduct.id &&
      _product.category.id === foundProduct.category.id
  );

  return (
    foundProduct !== null &&
    foundProduct !== undefined && (
      <LayoutFour title={`MeFarms | ${foundProduct.title}`}>
        <Breadcrumb title="Product Detail">
          <BreadcrumbItem name="Home" />
          <BreadcrumbItem name="Shop" />
          <BreadcrumbItem name={foundProduct.title} current />
        </Breadcrumb>
        <ProductDetail data={foundProduct} onReviewSubmit={onReviewSubmit} />
        <ProductSlideTwo data={relatedProducts} />
      </LayoutFour>
    )
  );
}
