import { AUTH } from "../variables";

export const logout = () => ({
  type: AUTH.LOGOUT_USER,
});

export const logoutSucess = () => ({
  type: AUTH.LOGOUT_USER_SUCCESS,
});

export const logoutFailure = () => ({
  type: AUTH.LOGOUT_USER_FAILURE,
});

export const forgotPwd = (data) => ({
  type: AUTH.FORGOT_PASSWORD,
  payload: data,
});

export const forgotPwdSucess = (data) => ({
  type: AUTH.FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotPwdFailure = () => ({
  type: AUTH.FORGOT_PASSWORD_FAILURE,
});

export const resetPwd = (data) => ({
  type: AUTH.RESET_PASSWORD,
  payload: data,
});

export const resetPwdSucess = (data) => ({
  type: AUTH.RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPwdFailure = () => ({
  type: AUTH.RESET_PASSWORD_FAILURE,
});

export const loginUser = (data, path) => ({
  type: AUTH.LOGIN_USER,
  payload: data,
  path: path,
});

export const loginUserSucess = (data) => ({
  type: AUTH.LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserFailure = (data) => ({
  type: AUTH.LOGIN_USER_FAILURE,
  payload: data,
});

export const registerPublicUser = (data, path) => ({
  type: AUTH.REGISTER_PUBLIC_USER,
  payload: data,
  path,
});

export const registerPublicUserSucess = (data) => ({
  type: AUTH.REGISTER_PUBLIC_USER_SUCCESS,
  payload: data,
});

export const registerPublicUserFailure = (data) => ({
  type: AUTH.REGISTER_PUBLIC_USER_FAILURE,
  payload: data,
});

export const loginPublicUser = (data) => ({
  type: AUTH.LOGIN_PUBLIC_USER,
  payload: data,
});

export const loginPublicUserSucess = (data) => ({
  type: AUTH.LOGIN_PUBLIC_USER_SUCCESS,
  payload: data,
});

export const loginPublicUserFailure = (data) => ({
  type: AUTH.LOGIN_PUBLIC_USER_FAILURE,
  payload: data,
});

export const registerUser = (data) => ({
  type: AUTH.REGISTER_USER,
  payload: data,
});

export const registerUserSucess = (data) => ({
  type: AUTH.REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserFailure = (data) => ({
  type: AUTH.REGISTER_USER_FAILURE,
  payload: data,
});

export const findAssocFarmer = (data) => ({
  type: AUTH.FIND_ASSOC_FARMER,
  payload: data,
});

export const findAssocFarmerSucess = (data) => ({
  type: AUTH.FIND_ASSOC_FARMER_SUCCESS,
  payload: data,
});

export const findAssocFarmerFailure = (data) => ({
  type: AUTH.FIND_ASSOC_FARMER_FAILURE,
  payload: data,
});
