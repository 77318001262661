/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoadingOverlay from "react-loading-overlay";
// import { FormGroup } from "reactstrap";
// import { push } from "connected-react-router";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "../../../common/useRouter";
import { findFarmer, updateFarmer } from "../../../redux/actions/farmerActions";
import { updateUser, findUser } from "../../../redux/actions/userActions";
// import { getProduct } from "../../../redux/actions/productActions";
// import { getOrder } from "../../../redux/actions/orderActions";
import { logout } from "../../../redux/actions/authActions";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
import UserInfo from "./UserInfo";
import UserStock from "./UserStock";
import UserOrders from "./UserOrders";
import EntryForm from "./EntryForm";
import { findInvestor } from "../../../redux/actions/investorActions";
import InvestmentProducts from "../investment/product";

const UserProfile = ({ match }) => {
  const router = useRouter();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { id, view } = router.query;
  const [isView, setIsView] = useState(view);
  let { farmer, is } = useSelector(({ farmerReducer }) => farmerReducer);
  const { investor } = useSelector(({ investorReducer }) => investorReducer);
  const productReducer = useSelector(({ productReducer }) => productReducer);
  const ordersReducer = useSelector(({ ordersReducer }) => ordersReducer);
  const { user, authFarmer, token } = useSelector(
    ({ authReducer }) => authReducer
  );
  const dispatch = useDispatch();

  // if (farmer.id !== authFarmer.id) farmer = authFarmer;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (view !== undefined) {
      setIsView(view);
    } else {
      if (id === user.id && !user.is_a_farmer) {
        setIsView("user");
      }
    }
    return () => {
      if (view !== undefined) setIsView(view);
    };
  }, [view, id, user.id, user.is_a_farmer]);

  useEffect(() => {
    if (view === "farmer") {
      dispatch(findFarmer(id));
    } else if (view === "user") {
      dispatch(findUser(id));
    } else if (view === "investor") {
      dispatch(findInvestor(id));
    }
    return () => {};
  }, [dispatch, id, view]);

  const convertToFormData = (jsonData) => {
    let formData = new FormData();
    for (const key in jsonData) {
      if (key === "photo" && !(jsonData[key] instanceof File)) {
        continue;
      }
      if (key === "email") continue;
      if (jsonData[key]) formData.append(key, jsonData[key]);
    }
    return formData;
  };

  const handleActivate = useCallback(
    (userData) => {
      dispatch(updateUser(userData));
    },
    [dispatch]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleUpdate = useCallback(
    (userData) => {
      if (isView === "farmer") {
        dispatch(updateFarmer(convertToFormData(userData)));
      } else if (isView === "user") {
        dispatch(updateUser(userData));
      }
    },
    [dispatch, isView]
  );

  const shouldEdit = user.id === authFarmer.user || id === user.id;
  return (
    <LoadingOverlay
      active={
        is.processing ||
        productReducer.is.processing ||
        ordersReducer.is.processing
      }
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <EntryForm
        isOpen={isOpen}
        toggle={toggle}
        selectedUser={isView === "farmer" ? farmer : user}
        onSubmit={handleUpdate}
        view={isView}
        // initialValues={selectGupdate}
      />
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          // titleSize={20}
          // description="Details"
        >
          Profile
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            {shouldEdit && (
              <Button
                color="red"
                to="#"
                onClick={(_evt) => toggle()}
                className="btn-info btn-md pl-3 pr-3 pt-2 pb-2 mt-4 "
                content={"Edit Profile"}
              />
            )}

            {(id === user.id || id === authFarmer.id) && (
              <Button
                color="dark"
                to="#"
                onClick={(_evt) => handleLogout()}
                className="btn-info btn-md pl-3 pr-3 pt-2 pb-2 mt-4 "
                content={"Logout"}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="row mt-6 justify-content-center"
        // style={{ marginTop: "3.5em", marginBottom: "3.5em" }}
      >
        {(isView === "farmer" || isView === "investor") && (
          <div className="col-3">
            <div className="text-center mb-4">
              <img
                className="img-thumbnail rounded"
                src={
                  farmer.photo
                    ? farmer.photo
                    : `https://via.placeholder.com/200x140.png?text=${
                        isView === "farmer"
                          ? farmer && farmer.first_name
                          : investor && investor.first_name
                      }`
                }
                alt="profile"
              />
            </div>

            {(user.is_admin || user.is_superadmin) && (
              <>
                <div className="card  ">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <Link to={`/admin/profile/${id}?view=${isView}`}>
                        UserInfo
                      </Link>
                    </li>
                    {isView === "farmer" && (
                      <li className="list-group-item">
                        <Link to={`/admin/profile/${id}/stock?view=${isView}`}>
                          Stock
                        </Link>
                      </li>
                    )}
                    {isView === "investor" && (
                      <li className="list-group-item">
                        <Link
                          to={`/admin/profile/${id}/investments?user=${user.id}&view=${isView}`}
                        >
                          Investments
                        </Link>
                      </li>
                    )}
                    {isView === "farmer" && (
                      <li className="list-group-item">
                        <Link to={`/admin/profile/${id}/orders?view=${isView}`}>
                          Orders
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="mt-4">
                  <ul className="list-group   mt-2">
                    {isView === "farmer" && (
                      <li className="list-group-item">
                        <button
                          type="button"
                          onClick={(_evt) =>
                            handleActivate({
                              id: farmer.user,
                              email: farmer.email,
                              first_name: farmer.first_name,
                              last_name: farmer.last_name,
                              is_active: !farmer.is_active,
                            })
                          }
                          style={{ textTransform: "capitalize" }}
                          className="btn btn-outline-primary btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          {farmer.is_active ? "Deactivate" : "Activate"}
                        </button>
                      </li>
                    )}
                    {/* <li className="list-group-item">
                      <button
                        type="button"
                        onClick={(_evt) => handleLogout()}
                        style={{ textTransform: "capitalize" }}
                        className="btn btn-outline-primary btn-sm pl-2 pr-2 pt-1 pb-1"
                      >
                        Logout
                      </button>
                    </li> */}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
        <div className="col-9">
          <Switch>
            <Route
              exact
              path={`${match.url}/`}
              render={(props) => (
                <UserInfo
                  farmer={farmer}
                  investor={investor}
                  user={user}
                  view={isView}
                  {...props}
                />
              )}
            />
            {isView === "farmer" && (
              <>
                <Route
                  path={`${match.url}/stock`}
                  render={(props) => (
                    <UserStock userId={farmer.user} {...props} />
                  )}
                />
                <Route
                  path={`${match.url}/orders`}
                  render={(props) => (
                    <UserOrders userId={farmer.user} {...props} />
                  )}
                />
              </>
            )}
            {isView === "investor" && (
              <>
                <Route
                  path={`${match.url}/investments`}
                  render={(props) => {
                    console.log("user.id", user.id, props);
                    return (
                      <InvestmentProducts
                        token={token}
                        userId={user.id}
                        isAdmin={false}
                        hideHeader={true}
                        {...props}
                      />
                    );
                  }}
                />
              </>
            )}
          </Switch>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default UserProfile;
