export const CART = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  REMOVE_ALL_FROM_CART: "REMOVE_ALL_FROM_CART",
  DECREASE_QUANTITY_CART: "DECREASE_QUANTITY_CART",
  INCREASE_QUANTITY_CART: "INCREASE_QUANTITY_CART",
};

export const WISHLIST = {
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  REMOVE_FROM_WISHLIST: "REMOVE_FROM_WISHLIST",
};

export const SHOP = {
  SET_FILTER_CATEGORY: "SET_FILTER_CATEGORY",
  SET_FILTER_PRICE_RANGE: "SET_FILTER_PRICE_RANGE",
  SET_FILTER_BRAND: "SET_FILTER_BRAND",
  RESET_FILTER: "RESET_FILTER",
};

export const AUTH = {
  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
  LOGIN_PUBLIC_USER: "LOGIN_PUBLIC_USER",
  LOGIN_PUBLIC_USER_SUCCESS: "LOGIN_PUBLIC_USER_SUCCESS",
  LOGIN_PUBLIC_USER_FAILURE: "LOGIN_PUBLIC_USER_FAILURE",
  REGISTER_PUBLIC_USER: "REGISTER_PUBLIC_USER",
  REGISTER_PUBLIC_USER_SUCCESS: "REGISTER_PUBLIC_USER_SUCCESS",
  REGISTER_PUBLIC_USER_FAILURE: "REGISTER_PUBLIC_USER_FAILURE",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FIND_ASSOC_FARMER: "FIND_ASSOC_FARMER",
  FIND_ASSOC_FARMER_SUCCESS: "FIND_ASSOC_FARMER_SUCCESS",
  FIND_ASSOC_FARMER_FAILURE: "FIND_ASSOC_FARMER_FAILURE",
};

export const BLOG = {
  ADD_BLOG: "ADD_BLOG",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_FAILURE: "ADD_BLOG_FAILURE",
  CREATE_BLOG: "CREATE_BLOG",
  CREATE_BLOG_SUCCESS: "CREATE_BLOG_SUCCESS",
  CREATE_BLOG_FAILURE: "CREATE_BLOG_FAILURE",
  REMOVE_BLOG: "REMOVE_BLOG",
  REMOVE_BLOG_SUCCESS: "REMOVE_BLOG_SUCCESS",
  REMOVE_BLOG_FAILURE: "REMOVE_BLOG_FAILURE",
  UPDATE_BLOG: "UPDATE_BLOG",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_FAILURE: "UPDATE_BLOG_FAILURE",
  FIND_BLOG: "FIND_BLOG",
  FIND_BLOG_SUCCESS: "FIND_BLOG_SUCCESS",
  FIND_BLOG_FAILURE: "FIND_BLOG_FAILURE",
  GET_BLOG: "GET_BLOG",
  GET_BLOG_SUCCESS: "GET_BLOG_SUCCESS",
  GET_BLOG_FAILURE: "GET_BLOG_FAILURE",
};

export const FARMER = {
  ADD_FARMER: "ADD_FARMER",
  ADD_FARMER_SUCCESS: "ADD_FARMER_SUCCESS",
  ADD_FARMER_FAILURE: "ADD_FARMER_FAILURE",
  CREATE_FARMER: "CREATE_FARMER",
  CREATE_FARMER_SUCCESS: "CREATE_FARMER_SUCCESS",
  CREATE_FARMER_FAILURE: "CREATE_FARMER_FAILURE",
  REMOVE_FARMER: "REMOVE_FARMER",
  REMOVE_FARMER_SUCCESS: "REMOVE_FARMER_SUCCESS",
  REMOVE_FARMER_FAILURE: "REMOVE_FARMER_FAILURE",
  UPDATE_FARMER: "UPDATE_FARMER",
  UPDATE_FARMER_SUCCESS: "UPDATE_FARMER_SUCCESS",
  UPDATE_FARMER_FAILURE: "UPDATE_FARMER_FAILURE",
  FIND_FARMER: "FIND_FARMER",
  FIND_FARMER_SUCCESS: "FIND_FARMER_SUCCESS",
  FIND_FARMER_FAILURE: "FIND_FARMER_FAILURE",
  GET_FARMER: "GET_FARMER",
  GET_FARMER_SUCCESS: "GET_FARMER_SUCCESS",
  GET_FARMER_FAILURE: "GET_FARMER_FAILURE",
};

export const CONTACT_US = {
  CONTACT_US_SEND_MAIL: "CONTACT_US_SEND_MAIL",
  CONTACT_US_SEND_MAIL_SUCCESS: "CONTACT_US_SEND_MAIL_SUCCESS",
  CONTACT_US_SEND_MAIL_FAILURE: "CONTACT_US_SEND_MAIL_FAILURE",
};

export const INVESTOR = {
  ADD_INVESTOR: "ADD_INVESTOR",
  ADD_INVESTOR_SUCCESS: "ADD_INVESTOR_SUCCESS",
  ADD_INVESTOR_FAILURE: "ADD_INVESTOR_FAILURE",
  CREATE_INVESTOR: "CREATE_INVESTOR",
  CREATE_INVESTOR_SUCCESS: "CREATE_INVESTOR_SUCCESS",
  CREATE_INVESTOR_FAILURE: "CREATE_INVESTOR_FAILURE",
  REMOVE_INVESTOR: "REMOVE_INVESTOR",
  REMOVE_INVESTOR_SUCCESS: "REMOVE_INVESTOR_SUCCESS",
  REMOVE_INVESTOR_FAILURE: "REMOVE_INVESTOR_FAILURE",
  UPDATE_INVESTOR: "UPDATE_INVESTOR",
  UPDATE_INVESTOR_SUCCESS: "UPDATE_INVESTOR_SUCCESS",
  UPDATE_INVESTOR_FAILURE: "UPDATE_INVESTOR_FAILURE",
  FIND_INVESTOR: "FIND_INVESTOR",
  FIND_INVESTOR_SUCCESS: "FIND_INVESTOR_SUCCESS",
  FIND_INVESTOR_FAILURE: "FIND_INVESTOR_FAILURE",
  GET_INVESTOR: "GET_INVESTOR",
  GET_INVESTOR_SUCCESS: "GET_INVESTOR_SUCCESS",
  GET_INVESTOR_FAILURE: "GET_INVESTOR_FAILURE",
};

export const PRODUCT = {
  ADD_PRODUCT: "ADD_PRODUCT",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",
  REMOVE_PRODUCT: "REMOVE_PRODUCT",
  REMOVE_PRODUCT_SUCCESS: "REMOVE_PRODUCT_SUCCESS",
  REMOVE_PRODUCT_FAILURE: "REMOVE_PRODUCT_FAILURE",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  FIND_PRODUCT: "FIND_PRODUCT",
  FIND_PRODUCT_SUCCESS: "FIND_PRODUCT_SUCCESS",
  FIND_PRODUCT_FAILURE: "FIND_PRODUCT_FAILURE",
  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
};

export const GUPDATE = {
  ADD_GUPDATE: "ADD_GUPDATE",
  ADD_GUPDATE_SUCCESS: "ADD_GUPDATE_SUCCESS",
  ADD_GUPDATE_FAILURE: "ADD_GUPDATE_FAILURE",
  CREATE_GUPDATE: "CREATE_GUPDATE",
  CREATE_GUPDATE_SUCCESS: "CREATE_GUPDATE_SUCCESS",
  CREATE_GUPDATE_FAILURE: "CREATE_GUPDATE_FAILURE",
  REMOVE_GUPDATE: "REMOVE_GUPDATE",
  REMOVE_GUPDATE_SUCCESS: "REMOVE_GUPDATE_SUCCESS",
  REMOVE_GUPDATE_FAILURE: "REMOVE_GUPDATE_FAILURE",
  UPDATE_GUPDATE: "UPDATE_GUPDATE",
  UPDATE_GUPDATE_SUCCESS: "UPDATE_GUPDATE_SUCCESS",
  UPDATE_GUPDATE_FAILURE: "UPDATE_GUPDATE_FAILURE",
  FIND_GUPDATE: "FIND_GUPDATE",
  FIND_GUPDATE_SUCCESS: "FIND_GUPDATE_SUCCESS",
  FIND_GUPDATE_FAILURE: "FIND_GUPDATE_FAILURE",
  GET_GUPDATE: "GET_GUPDATE",
  GET_GUPDATE_SUCCESS: "GET_GUPDATE_SUCCESS",
  GET_GUPDATE_FAILURE: "GET_GUPDATE_FAILURE",
};

export const TOPICS = {
  ADD_TOPICS: "ADD_TOPICS",
  ADD_TOPICS_SUCCESS: "ADD_TOPICS_SUCCESS",
  ADD_TOPICS_FAILURE: "ADD_TOPICS_FAILURE",
  CREATE_TOPICS: "CREATE_TOPICS",
  CREATE_TOPICS_SUCCESS: "CREATE_TOPICS_SUCCESS",
  CREATE_TOPICS_FAILURE: "CREATE_TOPICS_FAILURE",
  REMOVE_TOPICS: "REMOVE_TOPICS",
  REMOVE_TOPICS_SUCCESS: "REMOVE_TOPICS_SUCCESS",
  REMOVE_TOPICS_FAILURE: "REMOVE_TOPICS_FAILURE",
  UPDATE_TOPICS: "UPDATE_TOPICS",
  UPDATE_TOPICS_SUCCESS: "UPDATE_TOPICS_SUCCESS",
  UPDATE_TOPICS_FAILURE: "UPDATE_TOPICS_FAILURE",
  FIND_TOPICS: "FIND_TOPICS",
  FIND_TOPICS_SUCCESS: "FIND_TOPICS_SUCCESS",
  FIND_TOPICS_FAILURE: "FIND_TOPICS_FAILURE",
  GET_TOPICS: "GET_TOPICS",
  GET_TOPICS_SUCCESS: "GET_TOPICS_SUCCESS",
  GET_TOPICS_FAILURE: "GET_TOPICS_FAILURE",
};

export const POSTS = {
  ADD_POSTS: "ADD_POSTS",
  ADD_POSTS_SUCCESS: "ADD_POSTS_SUCCESS",
  ADD_POSTS_FAILURE: "ADD_POSTS_FAILURE",
  CREATE_POSTS: "CREATE_POSTS",
  CREATE_POSTS_SUCCESS: "CREATE_POSTS_SUCCESS",
  CREATE_POSTS_FAILURE: "CREATE_POSTS_FAILURE",
  REMOVE_POSTS: "REMOVE_POSTS",
  REMOVE_POSTS_SUCCESS: "REMOVE_POSTS_SUCCESS",
  REMOVE_POSTS_FAILURE: "REMOVE_POSTS_FAILURE",
  UPDATE_POSTS: "UPDATE_POSTS",
  UPDATE_POSTS_SUCCESS: "UPDATE_POSTS_SUCCESS",
  UPDATE_POSTS_FAILURE: "UPDATE_POSTS_FAILURE",
  FIND_POSTS: "FIND_POSTS",
  FIND_POSTS_SUCCESS: "FIND_POSTS_SUCCESS",
  FIND_POSTS_FAILURE: "FIND_POSTS_FAILURE",
  GET_POSTS: "GET_POSTS",
  GET_POSTS_SUCCESS: "GET_POSTS_SUCCESS",
  GET_POSTS_FAILURE: "GET_POSTS_FAILURE",
};

export const CATEGORY = {
  ADD_CATEGORY: "ADD_CATEGORY",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_FAILURE: "ADD_CATEGORY_FAILURE",
  CREATE_CATEGORY: "CREATE_CATEGORY",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  REMOVE_CATEGORY: "REMOVE_CATEGORY",
  REMOVE_CATEGORY_SUCCESS: "REMOVE_CATEGORY_SUCCESS",
  REMOVE_CATEGORY_FAILURE: "REMOVE_CATEGORY_FAILURE",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",
  FIND_CATEGORY: "FIND_CATEGORY",
  FIND_CATEGORY_SUCCESS: "FIND_CATEGORY_SUCCESS",
  FIND_CATEGORY_FAILURE: "FIND_CATEGORY_FAILURE",
  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
};

export const ORDERS = {
  ADD_ORDERS: "ADD_ORDERS",
  ADD_ORDERS_SUCCESS: "ADD_ORDERS_SUCCESS",
  ADD_ORDERS_FAILURE: "ADD_ORDERS_FAILURE",
  CREATE_ORDERS: "CREATE_ORDERS",
  CREATE_ORDERS_SUCCESS: "CREATE_ORDERS_SUCCESS",
  CREATE_ORDERS_FAILURE: "CREATE_ORDERS_FAILURE",
  REMOVE_ORDERS: "REMOVE_ORDERS",
  REMOVE_ORDERS_SUCCESS: "REMOVE_ORDERS_SUCCESS",
  REMOVE_ORDERS_FAILURE: "REMOVE_ORDERS_FAILURE",
  UPDATE_ORDERS: "UPDATE_ORDERS",
  UPDATE_ORDERS_SUCCESS: "UPDATE_ORDERS_SUCCESS",
  UPDATE_ORDERS_FAILURE: "UPDATE_ORDERS_FAILURE",
  FIND_ORDERS: "FIND_ORDERS",
  FIND_ORDERS_SUCCESS: "FIND_ORDERS_SUCCESS",
  FIND_ORDERS_FAILURE: "FIND_ORDERS_FAILURE",
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",
};

export const USER = {
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  REMOVE_USER: "REMOVE_USER",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAILURE: "REMOVE_USER_FAILURE",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  FIND_USER: "FIND_USER",
  FIND_USER_SUCCESS: "FIND_USER_SUCCESS",
  FIND_USER_FAILURE: "FIND_USER_FAILURE",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "FIND_USER_FAILURE",
};

export const STATS = {
  FETCH_STATISTICS: "FETCH_STATISTICS",
  FETCH_STATISTICS_SUCCESS: "FETCH_STATISTICS_SUCCESS",
  FETCH_STATISTICS_FAILURE: "FETCH_STATISTICS_FAILURE",
};

export const GENERATE_CSV = {
  GENERATE_CSV: "GENERATE_CSV",
  GENERATE_CSV_SUCCESS: "GENERATE_CSV_SUCCESS",
  GENERATE_CSV_FAILURE: "GENERATE_CSV_FAILURE",
};
