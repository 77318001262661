import { USER } from "../variables";

export const createUser = (data) => ({
  type: USER.ADD_USER,
  payload: data,
});

export const createUserSucess = (data) => ({
  type: USER.ADD_USER_SUCCESS,
  payload: data,
});

export const createUserFailure = (data) => ({
  type: USER.ADD_USER_FAILURE,
  payload: data,
});

export const updateUser = (data) => ({
  type: USER.UPDATE_USER,
  payload: data,
});

export const updateUserSucess = (data) => ({
  type: USER.UPDATE_USER_SUCCESS,
  payload: data,
});

export const updateUserFailure = (data) => ({
  type: USER.UPDATE_USER_FAILURE,
  payload: data,
});

export const removeUser = (data) => ({
  type: USER.REMOVE_USER,
  payload: data,
});

export const removeUserSucess = (data) => ({
  type: USER.REMOVE_USER_SUCCESS,
  payload: data,
});

export const removeUserFailure = (data) => ({
  type: USER.REMOVE_USER_FAILURE,
  payload: data,
});

export const findUser = (data) => ({
  type: USER.FIND_USER,
  payload: data,
});

export const findUserSucess = (data) => ({
  type: USER.FIND_USER_SUCCESS,
  payload: data,
});

export const findUserFailure = (data) => ({
  type: USER.FIND_USER_FAILURE,
  payload: data,
});

export const getUser = (data) => ({
  type: USER.GET_USER,
  payload: data,
});

export const getUserSucess = (data) => ({
  type: USER.GET_USER_SUCCESS,
  payload: data,
});

export const getUserFailure = (data) => ({
  type: USER.GET_USER_FAILURE,
  payload: data,
});
