import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";

export default function () {
  const tableData = [
    {
      title: "Goat farmers mapping Project Uganda",
      when: "July 2019",
      desc:
        "This project aimed at identifying prominent goat farmers in Uganda, Key best on farm practices, opportunities and challenges.",
    },
    {
      title: "Feeding and Livestock Trends in Uganda",
      when: "August 2019",
      desc:
        "This project aimed at establishing if livestock farmers have adapted to modern feeding practices and corresponding effects",
    },
    {
      title: "A.I strategy and adaptation challenges in Uganda",
      when: "August 2019",
      desc:
        "This project aimed understanding literature around AI efficacy In Goats in relation to other livestock, challenges and state.",
    },
  ];
  return (
    <LayoutFour>
      <Breadcrumb title="Projects">
        <BreadcrumbItem name="Projects" />
        <BreadcrumbItem name="Completed" current />
      </Breadcrumb>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="list-group list-group-flush">
              {tableData.map((_row, index) => (
                <div
                  //   href="#"
                  key={index}
                  className="list-group-item list-group-item-action flex-column align-items-start pt-2"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h4 className="mb-2"> {_row.title}</h4>
                    <small>{_row.when}</small>
                  </div>
                  <p className="mb-3">{_row.desc}</p>
                  {/* <small>Donec id elit non mi porta.</small> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </LayoutFour>
  );
}
