import { BLOG } from "../variables";

export const createBlog = (data) => ({
  type: BLOG.ADD_BLOG,
  payload: data,
});

export const createBlogSucess = (data) => ({
  type: BLOG.ADD_BLOG_SUCCESS,
  payload: data,
});

export const createBlogFailure = (data) => ({
  type: BLOG.ADD_BLOG_FAILURE,
  payload: data,
});

export const updateBlog = (data) => ({
  type: BLOG.UPDATE_BLOG,
  payload: data,
});

export const updateBlogSucess = (data) => ({
  type: BLOG.UPDATE_BLOG_SUCCESS,
  payload: data,
});

export const updateBlogFailure = (data) => ({
  type: BLOG.UPDATE_BLOG_FAILURE,
  payload: data,
});

export const removeBlog = (data) => ({
  type: BLOG.REMOVE_BLOG,
  payload: data,
});

export const removeBlogSucess = (data) => ({
  type: BLOG.REMOVE_BLOG_SUCCESS,
  payload: data,
});

export const removeBlogFailure = (data) => ({
  type: BLOG.REMOVE_BLOG_FAILURE,
  payload: data,
});

export const findBlog = (data) => ({
  type: BLOG.FIND_BLOG,
  payload: data,
});

export const findBlogSucess = (data) => ({
  type: BLOG.FIND_BLOG_SUCCESS,
  payload: data,
});

export const findBlogFailure = (data) => ({
  type: BLOG.FIND_BLOG_FAILURE,
  payload: data,
});

export const getBlog = (filters) => ({
  type: BLOG.GET_BLOG,
  payload: filters,
});

export const getBlogSucess = (data) => ({
  type: BLOG.GET_BLOG_SUCCESS,
  payload: data,
});

export const getBlogFailure = (data) => ({
  type: BLOG.GET_BLOG_FAILURE,
  payload: data,
});
