/* eslint-disable react/prop-types */
import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import PostCardOne from "../../Post/PostCardOne";

export default function BlogOne({ data }) {
  return (
    <div className="blog-one">
      <div className="container">
        <SectionTitle align="center">Our blog</SectionTitle>
        <div className="blog-one__content">
          {data.map((item, index) => (
            <div key={index} className="blog-one__content__item">
              <PostCardOne data={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
