import React from "react";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
import Button from "../../../components/Control/Button";
const UserPage = () => (
  <React.Fragment>
    <div className="row  justify-content-between mt-3 mb-3">
      <SectionTitleOne
        className="col-md-8"
        align="left"
        description="List of Registered"
      >
        Farmers
      </SectionTitleOne>
      <div className="col-md-4">
        <Button
          color="red"
          // as={Link}
          className="mt-4"
          style={{ float: "right" }}
          content={"Create"}
        />
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-12 col-md-12 " style={{ paddingBottom: "30px" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </React.Fragment>
);

export default UserPage;
