import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, farmerAPI } from "../../api";
import {
  createFarmerFailure,
  createFarmerSucess,
  updateFarmerSucess,
  updateFarmerFailure,
  findFarmerSucess,
  removeFarmerSucess,
  removeFarmerFailure,
  findFarmerFailure,
  getFarmerSucess,
  getFarmerFailure,
} from "../actions/farmerActions";
import { FARMER } from "../variables";
function* createFarmer({ payload }) {
  try {
    // const token = yield select(({ authReducer }) => authReducer.token);
    const farmerData = yield call(API.request, farmerAPI.createFarmer(payload));
    if (!farmerData) {
      throw new Error("Request failed");
    }

    yield put(createFarmerSucess(farmerData));
  } catch (error) {
    yield put(createFarmerFailure());
  }
}

function* updateFarmer({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const farmerData = yield call(
      API.request,
      farmerAPI.updateFarmer(token, payload)
    );
    if (!farmerData) {
      throw new Error("Request failed");
    }

    yield put(updateFarmerSucess(farmerData));
  } catch (error) {
    yield put(updateFarmerFailure());
  }
}

function* findFarmer({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const farmerData = yield call(
      API.request,
      farmerAPI.findFarmer(token, payload)
    );
    if (!farmerData) {
      throw new Error("Request failed");
    }

    yield put(findFarmerSucess(farmerData));
  } catch (error) {
    yield put(findFarmerFailure());
  }
}

function* getFarmer({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const farmerData = yield call(
      API.request,
      farmerAPI.getFarmers(token, payload)
    );
    if (!farmerData) {
      throw new Error("Request failed");
    }

    yield put(getFarmerSucess(farmerData));
  } catch (error) {
    yield put(getFarmerFailure());
  }
}

function* removeFarmer({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    yield call(API.request, farmerAPI.removeFarmer(token, payload));

    yield put(removeFarmerSucess(payload));
    yield call(getFarmer, { payload: { limit: 10 } });
  } catch (error) {
    yield put(removeFarmerFailure());
  }
}

export function* createFarmerRequest() {
  yield takeLatest(FARMER.ADD_FARMER, createFarmer);
}

export function* updateFarmerRequest() {
  yield takeLatest(FARMER.UPDATE_FARMER, updateFarmer);
}

export function* findFarmerRequest() {
  yield takeLatest(FARMER.FIND_FARMER, findFarmer);
}

export function* getFarmerRequest() {
  yield takeLatest(FARMER.GET_FARMER, getFarmer);
}

export function* removeFarmerRequest() {
  yield takeLatest(FARMER.REMOVE_FARMER, removeFarmer);
}

export default function* rootSaga() {
  yield all([
    fork(createFarmerRequest),
    fork(findFarmerRequest),
    fork(updateFarmerRequest),
    fork(getFarmerRequest),
    fork(removeFarmerRequest),
  ]);
}
