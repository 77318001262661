/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export default function PostCardOne({ data }) {
  return (
    <div className="post-card-one">
      <div className="post-card-one__date">
        <h5>{moment(data.created_at).format("DD")}</h5>
        <p>{moment(data.created_at).format("MMM")}</p>
      </div>
      <div className="post-card-one__content">
        <div className="post-card-one__content__info">
          <p>
            by{" "}
            <span>
              {Object.prototype.hasOwnProperty.call(data.created_by, "id")
                ? data.created_by.first_name
                : ""}
            </span>
          </p>
        </div>
        <Link
          className="link"
          to={process.env.PUBLIC_URL + "/blog/post/" + data.id}
        >
          {data.title}
        </Link>
      </div>
    </div>
  );
}
