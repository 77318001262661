/* eslint-disable react/prop-types */
import React from "react";
import SectionTitleOne from "../../SectionTitle/SectionTitle";
import { PrevArrow, NextArrow } from "../../../Other/SliderArrow";
import ProductSlider from "../Elements/ProductSlider";

export default function ProductSlideTwo({ data }) {
  const settings = {
    speed: 500,
    slidesToShow: data.length < 4 ? data.length : 4,
    slidesToScroll: 1,
    className: "product-slide__wrapper",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: data.length < 4 ? data.length : 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: data.length < 3 ? data.length : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.length < 2 ? data.length : 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="product-slide -style-2">
      <div className="container">
        <SectionTitleOne align="center" hideDecoration>
          Related Products
        </SectionTitleOne>
        {data.length ? (
          <ProductSlider data={data} sliderSettings={settings} />
        ) : (
          <div className="wishlist__empty">
            <h3>No related Products on Sale!</h3>
          </div>
        )}
      </div>
    </div>
  );
}
