import queryString from "query-string";
import { requestOptions } from "./request";

const createCategory = (token, data) => {
  return {
    url: "categories/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updateCategory = (token, data) => {
  return {
    url: "categories/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getCategorys = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "categories/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findCategory = (token, categoryId) => {
  return {
    url: "categories/" + categoryId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeCategory = (token, categoryId) => {
  return {
    url: "categories/" + categoryId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createCategory,
  updateCategory,
  findCategory,
  removeCategory,
  getCategorys,
};
