/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { loginUser } from "../../redux/actions/authActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import Input from "../../components/Control/Input";

const loginScheme = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid!")
    .required("Email is required!"),
  password: Yup.string().required("Password is required"),
});

const LOGIN_INITIAL = {
  email: "",
  password: "",
};

const messages = {
  processing: "Processing request! ...",
  ok: "Request successful",
  error: "Request failed, confirm that credentials are correct!",
};

function Login({ processing, ok, error, errors, loginUser }) {
  return (
    <LayoutFour>
      <Breadcrumb title="Login" />
      <div className="contact">
        <br />
        <br />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body mt-2">
                  <h3 className="contact-title mb-4 ml-3">Login Form</h3>
                  <div className="container">
                    {processing && (
                      <div className="alert alert-primary" role="alert">
                        {messages.processing}
                      </div>
                    )}

                    {error && (
                      <div className="alert alert-danger" role="alert">
                        <ul>
                          {errors.error &&
                            errors.error.map((_error, index) => (
                              <li key={index}>{_error}</li>
                            ))}
                        </ul>
                      </div>
                    )}

                    {ok && (
                      <div className="alert alert-success" role="alert">
                        {messages.ok}
                      </div>
                    )}
                  </div>

                  <Formik
                    initialValues={LOGIN_INITIAL}
                    validationSchema={loginScheme}
                    onSubmit={(values, actions) => {
                      // console.log(values);
                      setTimeout(() => {
                        loginUser(values, "/admin");
                        actions.setSubmitting(false);
                      }, 1000);
                    }}
                  >
                    {(props) => (
                      <div className="entry-form">
                        <form onSubmit={props.handleSubmit}>
                          <Input
                            type="email"
                            name="email"
                            errDivClass="form-text text-muted"
                            placeholder="Name"
                          />

                          <Input
                            type="password"
                            name="password"
                            placeholder="Password"
                            errDivClass="form-text text-muted"
                          />

                          <div className="row justify-content-end">
                            <div className="col-7">
                              <button
                                className="btn -dark"
                                disabled={props.isSubmitting}
                              >
                                Submit
                              </button>
                            </div>

                            <div
                              className="col-5 mt-4"
                              style={{ textAlign: "right" }}
                            >
                              <Link to="/forgot-pwd">Forgot Password?</Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>

                  <div className="row justify-content-end">
                    <div className="col-12 mt-4 pl-3">
                      Have no account ? <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </LayoutFour>
  );
}

const mapStateToProps = ({ authReducer }) => {
  const { is } = authReducer;
  return {
    ...is,
  };
};

export default connect(mapStateToProps, { loginUser })(Login);
