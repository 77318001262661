/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link, Switch } from "react-router-dom";
import Paginator from "react-hooks-paginator";
import { getOrder, removeOrder } from "../../../redux/actions/orderActions";
import { fetchStatistics } from "../../../redux/actions/generalActions";
import { updateUser } from "../../../redux/actions/userActions";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";

const OrderPages = ({
  orders,
  is,
  error,
  getOrder,
  updateUser,
  userReducer,
  is_admin,
  removeOrder,
  id,
  stats,
  authFarmer,
  is_a_farmer,
  is_superuser,
  is_public_user,
  fetchStatistics,
}) => {
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [txnStatus, setTxnStatus] = useState("");

  useEffect(() => {
    if (is_a_farmer) {
      getOrder({
        limit: pageLimit,
        offset,
        farmer_id: authFarmer.id,
        search: searchText,
        txn_status: txnStatus,
      });
    } else if (is_admin || is_superuser) {
      getOrder({
        limit: pageLimit,
        offset,
        search: searchText,
        txn_status: txnStatus,
      });
    } else if (is_public_user) {
      getOrder({
        limit: pageLimit,
        offset,
        user: id,
        search: searchText,
        txn_status: txnStatus,
      });
    }
    fetchStatistics();
  }, [
    getOrder,
    fetchStatistics,
    offset,
    id,
    is_a_farmer,
    authFarmer.id,
    is_superuser,
    is_public_user,
    is_admin,
    searchText,
    txnStatus,
    authFarmer,
  ]);

  const handleFilter = useCallback((evt, key) => {
    if (key === "search") setSearchText(evt.target.value);
    else if (key === "status") setTxnStatus(evt.target.value);
  }, []);

  return (
    <LoadingOverlay
      active={is.processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="List of orders placed"
        >
          Orders
        </SectionTitleOne>
        <div className="col-md-4">
          {/* <Link
            type="button"
            to={`/admin/orders/test`}
            className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
          >
            More details
          </Link> */}
        </div>
      </div>
      <div className="row justify-content-start mb-5">
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.completed_orders || 0}</h1>
              Completed
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.pending_orders || 0}</h1>
              Pending Orders
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.cancelled_orders || 0}</h1>
              Cancelled Orders
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">UGX {stats.total_amount || 0}</h1>
              Earning
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-start"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-3 ">
          <div className="blog-sidebar__section -search">
            <form>
              <input
                type="text"
                placeholder="Search ..."
                onChange={(evt) => handleFilter(evt, "search")}
                name="search"
              />
              <button disabled>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="col-3 entry-form">
          <form>
            <div className="form-group">
              <select
                className="form-control"
                placeholder="filter by status"
                onChange={(evt) => handleFilter(evt, "status")}
                style={{ borderRadius: 0 }}
              >
                <option value="">filter by status</option>
                <option value="complete">Complete</option>
                <option value="pending">Pending</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      {orders.results.length === 0 ? (
        <div className="wishlist__empty">
          <h3> You have no Orders!</h3>
        </div>
      ) : (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12 col-md-12 ">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">Owner</th>
                  <th scope="col">Coupon</th>
                  {/* <th scope="col">Delivery Method</th>
                  <th scope="col">Payment Method</th> */}
                  {is_admin && <th scope="col">Actions</th>}
                </tr>
              </thead>
              <tbody>
                {orders.results.map((_order) => (
                  <tr key={_order.id}>
                    <th scope="row">{_order.order_id}</th>
                    <td>{_order.txn_status}</td>
                    <td>{`${_order.owner.first_name} ${_order.owner.last_name}`}</td>
                    <td>{_order.coupon}</td>
                    {/* <td>{_order.delivery_method}</td>
                    <td>{_order.payment_method}</td> */}

                    <td>
                      <div className="btn-group" role="group">
                        <Link
                          type="button"
                          to={`/admin/orders/${_order.id}`}
                          className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          More details
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={orders.count}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ ordersReducer, userReducer, authReducer }) => {
  const { orders, is } = ordersReducer;
  const { stats, user, authFarmer } = authReducer;
  const { is_admin, id, is_a_farmer, is_superuser, is_public_user } = user;
  return {
    orders,
    is,
    userReducer,
    is_admin,
    id,
    stats,
    authFarmer,
    is_a_farmer,
    is_superuser,
    is_public_user,
  };
};
export default connect(mapStateToProps, {
  getOrder,
  updateUser,
  removeOrder,
  fetchStatistics,
})(OrderPages);
