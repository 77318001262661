/* eslint-disable react/prop-types */
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function ProductDetailInfoTab({ data }) {
  return (
    <div className="product-detail__tab">
      <Tabs className="product-detail__tab__content">
        <TabList className="tab__content__header">
          <Tab>Description</Tab>
        </TabList>

        <TabPanel className="tab__content__item -description">
          <p>{data && data.description}</p>
        </TabPanel>
      </Tabs>
    </div>
  );
}
