/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Paginator from "react-hooks-paginator";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getOrder } from "../../../redux/actions/orderActions";
const UserOrders = ({ userId }) => {
  const ordersReducer = useSelector(({ ordersReducer }) => ordersReducer);
  const dispatch = useDispatch();

  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(getOrder({ limit: pageLimit, offset, user: userId }));
    return () => {};
  }, [dispatch, offset, userId]);

  return (
    <div className="col-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Status</th>
            <th scope="col">Owner</th>
            <th scope="col">Coupon</th>
            <th scope="col">Delivery Method</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {ordersReducer.orders.results.map((_order) => (
            <tr key={_order.id}>
              <th scope="row">{_order.order_id}</th>
              <td>{_order.txn_status}</td>
              <td>{`${_order.owner.first_name} ${_order.owner.last_name}`}</td>
              <td>{_order.coupon}</td>
              <td>{_order.delivery_method}</td>
              <td>{_order.payment_method}</td>

              <td>
                <div className="btn-group" role="group">
                  <Link
                    type="button"
                    to={`/admin/orders/${_order.id}`}
                    className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                  >
                    More details
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        pageContainerClass="pagination justify-content-center"
        pageItemClass="page-item"
        pageLinkClass="page-link"
        pageActiveClass="active"
        totalRecords={ordersReducer.orders.count}
        pageLimit={pageLimit}
        pageNeighbours={2}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default UserOrders;
