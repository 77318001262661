import { GUPDATE } from "../variables";

export const createGupdate = (data) => ({
  type: GUPDATE.ADD_GUPDATE,
  payload: data,
});

export const createGupdateSucess = (data) => ({
  type: GUPDATE.ADD_GUPDATE_SUCCESS,
  payload: data,
});

export const createGupdateFailure = (data) => ({
  type: GUPDATE.ADD_GUPDATE_FAILURE,
  payload: data,
});

export const updateGupdate = (data) => ({
  type: GUPDATE.UPDATE_GUPDATE,
  payload: data,
});

export const updateGupdateSucess = (data) => ({
  type: GUPDATE.UPDATE_GUPDATE_SUCCESS,
  payload: data,
});

export const updateGupdateFailure = (data) => ({
  type: GUPDATE.UPDATE_GUPDATE_FAILURE,
  payload: data,
});

export const removeGupdate = (data) => ({
  type: GUPDATE.REMOVE_GUPDATE,
  payload: data,
});

export const removeGupdateSucess = (data) => ({
  type: GUPDATE.REMOVE_GUPDATE_SUCCESS,
  payload: data,
});

export const removeGupdateFailure = (data) => ({
  type: GUPDATE.REMOVE_GUPDATE_FAILURE,
  payload: data,
});

export const findGupdate = (data) => ({
  type: GUPDATE.FIND_GUPDATE,
  payload: data,
});

export const findGupdateSucess = (data) => ({
  type: GUPDATE.FIND_GUPDATE_SUCCESS,
  payload: data,
});

export const findGupdateFailure = (data) => ({
  type: GUPDATE.FIND_GUPDATE_FAILURE,
  payload: data,
});

export const getGupdate = (filters) => ({
  type: GUPDATE.GET_GUPDATE,
  payload: filters,
});

export const getGupdateSucess = (data) => ({
  type: GUPDATE.GET_GUPDATE_SUCCESS,
  payload: data,
});

export const getGupdateFailure = (data) => ({
  type: GUPDATE.GET_GUPDATE_FAILURE,
  payload: data,
});
