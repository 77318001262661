/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  ButtonGroup,
  Button as BootButton,
} from "reactstrap";

import Paginator from "react-hooks-paginator";
import {
  getProduct,
  createProduct,
  updateProduct,
  removeProduct,
} from "../../../redux/actions/productActions";
import { fetchStatistics } from "../../../redux/actions/generalActions";
import { getCategory } from "../../../redux/actions/categoryActions";
import { updateUser } from "../../../redux/actions/userActions";
import Button from "../../../components/Control/Button";
import EntryForm from "./EntryForm";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";

const PRODUCT_INITIAL = {
  title: "",
  description: "",
  user: "",
  price: 0,
  quantity: 0,
  breed: "",
  // photo: "",
  age: 0,
  is_pregnant: false,
  is_for_sale: false,
};
const ProductPages = ({
  products,
  processing,
  ok,
  error,
  getProduct,
  updateUser,
  user,
  createProduct,
  updateProduct,
  removeProduct,
  getCategory,
  category,
  stats,
  authFarmer,
  fetchStatistics,
}) => {
  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isViewing, setIsViewing] = useState(false);
  const [selectProduct, setSelectedProduct] = useState(PRODUCT_INITIAL);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");

  const onStatusFilterChange = (status) => setStatusFilter(status);
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    if (
      user &&
      Object.prototype.hasOwnProperty.call(user, "id") &&
      (user.is_superadmin || user.is_admin)
    ) {
      getProduct({ limit: pageLimit, offset, status: statusFilter });
    } else {
      getProduct({
        limit: pageLimit,
        offset,
        user: user.id,
        status: statusFilter,
      });
    }

    getCategory({});
    fetchStatistics();
    return () => {};
  }, [getProduct, getCategory, fetchStatistics, offset, user, statusFilter]);

  const handleSearch = useCallback(
    (searchText) => {
      if (
        user &&
        Object.prototype.hasOwnProperty.call(user, "id") &&
        (user.is_superadmin || user.is_admin)
      ) {
        getProduct({
          limit: pageLimit,
          offset,

          search: searchText,
        });
      } else {
        getProduct({
          limit: pageLimit,
          offset,
          user: user.id,
          search: searchText,
        });
      }
    },
    [getProduct, offset, user]
  );

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);

    if (isOpen) {
      setIsViewing(false);
    }
  }, [isOpen]);

  const handleActivate = useCallback(
    (userData) => {
      updateUser(userData);
    },
    [updateUser]
  );

  const convertToFormData = (jsonData) => {
    let formData = new FormData();
    for (const key in jsonData) {
      if (key === "photo" && !(jsonData[key] instanceof File)) {
        continue;
      }
      formData.append(key, jsonData[key]);
    }
    return formData;
  };

  const handleCreateProduct = useCallback(
    (data) => {
      createProduct(convertToFormData(data));
      setSelectedProduct({});
    },
    [createProduct]
  );

  const handleUpdateProduct = useCallback(
    (data) => {
      updateProduct(convertToFormData(data));
      setSelectedProduct({});
    },
    [updateProduct]
  );

  const handleEditClick = useCallback(
    (data, isView) => {
      setSelectedProduct({
        ...data,
        category: Object.prototype.hasOwnProperty.call(data.category, "id")
          ? data.category.id
          : data.category,
        user: Object.prototype.hasOwnProperty.call(data.user, "id")
          ? data.user.id
          : data.user,
      });
      setIsViewing(isView);
      toggle();
    },
    [toggle]
  );

  const handleUpdateStatus = useCallback(
    (data) => updateProduct(convertToFormData(data)),
    [updateProduct]
  );

  const handleRemoveProduct = useCallback(
    (id) => {
      removeProduct(id);
    },
    [removeProduct]
  );

  return (
    <LoadingOverlay
      active={processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <EntryForm
          isOpen={isOpen}
          toggle={toggle}
          initialValues={selectProduct}
          isViewing={isViewing}
          category={category}
          onSubmit={
            Object.prototype.hasOwnProperty.call(selectProduct, "id")
              ? handleUpdateProduct
              : handleCreateProduct
          }
        />
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="Available Stock"
        >
          Stock
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            <Button
              // color="red"
              // as={Link}
              onClick={(_evt) => toggle()}
              className="btn-info btn-md pl-3 pr-3 pt-2 pb-2 mt-4 mr-2"
              content={"New Stock "}
            />
            <Button
              // color="red"
              // as={Link}
              action="/admin/stock/categories"
              // onClick={(_evt) => toggle()}
              className="btn-secondary btn-md pl-3 pr-3 pt-2 pb-2 mt-4"
              content={"Stock Categories "}
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-start mb-5">
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.stock_for_sale || 0}</h1>
              Stock Available for sale
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.stock_not_for_sale || 0}</h1>
              Stock not for Sale
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-between"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-3 pt-3">
          <div className="blog-sidebar__section -search">
            <form>
              <input
                type="text"
                placeholder="Enter keyword"
                onChange={(evt) => handleSearch(evt.target.value)}
                name="search"
              />
              <button disabled>
                <i className="fas fa-search" />
              </button>
            </form>
          </div>
        </div>
        <div className="col-4">
          <UncontrolledDropdown style={{ float: "right" }}>
            <DropdownToggle
              // className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
              caret
              outline
              size="sm"
              className="pl-3 pr-3 pt-2 pb-2 mt-4"
              color="info"
            >
              Filter By - {statusFilter}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onStatusFilterChange("pending")}>
                Pending
              </DropdownItem>
              <DropdownItem onClick={() => onStatusFilterChange("approved")}>
                Approved
              </DropdownItem>
              <DropdownItem onClick={() => onStatusFilterChange("rejected")}>
                Rejected
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onStatusFilterChange("")}>
                Reset
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      {products.results.length === 0 ? (
        <div className="wishlist__empty">
          <h3> You have no Stock!</h3>
        </div>
      ) : (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12">
            <table className="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">#Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Price (UGX)</th>
                  <th scope="col">Breed</th>
                  <th scope="col">Age (months)</th>
                  <th scope="col">Pregnant</th>
                  <th scope="col">Is for Sale</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Desc</th>
                  {user &&
                    Object.prototype.hasOwnProperty.call(user, "id") &&
                    (user.is_superadmin || user.is_admin) && (
                      <th scope="col">Status</th>
                    )}
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.results.map((_product) => (
                    <tr key={_product.id}>
                      <th>
                        <img src={_product.photo} alt="ads" width="90" />
                      </th>
                      <th scope="row" style={{ verticalAlign: "inherit" }}>
                        {_product.product_code}
                      </th>
                      <td
                        style={{ verticalAlign: "inherit" }}
                      >{`${_product.title}`}</td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.price}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.breed}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.age}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.is_pregnant ? "Yes" : "No"}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.is_for_sale ? "Yes" : "no"}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.quantity}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.quantity > 0 ? "Available" : "Out of Stock"}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        <ButtonGroup size="sm">
                          {user &&
                            Object.prototype.hasOwnProperty.call(user, "id") &&
                            (user.is_superadmin || user.is_admin) && (
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  // className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                                  caret
                                  outline
                                  size="sm"
                                  className="pl-3 pr-3 pt-2 pb-2 mt-4"
                                  color="primary"
                                >
                                  {_product.stock_status}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {_product.stock_status !== "approved" && (
                                    <DropdownItem
                                      onClick={(_evt) => {
                                        handleUpdateStatus({
                                          id: _product.id,
                                          stock_status: "approved",
                                        });
                                      }}
                                    >
                                      Approve
                                    </DropdownItem>
                                  )}

                                  {_product.stock_status !== "rejected" && (
                                    <React.Fragment>
                                      {_product.stock_status !== "approved" && (
                                        <DropdownItem divider />
                                      )}
                                      <DropdownItem
                                        onClick={(_evt) =>
                                          handleUpdateStatus({
                                            id: _product.id,
                                            stock_status: "rejected",
                                          })
                                        }
                                      >
                                        Reject
                                      </DropdownItem>
                                    </React.Fragment>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}

                          <UncontrolledDropdown>
                            <DropdownToggle
                              // className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                              caret
                              outline
                              size="sm"
                              className="pl-3 pr-3 pt-2 pb-2 mt-4"
                              color="info"
                            >
                              More
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={(_evt) => {
                                  handleEditClick(_product, true);
                                }}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(_evt) =>
                                  handleEditClick(_product, false)
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(_evt) =>
                                  handleRemoveProduct(_product.id)
                                }
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={products.count}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ productReducer, authReducer, categoryReducer }) => {
  const { products, is } = productReducer;
  const { category } = categoryReducer;
  const { user, stats, authFarmer } = authReducer;
  return {
    products,
    ...is,
    user,
    stats,
    category,
    authFarmer,
  };
};
export default connect(mapStateToProps, {
  getProduct,
  updateUser,
  createProduct,
  updateProduct,
  removeProduct,
  getCategory,
  fetchStatistics,
})(ProductPages);
