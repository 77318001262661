/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import Slider from "react-slick";

import SectionTitleOne from "../SectionTitle/SectionTitle";
import TeamCard from "./Elements/TeamCard";
import { PrevArrow, NextArrow } from "../../Other/SliderArrow";

export default function TeamOne({ data }) {
  const settings = {
    infinite: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="team-one">
      <div className="container">
        <SectionTitleOne align="center" spaceBottom="1.875em">
          Professional team
        </SectionTitleOne>
        <Slider {...settings}>
          {data.map((user, index) => (
            <div key={index} className="slider__item">
              <TeamCard data={user} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
