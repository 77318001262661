import { push } from "connected-react-router";
import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, blogAPI } from "../../api";
import {
  createBlogFailure,
  createBlogSucess,
  updateBlogSucess,
  updateBlogFailure,
  findBlogSucess,
  removeBlogSucess,
  removeBlogFailure,
  findBlogFailure,
  getBlogSucess,
  getBlogFailure,
} from "../actions/blogActions";
import { BLOG } from "../variables";
function* createBlog({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const blogData = yield call(
      API.request,
      blogAPI.createBlog(token, payload)
    );
    if (!blogData) {
      throw new Error("Request failed");
    }

    yield put(createBlogSucess(blogData));

    yield put(push("/admin/blog"));
    yield call(getBlog, { payload: { limit: 6 } });
  } catch (error) {
    yield put(createBlogFailure());
  }
}

function* updateBlog({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const blogData = yield call(
      API.request,
      blogAPI.updateBlog(token, payload)
    );
    if (!blogData) {
      throw new Error("Request failed");
    }

    yield put(updateBlogSucess(blogData));
  } catch (error) {
    yield put(updateBlogFailure());
  }
}

function* findBlog({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const blogData = yield call(API.request, blogAPI.findBlog(token, payload));
    if (!blogData) {
      throw new Error("Request failed");
    }

    yield put(findBlogSucess(blogData));
  } catch (error) {
    yield put(findBlogFailure());
  }
}

function* getBlog({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const blogData = yield call(API.request, blogAPI.getBlogs(token, payload));
    if (!blogData) {
      throw new Error("Request failed");
    }

    yield put(getBlogSucess(blogData));
  } catch (error) {
    yield put(getBlogFailure());
  }
}

function* removeBlog({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    yield call(API.request, blogAPI.removeBlog(token, payload));

    yield put(removeBlogSucess(payload));
    yield call(getBlog, { payload: { limit: 10 } });
  } catch (error) {
    yield put(removeBlogFailure());
  }
}

export function* createBlogRequest() {
  yield takeLatest(BLOG.ADD_BLOG, createBlog);
}

export function* updateBlogRequest() {
  yield takeLatest(BLOG.UPDATE_BLOG, updateBlog);
}

export function* findBlogRequest() {
  yield takeLatest(BLOG.FIND_BLOG, findBlog);
}

export function* getBlogRequest() {
  yield takeLatest(BLOG.GET_BLOG, getBlog);
}

export function* removeBlogRequest() {
  yield takeLatest(BLOG.REMOVE_BLOG, removeBlog);
}

export default function* rootSaga() {
  yield all([
    fork(createBlogRequest),
    fork(findBlogRequest),
    fork(updateBlogRequest),
    fork(getBlogRequest),
    fork(removeBlogRequest),
  ]);
}
