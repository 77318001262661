/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import Paginator from "react-hooks-paginator";
import { getBlog, removeBlog } from "../../../redux/actions/blogActions";
import { generateCsv } from "../../../redux/actions/generalActions";

import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
import { push, goBack } from "connected-react-router";
// eslint-disable-next-line react/prop-types
const FarmerPages = ({
  blogs,
  processing,
  ok,
  error,
  getBlog,
  push,
  removeBlog,
}) => {
  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  // const [data, setData] = useState([]);

  useEffect(() => {
    getBlog({ limit: pageLimit, offset, search: searchText });

    return () => {};
  }, [getBlog, offset, searchText]);

  const handleRemoveEdit = useCallback((blogId) => removeBlog(blogId), [
    removeBlog,
  ]);

  const gotoCreate = useCallback(() => push("/admin/blog/new"), [push]);
  const gotoView = useCallback((id) => push(`/blog/post/${id}`), [push]);
  return (
    <LoadingOverlay
      active={processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="List of All blogs"
        >
          Blogs
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            <Button
              color="red"
              onClick={gotoCreate}
              className="mt-4"
              style={{ float: "right" }}
              content={"Create "}
            />
          </div>
        </div>
      </div>

      <div
        className="row justify-content-between"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-3 ">
          <div className="blog-sidebar__section -search">
            <form>
              <input
                type="text"
                placeholder="Enter keyword"
                onChange={(evt) => setSearchText(evt.target.value)}
                name="search"
              />
              <button disabled>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
      {blogs.count > 0 ? (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12 col-md-12 ">
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col"></th>
                  <th scope="col">Title</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {blogs.results.map((_blog) => (
                  <tr key={_blog.id}>
                    <th>
                      <img src={_blog.photo} alt="ads" width="90" />
                    </th>
                    <td>{_blog.title}</td>
                    <td>
                      <div className="btn-group" role="group">
                        {/* <Link
                        type="button"
                        to={`/admin/blog/edit/${_blog.id}`}
                        className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                      >
                        Edit
                      </Link> */}
                        <button
                          type="button"
                          onClick={(evt) => gotoView(_blog.id)}
                          className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          View
                        </button>
                        |
                        <button
                          type="button"
                          onClick={(evt) => handleRemoveEdit(_blog.id)}
                          className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={blogs.count}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      ) : (
        <div className="wishlist__empty">
          <h3>No new content, for now!</h3>
        </div>
      )}
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ blogReducer, userReducer, authReducer }) => {
  const { blogs, is } = blogReducer;
  const { stats, user } = authReducer;
  const { is_admin, is_a_blog } = user;
  return {
    blogs,
    ...is,
    userReducer,
    is_admin,
    is_a_blog,
    stats,
  };
};
export default connect(mapStateToProps, {
  getBlog,
  removeBlog,
  generateCsv,
  push,
})(FarmerPages);
