// eslint-disable-next-line react/prop-types
import React, { useCallback, useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import LoadingOverlay from "react-loading-overlay";
import Paginator from "react-hooks-paginator";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchInvestmentProductsMachine } from "../../../../machines/fetchInvestmentProductsMachine";
import SectionTitleOne from "../../../../components/Sections/SectionTitle/SectionTitle";
import { formatCurrency } from "../../../../common/utils";
import InvestmentProductDetails from "./details";
import { updateInvestmentProductsMachine } from "../../../../machines/updateInvestmentProductMachine";
import {useLocation} from "react-router-dom";

// eslint-disable-next-line react/prop-types
const InvestmentProducts = ({ userId, isAdmin, hideHeader }) => {
  const { token, oid, user } = useSelector(({ authReducer }) => authReducer);

  if (userId || userId === undefined) {
    userId = oid;
  }


  console.log("user", userId)

  if (isAdmin || isAdmin === undefined) {
    isAdmin = user.is_admin || user.is_superuser;
  }
  const [current, send] = useMachine(fetchInvestmentProductsMachine, {
    devTools: false,
  });

  const [
    updateInvestmentProductMachineState,
    updateInvestmentProductMachineInvoke,
  ] = useMachine(updateInvestmentProductsMachine, {
    devTools: false,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(undefined);
  const [isOpen, setOpen] = useState(false);

  const fetchProducts = (isAdmin, token, userId, offset) => {
    const payload = {
      limit: pageLimit,
      offset: offset,
    };
    if (isAdmin) {
      send({
        type: "FETCH",
        data: {
          token: token,
          payload: payload,
        },
      });
    } else {
      send({
        type: "FETCH",
        data: {
          token: token,
          payload: { ...payload, investor_id_user: userId },
        },
      });
    }
  };

  useEffect(() => {
    fetchProducts(isAdmin, token, userId, offset);
    return () => {};
  }, [isAdmin, userId]);

  const { response } = current.context;
  const showInvestment = (product) => {
    setData(product);
    setOpen(true);
  };

  const closeDetails = useCallback(() => {
    setData(undefined);
    setOpen(false);

    if (updateInvestmentProductMachineState.matches("success")) {
      if (isAdmin) {
        fetchProducts(isAdmin, token, userId);
      }
    }
  }, [
    updateInvestmentProductMachineState,
    fetchProducts,
    userId,
    token,
    isAdmin,
  ]);

  const updateInvestment = useCallback(
    ({ id, status }) => {
      updateInvestmentProductMachineInvoke({
        type: "UPDATE",
        data: {
          token: token,
          payload: { id, status },
        },
      });
    },
    [updateInvestmentProductMachineInvoke, token]
  );

  const totalRecords =
    (response &&
      Object.prototype.hasOwnProperty.call(response, "results") &&
      response.count) ||
    0;
  return (
    <LoadingOverlay
      active={current.matches("fetching")}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      {!hideHeader && (
        <div className="row  justify-content-between mt-3 mb-3">
          <SectionTitleOne
            className="col-md-8"
            align="left"
            description="Investment Products"
          >
            Products
          </SectionTitleOne>
          <div className="col-md-4" />
        </div>
      )}
      {current.matches({ success: "unknown" }) && (
        <div className="wishlist__empty">
          <h3> Something wrong happened </h3>
        </div>
      )}
      {current.matches({ success: "withoutData" }) && (
        <div className="wishlist__empty">
          <h3> No data </h3>
        </div>
      )}
      {current.matches({ success: "withData" }) && (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <InvestmentProductDetails
            isOpen={isOpen}
            closeDetails={closeDetails}
            quantity={data && data.no_assets}
            investmentYears={data && data.no_of_years}
            price={data && data.product_id.price}
            roi={data && data.rate_of_return}
            productCode={data && data.product_id.product_code}
            isAdmin={isAdmin}
            status={data && data.status}
            investmentId={data && data.id}
            investorId={data && data.investor_id}
            updateInvestment={updateInvestment}
            product={data && data.product_id}
          />
          <div className="col-12">
            <table className="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">#Code</th>
                  <th scope="col">Number of Goats</th>
                  <th scope="col">Years</th>
                  <th scope="col">Investment</th>
                  <th scope="col">ROI</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Status</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {response &&
                  Object.prototype.hasOwnProperty.call(response, "results") &&
                  response.results.map((_product) => (
                    <tr key={_product.id}>
                      <th scope="row" style={{ verticalAlign: "inherit" }}>
                        #
                        {_product.product_id &&
                          _product.product_id.product_code}
                      </th>

                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.no_assets}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.no_of_years}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {formatCurrency(_product.amount_invested)}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {formatCurrency(_product.roi)}
                      </td>

                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.rate_of_return}%
                      </td>
                      <td
                        style={{
                          verticalAlign: "inherit",
                          textTransform: "capitalize",
                        }}
                      >
                        {_product.status}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        <Button
                          onClick={(_evt) => showInvestment(_product)}
                          className="btn-info btn-sm"
                          style={{
                            textTransform: "capitalize",
                            // height: "40px",
                            padding: "4px 12px",
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
// const mapStateToProps = ({ authReducer, token, userId, isAdmin }) => {
//   console.log(token, userId, isAdmin);
//   // const { token, oid, user } = authReducer;
//   return {
//     token: token ? token : authReducer.token,
//     userId: userId ? userId : authReducer.oid,
//     isAdmin: isAdmin
//       ? isAdmin
//       : authReducer.user &&
//         (authReducer.user.is_admin || authReducer.user.is_superuser),
//   };
// };
export default InvestmentProducts;
