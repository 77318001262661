import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import Paginator from "react-hooks-paginator";
import { getTopic, removeTopic } from "../../redux/actions/topicActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { useRouter } from "../../common/useRouter";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import Topic from "./topic";
const ForumPage = () => {
  const { topics } = useSelector(({ topicsReducer }) => topicsReducer);
  //   const { user, token } = useSelector(({ authReducer }) => authReducer);
  const router = useRouter();
  const dispatch = useDispatch();
  const sort = router.query.sort;
  const pageLimit = 4;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getTopic({
        limit: pageLimit,
        offset,
        sort,
      })
    );
    return () => {};
  }, [dispatch, offset, sort]);

  const removeTopicHandler = useCallback(
    (topic) => {
      confirmAlert({
        title: "Are you sure?",
        message: "You are want to delete this Conversion?",
        buttons: [
          {
            label: "Yes",
            onClick: () => dispatch(removeTopic(topic.id)),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [dispatch]
  );

  return (
    <LayoutFour>
      <Breadcrumb title="Forum" />
      <div className="container all-columns">
        <div className="column-text closed">
          <div className="header">
            <div className="minigrid">
              <h1>Discussion (s)</h1>
              <div className="link">
                <Link className="linkbutton" to="/forum/discussion/new">
                  Ask Question
                </Link>
              </div>
            </div>

            <div className="minigrid subtitle">
              <div className="count">{topics.results.length} thread (s)</div>
              <div className="sort">
                <Link
                  aria-current="page"
                  className={sort === "newest" ? "selected active" : ""}
                  to="/forum?sort=newest"
                >
                  Newest
                </Link>
                <Link
                  aria-current="page"
                  className={sort === "oldest" ? "selected active" : ""}
                  to="/forum?sort=oldest"
                >
                  Oldest
                </Link>
              </div>
            </div>
          </div>
          <div className="content">
            {topics.count ? (
              topics.results.map((topic) => (
                <Topic
                  key={topic.id}
                  data={topic}
                  removeTopicHandler={removeTopicHandler}
                />
              ))
            ) : (
              <div className="wishlist__empty">
                <h3>No discussion started!</h3>
              </div>
            )}
          </div>
          <Paginator
            pageContainerClass="pagination justify-content-center"
            pageItemClass="page-item"
            pageLinkClass="page-link"
            pageActiveClass="active"
            totalRecords={topics.count}
            pageLimit={pageLimit}
            pageNeighbours={2}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </LayoutFour>
  );
};

export default ForumPage;
