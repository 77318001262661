import React, { useState, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import LayoutFour from "../../components/Layout/LayoutFour";
import Input from "../../components/Control/Input";
import { createTopic } from "../../redux/actions/topicActions";
const entryScheme = Yup.object().shape({
  body: Yup.string().required().label("Body"),
  subject: Yup.string().required().label("Subject"),
});

const TOPIC_INITIAL = {
  body: "",
  subject: "",
  starter: "",
};

const NewDiscussion = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const dispatch = useDispatch();
  const { user } = useSelector(({ authReducer }) => authReducer);
  const createTopicHandler = useCallback(
    (_data) => {
      if (editorState.getCurrentContent().hasText()) {
        dispatch(createTopic(_data));
      }
    },
    [dispatch, editorState]
  );

  return (
    <LayoutFour>
      <Breadcrumb title="Forum" />
      <div className="container all-columns">
        <div className="column-text none">
          <div className="header">
            <h1> Ask Question </h1>
          </div>
          <div className="content">
            <Formik
              initialValues={{
                ...TOPIC_INITIAL,
                starter: user.id,
              }}
              validationSchema={entryScheme}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                if (editorState.getCurrentContent().hasText()) {
                  // console.log(values);
                  setTimeout(() => {
                    createTopicHandler(values);
                    actions.setSubmitting(false);
                  }, 1000);
                }
              }}
            >
              {(props) => (
                <div className="entry-form">
                  <form className="editor" onSubmit={props.handleSubmit}>
                    <Input
                      type="text"
                      name="subject"
                      value={props.values.subject}
                      placeholder="Subject"
                    />

                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      onBlur={props.handleBlur("body")}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "emoji",
                          "image",
                          "remove",
                          "history",
                        ],
                      }}
                      onContentStateChange={(data) => {
                        // console.log("Data", data);
                        if (editorState.getCurrentContent().hasText()) {
                          props.setFieldValue("body", JSON.stringify(data));
                        }
                      }}
                    />

                    {user &&
                    Object.prototype.hasOwnProperty.call(user, "id") ? (
                      <button
                        className="btn btn-primary"
                        disabled={props.isSubmitting}
                        type="submit"
                      >
                        Submit
                      </button>
                    ) : (
                      <Link
                        className="btn btn-sm btn-primary p-2 mt-2"
                        style={{ textTransform: "capitalize" }}
                        to="/login"
                      >
                        Sign in first
                      </Link>
                    )}
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </LayoutFour>
  );
};

export default NewDiscussion;
