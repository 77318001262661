import { INVESTOR } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  investors: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  investor: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function investorReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };
    case INVESTOR.ADD_INVESTOR:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case INVESTOR.ADD_INVESTOR_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case INVESTOR.ADD_INVESTOR_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case INVESTOR.FIND_INVESTOR:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case INVESTOR.FIND_INVESTOR_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        investor: action.payload,
      };
    case INVESTOR.FIND_INVESTOR_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case INVESTOR.UPDATE_INVESTOR:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case INVESTOR.UPDATE_INVESTOR_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case INVESTOR.UPDATE_INVESTOR_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case INVESTOR.GET_INVESTOR:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case INVESTOR.GET_INVESTOR_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        investors: action.payload,
      };
    case INVESTOR.GET_INVESTOR_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case INVESTOR.REMOVE_INVESTOR:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case INVESTOR.REMOVE_INVESTOR_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case INVESTOR.REMOVE_INVESTOR_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
