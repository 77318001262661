/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
// import { useForm } from "react-hook-form";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendMail } from "../../redux/actions/contactUsActions";
import Input from "../../components/Control/Input";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import ContactInfoItem from "../../components/Pages/Contact/ContactInfoItem";
import contactData from "../../data/pages/contact.json";

const loginScheme = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  name: Yup.string().required().label("Name"),
  message: Yup.string().required().label("Message"),
});

const LOGIN_INITIAL = {
  email: "",
  name: "",
  message: "",
};
export default function Contact() {
  const { processing, ok, error } = useSelector(
    ({ sendMailReducer }) => sendMailReducer.is
  );

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data) => {
      dispatch(sendMail(data));
    },
    [dispatch]
  );

  const messages = {
    processing: "Submitting request! ...",
    ok: "Thanks for reaching out, we shall contact you shortly!",
    error: "Request failed, try again!",
  };
  return (
    <LayoutFour title="Mefarms | Contact us">
      <Breadcrumb title="Contact us">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="Contact us" current />
      </Breadcrumb>
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 className="contact-title">Contact info</h3>
              {contactData &&
                contactData.map((item, index) => (
                  <ContactInfoItem
                    key={index}
                    iconClass={item.iconClass}
                    title={item.title}
                    detail={item.detail}
                  />
                ))}
            </div>
            <div className="col-12 col-md-6">
              <h3 className="contact-title">Get in touch</h3>
              <div className="entry-form">
                {processing && (
                  <div className="alert alert-primary" role="alert">
                    {messages.processing}
                  </div>
                )}

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {messages.error}
                  </div>
                )}

                {ok && (
                  <div className="alert alert-success" role="alert">
                    {messages.ok}
                  </div>
                )}
                <Formik
                  initialValues={LOGIN_INITIAL}
                  validationSchema={loginScheme}
                  onSubmit={(values, actions) => {
                    // console.log(values);
                    setTimeout(() => {
                      handleSubmit(values);
                      console.log(values);
                      actions.setSubmitting(false);
                    }, 1000);
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Input type="text" name="name" placeholder="Name" />

                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        // ref={register({ required: true })}
                      />

                      <Input
                        name="message"
                        id=""
                        cols="30"
                        rows="3"
                        type="textarea"
                        placeholder="Message"
                      />

                      <button
                        className="btn -dark"
                        type="submit"
                        disabled={props.isSubmitting || processing}
                      >
                        SEND MESSAGE
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutFour>
  );
}
