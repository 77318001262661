/* eslint-disable react/prop-types */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import Input from "../../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";

const FILE_SIZE = 1000000;
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const entryScheme = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  description: Yup.string().nullable().label("Description"),
  user: Yup.string(),
  age: Yup.number().required().min(1).label("Age"),
  price: Yup.number().required().min(1000).label("Price"),
  quantity: Yup.number().required().label("Quantity"),
  breed: Yup.string().required().label("Breed"),
  is_pregnant: Yup.bool().default(false),
  is_for_sale: Yup.bool().default(false),
  product_type: Yup.string().default("sale").required().label("Product Type"),
  category: Yup.string().required().label("Stock Category"),
  photo: Yup.mixed()
    .required(
      "Image is required",
      (value) => !(value instanceof String || value instanceof File)
    )
    .test(
      "fileFormat",
      "Unsupported Format! only upload .png, .jpg and .jpeg images",
      (value) => {
        let result = false;
        if (value instanceof File) {
          result = value && SUPPORTED_IMAGE_FORMATS.includes(value.type);
        } else {
          result = true;
        }

        return result;
      }
    )
    .test("fileSize", "File too large, should be less than 468", (value) => {
      let result = false;
      if (value instanceof File) {
        result = value && value.size <= FILE_SIZE;
      } else {
        result = true;
      }
      return result;
    }),
});

const EntryForm = ({
  toggle,
  className,
  isOpen,
  onSubmit,
  initialValues,
  isViewing,
  category,
}) => {
  const userId = useSelector(({ authReducer }) => authReducer.user.id);
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal centered isOpen={isOpen} className={className}>
      {isViewing ? (
        <React.Fragment>
          <ModalHeader toggle={toggle} close={closeBtn}>
            {initialValues.title} - {initialValues.product_code}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="card">
                <img
                  className="card-img-top"
                  src={
                    initialValues.photo
                      ? initialValues.photo
                      : "https://placehold.it/1000x400"
                  }
                  alt="CardImage"
                />
                <div className="card-body">
                  <h5 className="card-title">{initialValues.title}</h5>
                  {/* <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p> */}
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Price: {initialValues.price}
                  </li>
                  <li className="list-group-item">
                    Breed: {initialValues.breed}
                  </li>
                  <li className="list-group-item">Age: {initialValues.age}</li>
                  <li className="list-group-item">
                    Is Pregnant?: {initialValues.is_pregnant ? "Yes" : "No"}
                  </li>
                  <li className="list-group-item">
                    For Sale?: {initialValues.is_for_sale ? "Yes" : "No"}
                  </li>
                  <li className="list-group-item">
                    Quantity: {initialValues.quantity}
                  </li>
                  <li className="list-group-item">
                    Description: {initialValues.description}
                  </li>
                  <li className="list-group-item">
                    Product Type: {initialValues.product_type}
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-sm p-2"
              color="secondary"
              onClick={toggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <Formik
          initialValues={
            Object.prototype.hasOwnProperty.call(initialValues, "id")
              ? initialValues
              : { ...initialValues, user: userId }
          }
          validationSchema={entryScheme}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              onSubmit(values);
              actions.setSubmitting(false);
              toggle();
            }, 1000);
          }}
        >
          {(props) => {
            return (
              <div className="entry-form">
                <ModalHeader toggle={toggle} close={closeBtn}>
                  {Object.prototype.hasOwnProperty.call(initialValues, "id")
                    ? " Update Product"
                    : "Add Product"}
                </ModalHeader>
                <form onSubmit={props.handleSubmit}>
                  <ModalBody>
                    <div className="container">
                      <Input
                        type="select"
                        label="Product Type"
                        name="property_type"
                        value={props.values.product_type}
                      >
                        <option value="">Select </option>
                        <option key="sale" value="sale">
                          Sale
                        </option>
                        <option key="investment" value="investment">
                          Investment
                        </option>
                      </Input>
                      <Input
                        type="select"
                        label="Stock Category"
                        name="category"
                        value={props.values.category}
                      >
                        <option value="">Select Category</option>
                        {category.results.length &&
                          category.results.map((_category) => (
                            <option key={_category.id} value={_category.id}>
                              {_category.name}
                            </option>
                          ))}
                      </Input>

                      <div className="row form">
                        <div className="col-6">
                          <Input
                            type="text"
                            name="title"
                            label="Title"
                            value={props.values.title}
                            placeholder="Title"
                          />
                        </div>
                        <div className="col-6">
                          <Input
                            type="text"
                            name="breed"
                            label="Breed"
                            value={props.values.breed}
                            placeholder="Breed"
                          />
                        </div>
                      </div>
                      <div className="row form">
                        <div className="col-6">
                          <Input
                            type="number"
                            label="Age (Months)"
                            name="age"
                            value={props.values.age}
                          />
                        </div>

                        <div className="col-6">
                          <Input
                            type="number"
                            name="price"
                            label="Price (UGX)"
                            value={props.values.price}
                            placeholder="1000"
                          />
                        </div>
                      </div>
                      <div className="row form">
                        <div className="col-6">
                          <Input
                            type="checkbox"
                            name="is_pregnant"
                            label="Is Pregnant?"
                            checked={props.values.is_pregnant}
                          />
                        </div>
                        <div className="col-6">
                          <Input
                            type="checkbox"
                            name="is_for_sale"
                            checked={props.values.is_for_sale}
                            label="Is for Sale?"
                          />
                        </div>
                      </div>

                      <Input
                        type="number"
                        name="quantity"
                        label="How many animals are available?"
                        checked={props.values.quantity}
                      />

                      <div className="form-group p-0">
                        <input
                          type="file"
                          name="photo"
                          onBlur={props.handleBlur}
                          label="Photo"
                          className="pt-0"
                          onChange={(evt) => {
                            props.setFieldValue("photo", evt.target.files[0]);
                          }}
                        />
                        {props.errors.photo && (
                          <div className="input-error">
                            {props.errors.photo}
                          </div>
                        )}
                      </div>

                      <Input
                        type="textarea"
                        name="description"
                        label="Description"
                        value={props.values.description}
                        rows="7"
                        placeholder="Description"
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      type="submit"
                      className="btn btn-sm p-2"
                      disabled={props.isSubmitting}
                    >
                      Submit
                    </Button>
                    <Button
                      className="btn btn-sm p-2"
                      color="secondary"
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </form>
              </div>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default EntryForm;
