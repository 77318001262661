import { useEffect, useCallback } from "react";

function outsideClickHandle(ref, cb) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    },
    [cb, ref]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cb, handleClickOutside, ref]);

  return null;
}

export default outsideClickHandle;
