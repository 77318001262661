/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPwd } from "../../redux/actions/authActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb } from "../../components/Other/Breadcrumb";
import Input from "../../components/Control/Input";
import { Formik } from "formik";
import * as Yup from "yup";
const registerScheme = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid!")
    .required("Email is required!"),
});

const REGISTER_INITIAL = {
  email: "",
};

const messages = {
  processing: "Processing request! ...",
  ok: "Request successful, Check your email for the password reset link!",
  error: "Request failed, try again!",
};

function Register({ processing, ok, error, forgotPwd }) {
  return (
    <LayoutFour>
      <Breadcrumb title="Reset Password" />
      <div className="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              {/* <h6 className="contact-title mb-4 f-10">
                Provide information below!
              </h6> */}
              <div className="card">
                <div className="card-body mt-2">
                  <h2 className="card-title mb-3">Reset Password form</h2>
                  <p className="card-text mb-2 text-muted">
                    Provide your email address registered with us
                  </p>
                  <br />
                  <div className="contact-form">
                    {processing && (
                      <div className="alert alert-primary" role="alert">
                        {messages.processing}
                      </div>
                    )}

                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {messages.error}
                      </div>
                    )}

                    {ok && (
                      <div className="alert alert-success" role="alert">
                        {messages.ok}
                      </div>
                    )}
                    <Formik
                      initialValues={REGISTER_INITIAL}
                      validationSchema={registerScheme}
                      onSubmit={(values, actions) => {
                        // console.log(values);
                        setTimeout(() => {
                          forgotPwd(values);
                          actions.setSubmitting(false);
                        }, 1000);
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <div className="input-validator">
                            <Input
                              type="email"
                              name="email"
                              errDivClass="form-text text-muted"
                              placeholder="Email"
                            />
                          </div>

                          <button
                            className="btn -dark"
                            disabled={props.isSubmitting}
                          >
                            Reset
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-12 mt-4">
                      Remember Password ? <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </LayoutFour>
  );
}
const mapStateToProps = ({ authReducer }) => {
  const { is } = authReducer;
  return {
    ...is,
  };
};
export default connect(mapStateToProps, { forgotPwd })(Register);
