import { USER } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";
const initialState = {
  users: [],
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ok: false, error: false, processing: false },
      };
    case USER.ADD_USER:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case USER.ADD_USER_SUCCESS:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: true },
      };
    case USER.ADD_USER_FAILURE:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: false, error: true },
      };

    case USER.FIND_USER:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case USER.FIND_USER_SUCCESS:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: true },
      };
    case USER.FIND_USER_FAILURE:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: false, error: true },
      };

    case USER.UPDATE_USER:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case USER.UPDATE_USER_SUCCESS:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: true },
      };
    case USER.UPDATE_USER_FAILURE:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: false, error: true },
      };

    case USER.GET_USER:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case USER.GET_USER_SUCCESS:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: true },
        users: action.payload,
      };
    case USER.GET_USER_FAILURE:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: false, error: true },
      };

    case USER.REMOVE_USER:
      return {
        ...state,
        is: { ...state.is, processing: true },
      };

    case USER.REMOVE_USER_SUCCESS:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: true },
        // users: state.users.filter((_user) => _user.id !== action.payload),
      };
    case USER.REMOVE_USER_FAILURE:
      return {
        ...state,
        is: { ...state.is, processing: false, ok: false, error: true },
      };
    default:
      return state;
  }
}
