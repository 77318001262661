import React from "react";
import { useDispatch } from "react-redux";

import { formatCurrency } from "../../../common/utils";
import { removeFromCart } from "../../../redux/actions/cartActions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function CartItem(props) {
  const dispatch = useDispatch();
  const { image, name, price, quantity, cartId, slug } = props;
  const removeProductHandle = (e) => {
    e.preventDefault();
    dispatch(removeFromCart(cartId));
    toast.error("Product removed from card");
  };
  return (
    <div className="cart-item">
      <div className="cart-item__image">
        <img alt="" src={process.env.PUBLIC_URL + image} />
      </div>
      <div className="cart-item__info">
        <Link
          // to={process.env.PUBLIC_URL + "/shop/product/[slug]"}
          to={process.env.PUBLIC_URL + "/shop/product/" + slug}
        >
          {name}
        </Link>
        <h5>{formatCurrency(price)}</h5>
        <p>
          Quantity: <span>{quantity}</span>
        </p>
      </div>
      <Link className="cart-item__remove" onClick={removeProductHandle}>
        <i className="fal fa-times"></i>
      </Link>
    </div>
  );
}

export default CartItem;
