import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, productAPI } from "../../api";
import {
  createProductFailure,
  createProductSucess,
  updateProductSucess,
  updateProductFailure,
  findProductSucess,
  removeProductSucess,
  removeProductFailure,
  findProductFailure,
  getProductSucess,
  getProductFailure,
} from "../actions/productActions";
import { PRODUCT } from "../variables";
function* createProduct({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const productData = yield call(
      API.request,
      productAPI.createProduct(token, payload)
    );
    if (!productData) {
      throw new Error("Request failed");
    }

    yield put(createProductSucess(productData));
    if (
      user &&
      Object.prototype.hasOwnProperty.call(user, "id") &&
      (user.is_superadmin || user.is_admin)
    ) {
      yield call(getProduct, { payload: { limit: 6 } });
    } else {
      yield call(getProduct, { payload: { limit: 6, user: user.id } });
    }
  } catch (error) {
    yield put(createProductFailure());
  }
}

function* updateProduct({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const productData = yield call(
      API.request,
      productAPI.updateProduct(token, payload)
    );
    if (!productData) {
      throw new Error("Request failed");
    }

    yield put(updateProductSucess(productData));
    if (
      user &&
      Object.prototype.hasOwnProperty.call(user, "id") &&
      (user.is_superadmin || user.is_admin)
    ) {
      yield call(getProduct, { payload: { limit: 6 } });
    } else {
      yield call(getProduct, { payload: { limit: 6, user: user.id } });
    }
  } catch (error) {
    yield put(updateProductFailure());
  }
}

function* findProduct({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const productData = yield call(
      API.request,
      productAPI.findProduct(token, payload)
    );
    if (!productData) {
      throw new Error("Request failed");
    }

    yield put(findProductSucess(productData));
  } catch (error) {
    yield put(findProductFailure());
  }
}

function* getProduct({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const productData = yield call(
      API.request,
      productAPI.getProducts(token, payload)
    );
    if (!productData) {
      throw new Error("Request failed");
    }

    yield put(getProductSucess(productData));
  } catch (error) {
    yield put(getProductFailure());
  }
}

function* removeProduct({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    yield call(API.request, productAPI.removeProduct(token, payload));

    yield put(removeProductSucess(payload));
    if (
      user &&
      Object.prototype.hasOwnProperty.call(user, "id") &&
      (user.is_superadmin || user.is_admin)
    ) {
      yield call(getProduct, { payload: { limit: 6 } });
    } else {
      yield call(getProduct, { payload: { limit: 6, user: user.id } });
    }
  } catch (error) {
    yield put(removeProductFailure());
  }
}

export function* createProductRequest() {
  yield takeLatest(PRODUCT.ADD_PRODUCT, createProduct);
}

export function* updateProductRequest() {
  yield takeLatest(PRODUCT.UPDATE_PRODUCT, updateProduct);
}

export function* findProductRequest() {
  yield takeLatest(PRODUCT.FIND_PRODUCT, findProduct);
}

export function* getProductRequest() {
  yield takeLatest(PRODUCT.GET_PRODUCT, getProduct);
}

export function* removeProductRequest() {
  yield takeLatest(PRODUCT.REMOVE_PRODUCT, removeProduct);
}

export default function* rootSaga() {
  yield all([
    fork(createProductRequest),
    fork(findProductRequest),
    fork(updateProductRequest),
    fork(getProductRequest),
    fork(removeProductRequest),
  ]);
}
