import { BLOG } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  blogs: {
    count: 1,
    next: null,
    previous: null,
    results: [],
  },
  blog: {},
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: initialState.is,
      };
    case BLOG.ADD_BLOG:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case BLOG.ADD_BLOG_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };
    case BLOG.ADD_BLOG_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case BLOG.FIND_BLOG:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case BLOG.FIND_BLOG_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        blog: action.payload,
      };
    case BLOG.FIND_BLOG_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case BLOG.UPDATE_BLOG:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case BLOG.UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blog: action.payload,
        is: { ...initialState.is, ok: true },
      };
    case BLOG.UPDATE_BLOG_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case BLOG.GET_BLOG:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case BLOG.GET_BLOG_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        blogs: action.payload,
      };
    case BLOG.GET_BLOG_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    case BLOG.REMOVE_BLOG:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case BLOG.REMOVE_BLOG_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
        // blogs: state.blogs.filter(
        //   (_blog) => _blog.id !== action.payload
        // ),
      };
    case BLOG.REMOVE_BLOG_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };
    default:
      return state;
  }
}
