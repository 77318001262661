import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { API, mailAPI } from "../../api";
import { sendMailFailure, sendMailSucess } from "../actions/contactUsActions";
import { CONTACT_US } from "../variables";
function* sendMail({ payload }) {
  try {
    yield call(API.request, mailAPI.sendMail(payload));

    yield put(sendMailSucess());
  } catch (error) {
    yield put(sendMailFailure());
  }
}

export function* sendMailRequest() {
  yield takeLatest(CONTACT_US.CONTACT_US_SEND_MAIL, sendMail);
}

export default function* rootSaga() {
  yield all([fork(sendMailRequest)]);
}
