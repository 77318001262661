import React, { useState, useEffect } from "react";

import { useRouter } from "../../common/useRouter";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import ServiceItem from "../../components/Pages/Services/ServiceItem";

const serviceData = [
  {
    slug: "goat_banking",
    title: "Goat Banking",
    image: "/assets/images/services/goat-banking.png",
    text1:
      "<p>Menigte farms are set up in a smart manner that creates investors’ trust to own animals at our farms through maintaining insured different types of breeds. Any one irrespective of where they are can buy and invest in our high bred goats. We profile only 2-3 months Pregnant goats at our farms for investment.</p> <p>An investor thus maintains an account for regular updates and our experts rear the goat (s) for you and credible buyers purchase the goat products i.e. Investors will benefit from returns on investment from the sale of siblings and meat products from the goats. A convenient way to farm without engaging physically on the farm. Supervision and monitoring of the goats is guaranteed. Vist our MeGoat Bank to Invest Payments can be made via credit card, bank transfer, western union or Mobile Money. </p>",
    text2: "",
    text: {
      blocks: [
        {
          key: "114in",
          text: "(MeGoat Bank)",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
        {
          key: "c69ct",
          text:
            "Investors from across the world can own one or more of our well-bred goats looked after by our professional staff at our satellite Farms. We profile only 2-3 months pregnant insured goats at our farms for investment of USD540 (UGX 2000, 000) per goat.  This ensures faster rate of Return on Investment. It takes 12 months inclusive of the (2-3moth period) for a fetus to grow into a healthy goat with reasonable market weight that sees an average return of 30% of the investment amount per annum. An investor thus maintains an account for regular updates from MeFarms experts. Farm Visits to see the animal invested in can be scheduled from the individual account portal. Investors will benefit from returns on investment from the sale of siblings and meat products from the goats. Depending on the number of years the investor chooses, our online calculator automatically shows the ROI. A convenient way to farm without engaging physically on the farm. Supervision, regular updates and monitoring of the goats is guaranteed. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
        {
          key: "bff40",
          text:
            "An investor can thus choose to reinvest their earnings for an additional period or withdraw it into their personal account.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
    btnText: "Become an investor",
    to: "/investments",
  },
  {
    slug: "import_export",
    title: "Goat Import and Export Management",
    image: "/assets/images/services/importandexport.png",
    btnText: "Checkout Local Shop",
    to: "/shop",
    text: {
      blocks: [
        {
          key: "9e19q",
          text:
            "Mefarms Ltd has established cordial relations with key local experts in livestock industry and the Ministry of Agriculture, Animal Industry and Fisheries to improve local goat breeds in Uganda and Africa at large. Mefarms Ltd works closely with foreign experts and farmers to import top quality full blood, pure bred and disease-free animals into Uganda and export across the world. This ensures maintenance of a bio diversified goat breed bank of both Goat meat and Milk potency.  In our Import program, farmers can import with us at subsidised prices or book and wait to buy when animals have arrived at our farms. Deliveries of such to individual farms are always arranged after animals have finalised their quarantine schedules. In our Export Program, MeFarms together with our organised MeFarmer network of subscribers, we are able to export goats and goat products around the world collectively. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
  },
  {
    slug: "goat_breeding",
    title: "Goat Breeding and Trading",
    image: "/assets/images/services/goatbreed.png",
    btnText: "Subscribe with us",
    to: "/login",
    text: {
      blocks: [
        {
          key: "bq243",
          text:
            "There are various breeds of goats which are used for commercial goat farming. Mefarms Ltd biodiversity scheme includes: Kigezi Breed, Mubende Goats, Small East Africa goat, Saanen, Sudanese dwarf goat, Karamoja Goat, Sebei Goat, Red Kalahari, the Toggern Burg goat, Boers, Savannah and alpine breed.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
        {
          key: "a7li9",
          text:
            "Mefarms Ltd, ensures conservation of our local breeds, crossed breeds and pure foreign breeds locally produced all year around through mating. These are then marketed together with those of our MeFarmers on our online store.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
      ],
      entityMap: {},
    },
  },
  {
    slug: "meat_packing",
    title: "Organic Goat Meat Packaging & Sales",
    image: "/assets/images/services/meatpacking.jpg",
    btnText: "Contact us for Details",
    to: "/contact",
    text: {
      blocks: [
        {
          key: "2ll9n",
          text:
            "The market for goat meat is very huge and is ever increasing. The annual global demand of goat meat is 5 million tonnes. That’s a lot! Menigte Farms Ltd supplies goat meat to abattoirs, local butcher shops, retailers, hotels, and packages meat for exports, sells to supermarkets, restaurants, wholesalers and individual consumers.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
        {
          key: "3e3rh",
          text:
            "MeFarms Ltd ensures that goat meat is sold whilst it’s still fresh, our goats are normally kept for meat, milk, hair and fiber uses. Menigte Farms works closely with the Ugandan authorities to export the goat meat and goat products to other countries. The largest importers of goat meat include United Arab Emirates, Indonesia, United States of America, Caribbean, South Korea, Angola, Taiwan, China, India, Middle East, European Union, Malaysia, and Singapore. Our Meat is globally demanded due to its organic nature and proper handling procedures undertaken by our expert team. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
      ],
      entityMap: {},
    },
    text1:
      "The market for goat meat is very huge and is ever increasing. The annual global demand of goat meat is 5 million tonnes. That’s a lot! Menigte Farms Ltd supplies goat meat to abattoirs, local butcher shops, retailers, hotels, and packages meat for exports, sell to supermarkets, restaurants, wholesalers and individual consumers. <br /> MeFarms Ltd ensures that goat meat is sold whilst it’s still fresh, our goats are normally kept for meat, milk, hair and fiber uses. Menigte Farms works closely with the Ugandan authorities to export the goat meat to other countries. The largest importers of goat meat include United Arab Emirates, Indonesia, United States of America, Caribbean, South Korea, Angola, Taiwan, China, India, Middle East, European Union, Malaysia, and Singapore. ",
  },
  {
    slug: "knowledge_bank",
    title: "Goat Farming, Knowledge Bank Sharing & Networking Actors",
    image: "/assets/images/services/knowledgebank.png",
    btnText: "Checkout Knowledge bank",
    to: "/resources",
    text: {
      blocks: [
        {
          key: "56gg1",
          text:
            "Menigte farms Ltd has created a repository of resources on the MeFarm’s Ltd cloud-based portal to bridge the information gap around best goat farming practices for smallholder farmers and buyers through partners (both local and regional subject matter experts).",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
        {
          key: "9ccks",
          text:
            "Mefarms Ltd experts regularly update these resources, and manage an interactive discussion on our farmer’s forum. The resources can be accessed at no cost to respond to farmers’ questions on selected topics like breeding, farming, book keeping and marketing to mention but a few. We believe that a sustainable farmer led goat value chain begins with an informed farmer network. We organise MeFarmer capacity building programs, farm visits aimed at benchmarking and share key contacts of regional based agents, actors, veterinary officers for use by the farmers. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {
            "text-align": "justify",
          },
        },
      ],
      entityMap: {},
    },
  },
  {
    slug: "network",
    title: "Goat Research, Consultancy & Training",
    image: "/assets/images/services/network.png",
    btnText: "Subscribe with Us",
    to: "/register",
    text: {
      blocks: [
        {
          key: "ab48a",
          text:
            "Open access research, Mefarms ltd independent research unit contributes to research efforts, that provide a sound evidence base to support scalable and cost-effective programmes and related advocacy in agriculture and social inclusion centered on four interrelated objectives:-",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            {
              offset: 0,
              length: 20,
              style: "BOLD",
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "b1lkt",
          text:
            "Keep up to date with the existing body of evidence by conducting both primary and secondary data collection and ensure its effective use in our programmes and advocacy;",
          type: "unordered-list-item",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "3q45n",
          text:
            "Conduct high quality research to generate new evidence to address global knowledge gaps in goat farming, opportunities and operational challenges;",
          type: "unordered-list-item",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "58dkg",
          text:
            "Strengthen organizational capacity and partnerships to generate, understand, and use research evidence for decision making; and",
          type: "unordered-list-item",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "s2pr",
          text:
            "Ensure effective dissemination of research findings within and outside the organization.",
          type: "unordered-list-item",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "1i80a",
          text:
            "Zero cost consultation, Menigte farms engages subject matter experts in agriculture including experienced farmers who participate in an interactive electronic platform to respond to farmers’ questions on selected topics. Therefore, Menigte farms: Drives participatory communication to deliver agricultural services to smallholder farmers, through access to advice and knowledge and empowerment through seeking a range of services: from better livelihoods to quality health and education, from smart bio-diverse approaches to lives of less hard labor\n",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            {
              offset: 0,
              length: 23,
              style: "BOLD",
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "aljjq",
          text: "Read our research strategy here ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 27,
              length: 4,
              key: 0,
            },
          ],
          data: {},
        },
      ],
      entityMap: {
        0: {
          type: "LINK",
          mutability: "MUTABLE",
          data: {
            url: `${process.env.PUBLIC_URL}/assets/files/Mefarms_Research_Strategy_final.pdf`,
            targetOption: "_blank",
          },
        },
      },
    },
  },
];
export default function () {
  const [content, setContent] = useState({
    title: "",
    slug: "",
    image: "",
    text: "",
  });
  const router = useRouter();
  const { slug } = router.query;

  useEffect(() => {
    const content = serviceData.find((_item) => _item.slug === slug);
    setContent(content);
    return () => {
      // console.log("cleanjkfdf");
    };
  }, [slug]);

  return (
    <LayoutFour>
      <Breadcrumb title="Services">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="Services" current />
      </Breadcrumb>
      <div className={`services__item`}>
        {content && (
          <ServiceItem
            bigImgSrc={content.image}
            title={content.title}
            text={content.text}
            btnText={content.btnText}
            to={content.to}
          />
        )}
      </div>
    </LayoutFour>
  );
}
