import React, { useState, useEffect } from "react";

import Paginator from "react-hooks-paginator";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../common/useRouter";
import { getBlog } from "../../redux/actions/blogActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import BlogContent from "../../components/Blog/BlogContent";
// import EntryForm from "./EntryForm";

export default function Blog() {
  const { blogs } = useSelector(({ blogReducer }) => blogReducer);
  const router = useRouter();
  const dispatch = useDispatch();
  const search = router.query.search;
  const pageLimit = 7;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getBlog({
        limit: pageLimit,
        offset,
        search,
      })
    );
    return () => {};
  }, [dispatch, offset, search]);

  return (
    <LayoutFour title="Mefarms | Forum">
      <Breadcrumb title="Our Blog">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="Blog" current />
      </Breadcrumb>
      <div className="blog">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-12">
              {blogs.count > 0 ? (
                <BlogContent
                  offset={offset}
                  search={search}
                  data={blogs.results}
                />
              ) : (
                <div className="wishlist__empty">
                  <h3>No new content, for now!</h3>
                </div>
              )}
              <Paginator
                pageContainerClass="paginator"
                totalRecords={blogs.count}
                pageLimit={pageLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutFour>
  );
}
