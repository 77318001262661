import React from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import SocialIcons from "../../Other/SocialIcons";
import { renderContainer } from "../../../common/utils";

// eslint-disable-next-line react/prop-types
export default function TopNavOne({ container, menuStyle }) {
  const token = useSelector(({ authReducer }) => authReducer.token);
  return (
    <div className={`top-nav ${menuStyle}`}>
      <div className={renderContainer(container)}>
        <div className="top-nav__wrapper">
          <SocialIcons className="-white" />
          <p className="top-nav__wrapper__promo">
            {/* Free shipping on international orders of $120+ */}
          </p>
          <div className="top-nav__wrapper__selectors">
            {/*<Link*/}
            {/*  to={process.env.PUBLIC_URL + "/blog"}*/}
            {/*  className="top-nav__auth"*/}
            {/*>*/}
            {/*  Blog*/}
            {/*</Link>*/}
            <Link
              to={process.env.PUBLIC_URL + "/forum"}
              className="top-nav__auth"
            >
              Forum
            </Link>
            <Link
              to={process.env.PUBLIC_URL + "/projects"}
              className="top-nav__auth"
            >
              Projects
            </Link>
            <Link
              to={process.env.PUBLIC_URL + "/resources"}
              className="top-nav__auth"
            >
              Resources
            </Link>

            <Link
              to={process.env.PUBLIC_URL + token === "" ? "/login" : "/admin"}
              className="top-nav__auth"
            >
              {token === "" ? "Login/Subscribe" : "Dashboard"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
