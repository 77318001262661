import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, authAPI, userAPI } from "../../api";
import { push } from "connected-react-router";
import {
  loginUserSucess,
  loginUserFailure,
  resetPwdSucess,
  resetPwdFailure,
  forgotPwdSucess,
  forgotPwdFailure,
  logoutSucess,
  logoutFailure,
  registerPublicUserSucess,
  registerPublicUserFailure,
  findAssocFarmerSucess,
  findAssocFarmerFailure,
} from "../actions/authActions";
import { AUTH } from "../variables";
import { getStatistic } from "./general";

function* loginUser({ payload, path }) {
  try {
    // const token = yield select(({ userData }) => userData.user.token);
    const userData = yield call(API.request, authAPI.loginUser(payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(loginUserSucess(userData));

    yield put(push(path));

    if (userData.user && userData.user.is_a_farmer) {
      yield fork(findAssocfarmer, { payload: userData.user.id });
    }

    yield fork(getStatistic);
  } catch (error) {
    yield put(loginUserFailure(error.data));
  }
}

function* findAssocfarmer({ payload }) {
  try {
    const { token } = yield select(({ authReducer }) => authReducer);
    const userData = yield call(
      API.request,
      userAPI.findAssocFarmer(token, payload)
    );
    if (!userData) {
      throw new Error("Request failed");
    }

    if (userData instanceof Array)
      yield put(findAssocFarmerSucess(userData[0]));
    else yield put(findAssocFarmerSucess(userData));
  } catch (error) {
    yield put(findAssocFarmerFailure(error.data));
  }
}

function* registerPublicUser({ payload, path }) {
  try {
    // const token = yield select(({ userData }) => userData.user.token);
    const userData = yield call(
      API.request,
      authAPI.registerPublicUser(payload)
    );
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(registerPublicUserSucess(userData));

    yield call(loginUser, {
      payload: { email: payload.email, password: payload.password },
      path,
    });

    // yield put(push(path));
  } catch (error) {
    yield put(registerPublicUserFailure(error.data));
  }
}

function* resetPwd({ payload }) {
  try {
    // const token = yield select(({ userData }) => userData.user.token);
    const userData = yield call(API.request, authAPI.authResetPwd(payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(resetPwdSucess(userData));

    yield put(push("/login"));
  } catch (error) {
    yield put(resetPwdFailure(error.data));
  }
}

function* forgotPwd({ payload }) {
  try {
    // const token = yield select(({ userData }) => userData.user.token);
    const userData = yield call(API.request, authAPI.authforgotPwd(payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(forgotPwdSucess(userData));
  } catch (error) {
    yield put(forgotPwdFailure(error.data));
  }
}

function* logoutUser() {
  try {
    yield put(push("/"));
    yield put(logoutSucess());
  } catch (error) {
    yield put(logoutFailure());
  }
}

export function* loginUserRequest() {
  yield takeLatest(AUTH.LOGIN_USER, loginUser);
}

export function* registerPublicRequest() {
  yield takeLatest(AUTH.REGISTER_PUBLIC_USER, registerPublicUser);
}

export function* forgotPwdRequest() {
  yield takeLatest(AUTH.FORGOT_PASSWORD, forgotPwd);
}

export function* resetPwdRequest() {
  yield takeLatest(AUTH.RESET_PASSWORD, resetPwd);
}

export function* logoutRequest() {
  yield takeLatest(AUTH.LOGOUT_USER, logoutUser);
}

export function* findAssocfarmerRequest() {
  yield takeLatest(AUTH.FIND_ASSOC_FARMER, findAssocfarmer);
}

export default function* rootSaga() {
  yield all([
    fork(loginUserRequest),
    fork(forgotPwdRequest),
    fork(resetPwdRequest),
    fork(logoutRequest),
    fork(registerPublicRequest),
    fork(findAssocfarmerRequest),
  ]);
}
