import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, investorAPI } from "../../api";
import {
  createInvestorFailure,
  createInvestorSucess,
  updateInvestorSucess,
  updateInvestorFailure,
  findInvestorSucess,
  removeInvestorSucess,
  removeInvestorFailure,
  findInvestorFailure,
  getInvestorSucess,
  getInvestorFailure,
} from "../actions/investorActions";
import { INVESTOR } from "../variables";
function* createInvestor({ payload }) {
  try {
    // const token = yield select(({ authReducer }) => authReducer.token);
    console.log("payload: ", payload);
    const investorData = yield call(
      API.request,
      investorAPI.createInvestor(payload)
    );
    if (!investorData) {
      throw new Error("Request failed");
    }

    yield put(createInvestorSucess(investorData));
  } catch (error) {
    yield put(createInvestorFailure());
  }
}

function* updateInvestor({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const investorData = yield call(
      API.request,
      investorAPI.updateInvestor(token, payload)
    );
    if (!investorData) {
      throw new Error("Request failed");
    }

    yield put(updateInvestorSucess(investorData));
  } catch (error) {
    yield put(updateInvestorFailure());
  }
}

function* findInvestor({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const investorData = yield call(
      API.request,
      investorAPI.findInvestor(token, payload)
    );
    if (!investorData) {
      throw new Error("Request failed");
    }

    yield put(findInvestorSucess(investorData));
  } catch (error) {
    yield put(findInvestorFailure());
  }
}

function* getInvestor({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const investorData = yield call(
      API.request,
      investorAPI.getInvestors(token, payload)
    );
    if (!investorData) {
      throw new Error("Request failed");
    }

    yield put(getInvestorSucess(investorData));
  } catch (error) {
    yield put(getInvestorFailure());
  }
}

function* removeInvestor({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    yield call(API.request, investorAPI.removeInvestor(token, payload));

    yield call(getInvestor, { payload: { limit: 10 } });
    yield put(removeInvestorSucess(payload));
  } catch (error) {
    yield put(removeInvestorFailure());
  }
}

export function* createInvestorRequest() {
  yield takeLatest(INVESTOR.ADD_INVESTOR, createInvestor);
}

export function* updateInvestorRequest() {
  yield takeLatest(INVESTOR.UPDATE_INVESTOR, updateInvestor);
}

export function* findInvestorRequest() {
  yield takeLatest(INVESTOR.FIND_INVESTOR, findInvestor);
}

export function* getInvestorRequest() {
  yield takeLatest(INVESTOR.GET_INVESTOR, getInvestor);
}

export function* removeInvestorRequest() {
  yield takeLatest(INVESTOR.REMOVE_INVESTOR, removeInvestor);
}

export default function* rootSaga() {
  yield all([
    fork(createInvestorRequest),
    fork(findInvestorRequest),
    fork(updateInvestorRequest),
    fork(getInvestorRequest),
    fork(removeInvestorRequest),
  ]);
}
