/* eslint-disable react/prop-types */
import React from "react";
// import SocialIcons from "../../../Other/SocialIcons";

export default function TeamCard({ data }) {
  return (
    <div className="container">
      <div className="row team-card ">
        <div className="col-12 col-sm-12 col-md-4">
          <div className="team-card__avatar">
            <img
              src={require(`../../../../assets/images/team/teamOne/${data.image}`)}
              alt={data.name}
            />
          </div>
          <div className="team-card__content">
            <h3>{data.name}</h3>
            <h5>{data.position}</h5>

            {/* <SocialIcons /> */}
          </div>
        </div>
        <div
          className="col-12 col-sm-12 col-md-8"
          style={{ lineHeight: "2em" }}
        >
          <h3>Who is {data.name}?</h3>
          <div dangerouslySetInnerHTML={{ __html: data.quote }}></div>
        </div>
      </div>
    </div>
  );
}
