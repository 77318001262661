import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, userAPI } from "../../api";
import {
  createUserFailure,
  createUserSucess,
  updateUserSucess,
  updateUserFailure,
  findUserSucess,
  removeUserSucess,
  removeUserFailure,
  findUserFailure,
  getUserSucess,
  getUserFailure,
} from "../actions/userActions";

import { USER } from "../variables";
import { getFarmer } from "../actions/farmerActions";
function* createUser({ payload }) {
  try {
    // const token = yield select(({ authReducer }) => authReducer.token);
    const userData = yield call(API.request, userAPI.createUser(payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(createUserSucess(userData));
  } catch (error) {
    yield put(createUserFailure());
  }
}

function* updateUser({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const userData = yield call(
      API.request,
      userAPI.updateUser(token, payload)
    );
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(updateUserSucess(userData));
    yield put(getFarmer({ limit: 10 }));
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* findUser({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const userData = yield call(API.request, userAPI.findUser(token, payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(findUserSucess(userData));
  } catch (error) {
    yield put(findUserFailure());
  }
}

function* getUser({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const userData = yield call(API.request, userAPI.getUsers(token, payload));
    if (!userData) {
      throw new Error("Request failed");
    }

    yield put(getUserSucess(userData));
  } catch (error) {
    yield put(getUserFailure());
  }
}

function* removeUser({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    yield call(API.request, userAPI.removeUser(token, payload));

    yield put(removeUserSucess(payload));
  } catch (error) {
    yield put(removeUserFailure());
  }
}

export function* createUserRequest() {
  yield takeLatest(USER.ADD_USER, createUser);
}

export function* updateUserRequest() {
  yield takeLatest(USER.UPDATE_USER, updateUser);
}

export function* findUserRequest() {
  yield takeLatest(USER.FIND_USER, findUser);
}

export function* getUserRequest() {
  yield takeLatest(USER.GET_USER, getUser);
}

export function* removeUserRequest() {
  yield takeLatest(USER.REMOVE_USER, removeUser);
}

export default function* rootSaga() {
  yield all([
    fork(createUserRequest),
    fork(findUserRequest),
    fork(updateUserRequest),
    fork(getUserRequest),
    fork(removeUserRequest),
  ]);
}
