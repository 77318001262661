/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react";
import Parallax from "parallax-js";
import draftToHtml from "draftjs-to-html";
import Button from "../../Control/Button";

export default function ServiceItem(props) {
  const { bigImgSrc, title, text, btnText, to } = props;
  const bg1 = useRef(null);

  useEffect(() => {
    let parallax1 = new Parallax(bg1.current);

    return () => {
      parallax1.disable();
    };
  }, []);

  return (
    <div className={`services__item`}>
      <div className="container">
        <div className="row">
          <div className={`col-12 col-md-4 d-none d-md-block`}>
            <div className="services__item__image">
              <div className="services__item__image__detail">
                <div className="image__item mt-5">
                  <div ref={bg1} className="wrapper">
                    <img data-depth="0.3" src={bigImgSrc} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-8`}>
            <div className="services__item__content">
              <h2 className="services__item__title">{title}</h2>
              <p
                className="services__item__description"
                dangerouslySetInnerHTML={{ __html: draftToHtml(text) }}
              ></p>
            </div>
            {btnText && (
              <div className="col-12 col-sm-12 col-md-5  pl-0">
                <Button
                  action={to}
                  content={btnText}
                  color="dark"
                  style={{ textTransform: "capitalize" }}
                />
              </div>
            )}
          </div>
        </div>

        {/* {btnText && (
          <div
            className="row justify-content-end"
            // style={{ marginLeft: "-6em" }}
          >
            <div className="col-12 col-sm-12 col-md-3 ">
              <Button
                action={to}
                content={btnText}
                color="dark"
                style={{ textTransform: "capitalize" }}
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
