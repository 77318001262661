import { INVESTOR } from "../variables";

export const createInvestor = (data) => ({
  type: INVESTOR.ADD_INVESTOR,
  payload: data,
});

export const createInvestorSucess = (data) => ({
  type: INVESTOR.ADD_INVESTOR_SUCCESS,
  payload: data,
});

export const createInvestorFailure = (data) => ({
  type: INVESTOR.ADD_INVESTOR_FAILURE,
  payload: data,
});

export const updateInvestor = (data) => ({
  type: INVESTOR.UPDATE_INVESTOR,
  payload: data,
});

export const updateInvestorSucess = (data) => ({
  type: INVESTOR.UPDATE_INVESTOR_SUCCESS,
  payload: data,
});

export const updateInvestorFailure = (data) => ({
  type: INVESTOR.UPDATE_INVESTOR_FAILURE,
  payload: data,
});

export const removeInvestor = (data) => ({
  type: INVESTOR.REMOVE_INVESTOR,
  payload: data,
});

export const removeInvestorSucess = (data) => ({
  type: INVESTOR.REMOVE_INVESTOR_SUCCESS,
  payload: data,
});

export const removeInvestorFailure = (data) => ({
  type: INVESTOR.REMOVE_INVESTOR_FAILURE,
  payload: data,
});

export const findInvestor = (data) => ({
  type: INVESTOR.FIND_INVESTOR,
  payload: data,
});

export const findInvestorSucess = (data) => ({
  type: INVESTOR.FIND_INVESTOR_SUCCESS,
  payload: data,
});

export const findInvestorFailure = (data) => ({
  type: INVESTOR.FIND_INVESTOR_FAILURE,
  payload: data,
});

export const getInvestor = (data) => ({
  type: INVESTOR.GET_INVESTOR,
  payload: data,
});

export const getInvestorSucess = (data) => ({
  type: INVESTOR.GET_INVESTOR_SUCCESS,
  payload: data,
});

export const getInvestorFailure = (data) => ({
  type: INVESTOR.GET_INVESTOR_FAILURE,
  payload: data,
});
