/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import Paginator from "react-hooks-paginator";
import { useMachine } from "@xstate/react";
import { fetchInvestmentStockMachine } from "../../../../machines/fetchInvestmentStockMachine";
import { formatCurrency } from "../../../../common/utils";
import SectionTitleOne from "../../../../components/Sections/SectionTitle/SectionTitle";
import { investmentCalculatorMachine } from "../../../../machines/investmentCalculatorMachine";

const InvestmentDetailModel = ({
  token,
  userId,
  product,
  closeCalculator,
  isOpen,
}) => {
  const [state, send] = useMachine(investmentCalculatorMachine, {
    devTools: true,
  });

  useEffect(() => {
    send({ type: "SET_GOAT_COST", value: product && product.price });
  }, [product, send]);

  useEffect(() => {
    if (state.matches("success")) {
      closeCalculator();
    }
  }, [state, closeCalculator]);

  const submitInvestmentDetails = () => {
    send({
      type: "SUBMIT",
      data: {
        token: token,
        payload: {
          investor_id: userId,
          product_id: product.id,
          amount_invested: state.context.totalInvestment,
          roi: state.context.totalReturn,
          rate_of_return: state.context.roi,
          no_of_years: state.context.investmentYears,
          no_assets: state.context.count,
        },
      },
    });
  };
  return (
    <Modal isOpen={isOpen} className={"modal-lg"}>
      <ModalHeader toggle={closeCalculator}>
        Configure Investment Detail for {product && product.product_code}
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row mt-5">
            <div className="col">
              <ol className="list-group list-group-numbered">
                <li className="list-group-item  d-flex flex-column justify-content-start align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">
                      How many goats would you like to start with?
                    </h4>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max={(product && product.quantity) || 1}
                    step="1"
                    onChange={(evt) =>
                      send({
                        type: "SET_GOAT_COUNT",
                        value: evt.target.value,
                      })
                    }
                    value={state.context.count}
                    className="form-control form-control-lg form-range"
                    id="goatCount"
                  />
                </li>
                <li className="list-group-item d-flex flex-column justify-content-start align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">
                      For how many years will you invest?
                    </h4>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    onChange={(evt) =>
                      send({
                        type: "SET_INVESTMENT_YEARS",
                        value: evt.target.value,
                      })
                    }
                    value={state.context.investmentYears}
                    className="form-control form-control-lg form-range"
                    id="investmentYears"
                  />
                </li>
                <li className="list-group-item d-flex flex-row justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <h4 className="fw-bold">Rate of Return</h4>
                  </div>

                  <span className="badge bg-primary rounded-pill">
                    {state.context.roi}%
                  </span>
                </li>
              </ol>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">RETURNS (What I get back)</h4>
                </div>
                <ol className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">No. of assets</div>
                    <span>{state.context.count}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Years</div>
                    <span>{state.context.investmentYears}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Investment:</div>
                    <span>{formatCurrency(state.context.totalInvestment)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="fw-bold">Total Return:</div>
                    <span>{formatCurrency(state.context.totalReturn)}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className={"row"}>
            {/*    table for assets breakdown per year */}
            <div className="col mt-5">
              <table className="table table-striped table-hover table-bordered">
                <caption>Investment breakdown per year</caption>
                <thead>
                  <tr>
                    <th>Years</th>
                    <th>Total No. Assets (Goats)</th>
                    <th>ROI</th>
                    <th>Profit</th>
                    <th>Investment Value</th>
                  </tr>
                </thead>

                <tbody id="tblBody">
                  {state.context.yearReturns.map((item) => (
                    <tr key={item.year}>
                      <td>{item.year}</td>
                      <td>{item.totalAssets}</td>
                      <td>{item.roi}%</td>
                      <td>{formatCurrency(item.profit)}</td>
                      <td>{formatCurrency(item.investmentValue)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} onClick={closeCalculator}>
          Cancel
        </Button>
        <Button
          size={"sm"}
          disabled={
            state.matches("submitting") ||
            state.context.count === 0 ||
            state.context.investmentYears === 0
          }
          color="primary"
          onClick={submitInvestmentDetails}
        >
          Invest
        </Button>
      </ModalFooter>
    </Modal>
  );
};
// eslint-disable-next-line react/prop-types
const InvestmentStocks = ({ token, userId }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedProduct, setProductInfo] = useState(undefined);

  const [current, send] = useMachine(fetchInvestmentStockMachine, {
    devTools: true,
  });

  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [data, setData] = useState([]);
  console.log("Offset: ", offset);
  useEffect(() => {
    send({
      type: "FETCH",
      data: {
        token: token,
        payload: { limit: pageLimit, offset, product_type: "investment" },
      },
    });
  }, [send, token, offset]);

  const showCalculator = (product) => {
    setProductInfo(product);
    setOpen(true);
  };

  const closeCalculator = useCallback(() => {
    setProductInfo(undefined);
    setOpen(false);
    send({
      type: "FETCH",
      data: {
        token: token,
        payload: { limit: pageLimit, offset, product_type: "investment" },
      },
    });
  }, [send, token, offset]);

  const { response } = current.context;

  //TODO
  // select only one item for investment and prompt select of the duration.
  // show investment preview and expectations
  // create a page for updates on the investment
  // show amount accumulated and expected end period
  // show when profits should be collected

  return (
    <LoadingOverlay
      active={current.matches("fetching")}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <InvestmentDetailModel
        isOpen={isOpen}
        closeCalculator={closeCalculator}
        product={selectedProduct}
        token={token}
        userId={userId}
      />
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="Investment Stock"
        >
          Stocks
        </SectionTitleOne>
        <div className="col-md-4" />
      </div>
      {current.matches({ success: "unknown" }) && (
        <div className="wishlist__empty">
          <h3> Something wrong happened </h3>
        </div>
      )}
      {current.matches({ success: "withoutData" }) && (
        <div className="wishlist__empty">
          <h3> No data </h3>
        </div>
      )}
      {current.matches({ success: "withData" }) && (
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12">
            <table className="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">#Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Price (UGX)</th>
                  <th scope="col">Breed</th>
                  <th scope="col">Age (months)</th>
                  <th scope="col">Quantity</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {response &&
                  Object.prototype.hasOwnProperty.call(response, "results") &&
                  response.results.map((_product) => (
                    <tr key={_product.id}>
                      <th>
                        <img src={_product.photo} alt="" width="90" />
                      </th>
                      <th scope="row" style={{ verticalAlign: "inherit" }}>
                        {_product.product_code}
                      </th>
                      <td
                        style={{ verticalAlign: "inherit" }}
                      >{`${_product.title}`}</td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {formatCurrency(_product.price)}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.breed}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.age}
                      </td>

                      <td style={{ verticalAlign: "inherit" }}>
                        {_product.quantity}
                      </td>
                      <td style={{ verticalAlign: "inherit" }}>
                        <Button
                          disabled={_product.quantity === 0}
                          onClick={(_evt) => showCalculator(_product)}
                          className="btn-info btn-sm"
                          style={{
                            textTransform: "capitalize",
                            // height: "40px",
                            padding: "4px 12px",
                          }}
                        >
                          Invest
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Paginator
              pageContainerClass="pagination justify-content-center"
              pageItemClass="page-item"
              pageLinkClass="page-link"
              pageActiveClass="active"
              totalRecords={
                response &&
                Object.prototype.hasOwnProperty.call(response, "results") &&
                response.count
              }
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ authReducer }) => {
  const { token, oid } = authReducer;
  return {
    token,
    userId: oid,
  };
};
export default connect(mapStateToProps, {})(InvestmentStocks);
