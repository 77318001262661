import queryString from "query-string";
import { requestOptions } from "./request";

const createFarmer = (data) => {
  return {
    url: "farmers/",
    opt: requestOptions("POST", null, {}, { data: JSON.stringify(data) }),
  };
};

const updateFarmer = (token, data) => {
  return {
    url: "farmers/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getFarmers = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "farmers/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findFarmer = (token, farmerId) => {
  return {
    url: "farmers/" + farmerId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeFarmer = (token, farmerId) => {
  return {
    url: "farmers/" + farmerId + "/",
    opt: requestOptions("DELETE", token),
  };
};


export default {
  createFarmer,
  updateFarmer,
  findFarmer,
  removeFarmer,
  getFarmers,
};
