/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function Navigator({
  disableSubmenu,
  className,
  menuData = [],
}) {
  function renderMenu() {
    return menuData.map((item, index) => {
      if (item.title === "Services") {
        return (
          <li className="relative" key={index}>
            <Link to={process.env.PUBLIC_URL + item.to}>
              {item.title}
              <span className="dropable-icon">
                <i className="fas fa-angle-down"></i>
              </span>
            </Link>
            <ul className="dropdown-menu-custom wider">
              {item.subMenu.map((i, index) => (
                <li key={index}>
                  <Link to={i.to}>{i.title}</Link>
                </li>
              ))}
            </ul>
          </li>
        );
      }
      if (item.title === "Shop") {
        return (
          <li className="relative" key={index}>
            <Link to={process.env.PUBLIC_URL + item.to}>
              {item.title}
              <span className="dropable-icon">
                <i className="fas fa-angle-down"></i>
              </span>
            </Link>
            <ul className="dropdown-menu-custom">
              {item.subMenu.map((i, index) => (
                <li key={index}>
                  <Link to={i.to}>{i.title}</Link>
                </li>
              ))}
            </ul>
          </li>
        );
      }
      return (
        <li key={index}>
          {Object.prototype.hasOwnProperty.call(item, "isExternal") &&
          item.isExternal ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={item.to} target="_blank">
              {item.title}
            </a>
          ) : (
            <Link to={process.env.PUBLIC_URL + item.to}>{item.title}</Link>
          )}
        </li>
      );
    });
  }
  if (disableSubmenu) {
    return (
      <div className={`navigator -off-submenu ${classNames(className)}`}>
        <ul>
          {menuData.map((item, index) => (
            <li key={index}>
              <Link to={process.env.PUBLIC_URL + item.to}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return (
    <div className={`navigator ${classNames(className)}`}>
      <ul>{renderMenu()}</ul>
    </div>
  );
}
