/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/Control/Input";
import { createOrder } from "../../redux/actions/orderActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import { formatCurrency, formatSingleNumber } from "../../common/utils";
import { calculateTotalPrice } from "../../common/shopUtils";

const CHECKOUT_INITIAL = {
  coupon: "",
  note: "",
};

const chekoutScheme = Yup.object().shape({
  coupon: Yup.string(),
  note: Yup.string(),
});

const messages = {
  processing: "Processing request! ...",
  ok:
    "Request successful! Your order has been created, you will be contacted shortly to confirm the order.",
  error: "Request failed, If issue persists notify us!",
};

export default function CHeckout() {
  const cartState = useSelector((state) => state.cartReducer);
  const { token, user } = useSelector(({ authReducer }) => authReducer);
  const { is } = useSelector(({ ordersReducer }) => ordersReducer);
  const dispatch = useDispatch();

  const createUserOrder = useCallback(
    (_data) => {
      dispatch(createOrder(_data));
    },
    [dispatch]
  );

  return (
    <LayoutFour title="MeFarms | Checkout">
      <Breadcrumb title="Checkout">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="Shop" />
        <BreadcrumbItem name="Checkout" current />
      </Breadcrumb>
      <Formik
        initialValues={CHECKOUT_INITIAL}
        validationSchema={chekoutScheme}
        onSubmit={(values, actions) => {
          const order = {
            products: [...cartState],
            order_note: values.note,
            owner: user.id,
            coupon: values.coupon,
          };

          if (values.note === "") delete order.order_note;

          console.log(order);
          setTimeout(() => {
            createUserOrder(order);
            actions.setSubmitting(false);
          }, 1000);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="checkout">
              <div className="container">
                <div className="row">
                  <div className="col-12  col-lg-8">
                    <div className="checkout__form">
                      <div className="checkout__form__contact">
                        <div className="checkout__form__contact__title">
                          {/* <h5 className="checkout-title">Contact information</h5> */}
                          {!(
                            token &&
                            Object.prototype.hasOwnProperty.call(user, "id")
                          ) && (
                            <p>
                              Login
                              <Link to={process.env.PUBLIC_URL + "/login"}>
                                here
                              </Link>{" "}
                              to submit your Order!
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="container">
                        {is.processing && (
                          <div className="alert alert-primary" role="alert">
                            {messages.processing}
                          </div>
                        )}

                        {is.error && (
                          <div className="alert alert-danger" role="alert">
                            {messages.error}
                          </div>
                        )}

                        {is.ok && (
                          <div className="alert alert-success" role="alert">
                            {messages.ok}
                          </div>
                        )}
                      </div>
                      <div className="checkout__form__shipping">
                        <h5 className="checkout-title"> Order note</h5>
                        <div className="row">
                          <div className="col-12">
                            <Input
                              type="textarea"
                              name="note"
                              rows="10"
                              placeholder="Note about your order, e.g, special noe for delivery"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-12 ml-auto">
                        <div className="checkout__total">
                          <h5 className="checkout-title">Your order</h5>
                          {/* <div className="checkout__total__coupon">
                            <h5>Coupon Code</h5>

                            <Input
                              type="text"
                              placeholder="Your code here"
                              name="coupon"
                            />
                          </div> */}
                          <div className="checkout__total__price">
                            <h5>Product</h5>
                            <table>
                              <colgroup>
                                <col style={{ width: "70%" }} />
                                <col style={{ width: "30%" }} />
                              </colgroup>
                              <tbody>
                                {cartState.map((item) => (
                                  <tr key={item.cartId}>
                                    <td>
                                      <span>
                                        {formatSingleNumber(item.cartQuantity)}
                                      </span>{" "}
                                      x {item.name}
                                    </td>
                                    <td>
                                      {formatCurrency(
                                        item.price * item.cartQuantity
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="checkout__total__price__total-count">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Subtotal</td>
                                    <td>
                                      {calculateTotalPrice(cartState, true)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      {calculateTotalPrice(cartState, true)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <button
                            className="btn -red"
                            type="submit"
                            disabled={
                              !(
                                token &&
                                Object.prototype.hasOwnProperty.call(user, "id")
                              )
                            }
                            // onClick={handleSubmit(onSubmit)}
                          >
                            Place order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </LayoutFour>
  );
}
