import queryString from "query-string";
import { requestOptions } from "./request";

const createOrder = (token, data) => {
  return {
    url: "orders/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updateOrder = (token, data) => {
  return {
    url: "orders/" + data.id + "/",
    opt: requestOptions("PATCH", token, {}, { data: JSON.stringify(data) }),
  };
};

const getOrders = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "orders/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findOrder = (token, orderId) => {
  return {
    url: "orders/" + orderId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeOrder = (token, orderId) => {
  return {
    url: "orders/" + orderId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createOrder,
  updateOrder,
  findOrder,
  removeOrder,
  getOrders,
};
