/* eslint-disable react/display-name */
import React, { useEffect, useRef } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";

import { Input, Label, FormGroup } from "reactstrap";

const TextField = React.forwardRef(({ label, type, isRow, ...props }, ref) => {
  const [field, meta] = useField(props);

  const isStrEmpty = (str) => str === "" || str === undefined;

  const showError = meta.touched && !isStrEmpty(meta.error);

  const inputRef = useRef(null).current;

  useEffect(() => {
    if (!ref) return;
    typeof ref === "function" ? ref(inputRef) : (ref.current = inputRef);
    return () => (typeof ref === "function" ? ref(null) : (ref.current = null));
  }, [inputRef, ref]);

  return (
    <FormGroup
      row={isRow || false}
      check={type === "checkbox" || type === "radio"}
    >
      {label && type !== "checkbox" && type !== "radio" ? (
        <Label md={isRow ? 5 : 12} for={field.name}>
          {label}
        </Label>
      ) : null}
      {type === "checkbox" || type === "radio" ? (
        <Label for={field.name} check={true}>
          <Input ref={inputRef} {...field} {...props} type={type} /> {label}
        </Label>
      ) : (
        <React.Fragment>
          <Input
            ref={inputRef}
            {...field}
            {...props}
            type={type}
            invalid={showError}
            // valid={!showError && !isStrEmpty(meta.value)}
          />

          {showError ? <div className="input-error">{meta.error}</div> : null}
        </React.Fragment>
      )}
    </FormGroup>
  );
});

TextField.propTypes = {
  label: PropTypes.string,
  isRow: PropTypes.bool,
  type: PropTypes.string,
  col: PropTypes.number,
};

export default TextField;
