/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Paginator from "react-hooks-paginator";
import { useSelector, useDispatch } from "react-redux";

import { getProduct } from "../../../redux/actions/productActions";

const UserStock = ({ userId }) => {
  const productReducer = useSelector(({ productReducer }) => productReducer);
  const dispatch = useDispatch();

  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getProduct({ limit: pageLimit, offset, user: userId }));

    return () => {};
  }, [dispatch, offset, userId]);

  return (
    <div className="col-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">#Code</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Breed</th>
            <th scope="col">Age</th>
            <th scope="col">Pregnant</th>
            <th scope="col">Is for Sale</th>
          </tr>
        </thead>
        <tbody>
          {productReducer.products &&
            productReducer.products.results.map((_product) => (
              <tr key={_product.id}>
                <th>
                  <img src={_product.photo} alt="ads" width="90" />
                </th>
                <th scope="row" style={{ verticalAlign: "inherit" }}>
                  {_product.product_code}
                </th>
                <td
                  style={{ verticalAlign: "inherit" }}
                >{`${_product.title}`}</td>
                <td style={{ verticalAlign: "inherit" }}>
                  UGX {_product.price}
                </td>
                <td style={{ verticalAlign: "inherit" }}>{_product.breed}</td>
                <td style={{ verticalAlign: "inherit" }}>
                  {_product.age} months
                </td>
                <td style={{ verticalAlign: "inherit" }}>
                  {_product.is_pregnant ? "Yes" : "No"}
                </td>
                <td style={{ verticalAlign: "inherit" }}>
                  {_product.is_for_sale ? "Yes" : "no"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        pageContainerClass="pagination justify-content-center"
        pageItemClass="page-item"
        pageLinkClass="page-link"
        pageActiveClass="active"
        totalRecords={productReducer.products.count}
        pageLimit={pageLimit}
        pageNeighbours={2}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default UserStock;
