/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoFrame from "../../Other/VideoFrame";
import SectionTitleOne from "../SectionTitle/SectionTitle";

export default function IntroductionTwo({ style, data }) {
  const [currentChoose, setCurrentChoose] = useState(data[0].name);
  const [currentVideoImg, setCurrentVideoImg] = useState(data[0].videoPoster);
  const [currentVideoSrc, setCurrentVideoSrc] = useState(data[0].videoSrc);

  return (
    <div className="introduction-two" style={style}>
      <div className="container">
        <SectionTitleOne>Services</SectionTitleOne>
      </div>
      <div className="d-none d-md-block">
        <VideoFrame
          poster={currentVideoImg}
          height={500}
          src={currentVideoSrc}
        />
      </div>
      <div className="introduction-two__content pt-4 ">
        <div className="container ">
          {data.map((item, index) => (
            <div
              onMouseOver={() => {
                setCurrentChoose(item.name);
                setCurrentVideoImg(item.videoPoster);
                setCurrentVideoSrc(item.videoSrc);
              }}
              key={index}
              className={`introduction-two__content__item ${
                currentChoose === item.name ? "active" : ""
              }`}
            >
              {!item.description && <span>0{index + 1}.</span>}

              <Link
                to={process.env.PUBLIC_URL + item.to}
                // onClick={(e) => e.preventDefault()}
              >
                {item.name}
              </Link>
              <p>{item.description && item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
