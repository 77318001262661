import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, topicAPI } from "../../api";
import {
  createTopicFailure,
  createTopicSucess,
  updateTopicSucess,
  updateTopicFailure,
  findTopicSucess,
  removeTopicSucess,
  removeTopicFailure,
  findTopicFailure,
  getTopicSucess,
  getTopicFailure,
} from "../actions/topicActions";
import { TOPICS } from "../variables";
import { push } from "connected-react-router";
function* createTopic({ payload }) {
  try {
    const { token } = yield select(({ authReducer }) => authReducer);
    const topicData = yield call(
      API.request,
      topicAPI.createTopic(token, payload)
    );
    if (!topicData) {
      throw new Error("Request failed");
    }

    yield put(createTopicSucess(topicData));
    yield put(push("/forum?sort=newest"));
    // yield call(getTopic, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(createTopicFailure());
  }
}

function* updateTopic({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const topicData = yield call(
      API.request,
      topicAPI.updateTopic(token, payload)
    );
    if (!topicData) {
      throw new Error("Request failed");
    }

    yield put(updateTopicSucess(topicData));
    yield call(getTopic, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(updateTopicFailure());
  }
}

function* findTopic({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const topicData = yield call(
      API.request,
      topicAPI.findTopic(token, payload)
    );
    if (!topicData) {
      throw new Error("Request failed");
    }

    yield put(findTopicSucess(topicData));
  } catch (error) {
    yield put(findTopicFailure());
  }
}

function* getTopic({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const topicData = yield call(
      API.request,
      topicAPI.getTopics(token, payload)
    );
    if (!topicData) {
      throw new Error("Request failed");
    }

    yield put(getTopicSucess(topicData));
  } catch (error) {
    yield put(getTopicFailure());
  }
}

function* removeTopic({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    yield call(API.request, topicAPI.removeTopic(token, payload));

    yield put(removeTopicSucess(payload));
    yield call(getTopic, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(removeTopicFailure());
  }
}

export function* createTopicRequest() {
  yield takeLatest(TOPICS.ADD_TOPICS, createTopic);
}

export function* updateTopicRequest() {
  yield takeLatest(TOPICS.UPDATE_TOPICS, updateTopic);
}

export function* findTopicRequest() {
  yield takeLatest(TOPICS.FIND_TOPICS, findTopic);
}

export function* getTopicRequest() {
  yield takeLatest(TOPICS.GET_TOPICS, getTopic);
}

export function* removeTopicRequest() {
  yield takeLatest(TOPICS.REMOVE_TOPICS, removeTopic);
}

export default function* rootSaga() {
  yield all([
    fork(createTopicRequest),
    fork(findTopicRequest),
    fork(updateTopicRequest),
    fork(getTopicRequest),
    fork(removeTopicRequest),
  ]);
}
