import React from "react";
import MenuThree from "./Menu/MenuThree";

export default function HeaderFour(props) {
  return (
    <>
      <MenuThree {...props} />
    </>
  );
}
