/* eslint-disable react/prop-types */
import React from "react";
import Slider from "react-slick";

import Product from "../../../Product";

export default function ProductSlider({ data, sliderSettings }) {
  return (
    <div className="product-slider">
      <Slider {...sliderSettings}>
        {data.map((p, index) => (
          <div key={index} className="product-slide__item">
            <Product
              data={p}
              className={data.length < 2 ? "col-6 offset-3" : ""}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
