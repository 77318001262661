/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
export const PrevArrow = ({ currentSlide, slideCount, ...arrowProps }) => (
  <div {...arrowProps}>
    <i className="far fa-angle-left"></i>
  </div>
);

export const NextArrow = ({ currentSlide, slideCount, ...arrowProps }) => (
  <div {...arrowProps}>
    <i className="far fa-angle-right"></i>
  </div>
);
