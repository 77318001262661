/* eslint-disable react/no-unescaped-entities */
import React from "react";
import draftToHtml from "draftjs-to-html";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import introductionOneData from "../../data/introduction/introductionOne.json";
import SectionTitleOne from "../../components/Sections/SectionTitle/SectionTitle";
import TeamOne from "../../components/Sections/Team/TeamOne";
import teamOneData from "../../data/team/teamOne.json";

const aboutContent = {
  blocks: [
    {
      key: "117vr",
      text:
        "Menigte Farms Ltd is a registered and licensed livestock farming company based in Uganda, East Africa- Africa. It started its operations in August 2018 and was formally registered on 26th, May 2020. We present an inclusive, digitally-enabled agricultural transformation model to improve goat farmers’ livelihood through easy access and sharing of practical information on goat farming, making it easy and affordable to access improved breeds locally through managing import and export logistics, enabling marginalised groups who do not own land and any other able persons to invest and own goats at our satellite farms in strategic regional areas taken care of by our professionals for a profit to the investor.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "fr12v",
      text:
        "We maintain updated information and theme based resources about goat farming. Goat farmers freely subscribe and enjoy economies of collective global market options for their animals, maintain individual accounts for managing their profiles and farms. We also maintain an interactive forum of skilled farmers answering questions of beginner goat farmers based on practical knowledge and aiding them to make informed decisions. This consequently drives engagement in agriculture for women and youth, increasing food security, improving house hold incomes and creating employment opportunities along the value chain.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {
        "text-align": "justify",
      },
    },
    {
      key: "5pcqt",
      text:
        "Mefarms Ltd aims to emphasize the importance of research evidence to achieving its organizational goals. Gathering and disseminating sound research evidence is the best way of ensuring our programmes are demonstrating scalable and cost-effective approaches that are meeting smallholder farmers’ needs. Research evidence is also a strong tool for advocating with governments and policy makers for improved policies and environments that allow farmers equal opportunities for participation within society but also contributing to the global data of resources particularly in the goat farming value chain.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {
        "text-align": "justify",
      },
    },
  ],
  entityMap: {},
};
export default function About() {
  return (
    <LayoutFour title="Mefarms | About us">
      <Breadcrumb title="About us">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="About us" current />
      </Breadcrumb>
      {/* <IntroductionOne data={introductionOneData} /> */}
      <div className="introduction-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="introduction-one__content">
                <SectionTitleOne showSubTitle align="center">
                  {introductionOneData && introductionOneData.title.main}
                  <br />
                  {introductionOneData && introductionOneData.title.span}
                </SectionTitleOne>
                <p
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(aboutContent),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-12 col-md-4">
              <div className="introduction-one__content">
                <h5>
                  Our <span>Mission</span>
                </h5>
                <p>To be the leading AgriTeach Service Provider in the world</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="introduction-one__content">
                <h5>
                  Our <span>Vision</span>
                </h5>
                <p>
                  To provide good quality products and services at affordable
                  prices through the application of technological principles in
                  agriculture to ensure it's modernisation and advancement
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="introduction-one__content">
                <h5>
                  Core<span>Values</span>
                </h5>
                <p>
                  Honesty, Innovation, Proffessionalism, Accountability, Trust
                  and respect
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeamOne data={teamOneData} />
    </LayoutFour>
  );
}
