import { CONTACT_US } from "../variables";

export const sendMail = (payload) => ({
  type: CONTACT_US.CONTACT_US_SEND_MAIL,
  payload,
});

export const sendMailSucess = () => ({
  type: CONTACT_US.CONTACT_US_SEND_MAIL_SUCCESS,
});

export const sendMailFailure = () => ({
  type: CONTACT_US.CONTACT_US_SEND_MAIL_FAILURE,
});
