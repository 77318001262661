/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import Paginator from "react-hooks-paginator";
import { getFarmer, removeFarmer } from "../../../redux/actions/farmerActions";
import { generateCsv } from "../../../redux/actions/generalActions";
import { updateUser } from "../../../redux/actions/userActions";
import { fetchStatistics } from "../../../redux/actions/generalActions";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";
import { createTokenHash } from "../../../utils/generateHash";
// eslint-disable-next-line react/prop-types
const FarmerPages = ({
  farmers,
  processing,
  ok,
  error,
  getFarmer,
  updateUser,
  userReducer,
  is_admin,
  removeFarmer,
  is_a_farmer,
  generateCsv,
  stats,
  fetchStatistics,
  token,
  user,
}) => {
  // eslint-disable-next-line react/prop-types
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  // const [cipherText, setCipherText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  // const [data, setData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getFarmer({ limit: pageLimit, offset, search: searchText });
    fetchStatistics();
    return () => {};
  }, [fetchStatistics, getFarmer, offset, searchText]);

  const handleActivate = useCallback(
    (userData) => {
      updateUser(userData);
    },
    [updateUser]
  );

  const handleExport = useCallback(() => {
    generateCsv({ search: searchText });
  }, [generateCsv, searchText]);

  const handleRemoveFarmer = useCallback((userId) => removeFarmer(userId), [
    removeFarmer,
  ]);

  const gotoEgoat = useCallback(() => {
    var cipherText = createTokenHash(JSON.stringify({ token, user }));
    const EGOAT_PORTAL = "https://portal.menigtefarms.com/";
    const win = window.open(`${EGOAT_PORTAL}?acr=${cipherText}`, "_blank");
    win.focus();
  }, [token, user]);

  return (
    <LoadingOverlay
      active={processing || userReducer.is.processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="List of Registered"
        >
          Farmers
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            {is_admin && (
              <Button
                color="red"
                // as={Link}
                onClick={gotoEgoat}
                className="mt-4"
                style={{ float: "right" }}
                content={"eGoat "}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-start mb-5">
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.active_farmers || 0}</h1>
              Active
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h1 className="display-5">{stats.inactive_farmers || 0}</h1>
              Inactive
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-between"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-3 ">
          <div className="blog-sidebar__section -search">
            <form>
              <input
                type="text"
                placeholder="Enter keyword"
                onChange={(evt) => setSearchText(evt.target.value)}
                name="search"
              />
              <button disabled>
                <i className="fas fa-search"/>
              </button>
            </form>
          </div>
        </div>
        <div className="col-4">
          <button
            type="button"
            onClick={handleExport}
            style={{ textTransform: "capitalize", float: "right" }}
            className="btn btn-primary btn-md  pt-2 pb-2"
          >
            Export Data
          </button>
          {/* <Button
            color="red"
            onClick={handleExport}
            className="mt-4"
            style={{ float: "right" }}
            content={"Export Data "}
          /> */}
        </div>
      </div>
      <div
        className="row justify-content-center"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-12 col-md-12 ">
          <table className="table table-striped">
            <thead>
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">District</th>
                <th scope="col">Village</th>
                <th scope="col">Contact</th>
                {is_admin && <th scope="col">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {farmers.results.map((_farmer) => (
                <tr key={_farmer.id}>
                  {/* <th scope="row">1</th> */}
                  <td>{`${_farmer.first_name} ${_farmer.last_name}`}</td>
                  <td>{_farmer.email}</td>
                  <td>{_farmer.address}</td>
                  <td>{_farmer.town_village}</td>
                  <td>{_farmer.phone}</td>
                  {is_admin && (
                    <td>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          onClick={(_evt) =>
                            handleActivate({
                              id: _farmer.user,
                              email: _farmer.email,
                              first_name: _farmer.first_name,
                              last_name: _farmer.last_name,
                              is_active: !_farmer.is_active,
                            })
                          }
                          className="btn btn-outline-primary btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          {_farmer.is_active ? "Deactivate" : "Activate"}
                        </button>
                        |
                        <Link
                          type="button"
                          to={`/admin/profile/${_farmer.id}?view=farmer`}
                          className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          Profile
                        </Link>
                        |
                        <button
                          type="button"
                          onClick={(evt) => handleRemoveFarmer(_farmer.id)}
                          className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <Paginator
            pageContainerClass="pagination justify-content-center"
            pageItemClass="page-item"
            pageLinkClass="page-link"
            pageActiveClass="active"
            totalRecords={farmers.count}
            pageLimit={pageLimit}
            pageNeighbours={2}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};
const mapStateToProps = ({ farmerReducer, userReducer, authReducer }) => {
  const { farmers, is } = farmerReducer;
  const { stats, user, token } = authReducer;
  const { is_admin, is_a_farmer } = user;
  return {
    farmers,
    ...is,
    userReducer,
    is_admin,
    is_a_farmer,
    stats,
    token,
    user,
  };
};
export default connect(mapStateToProps, {
  getFarmer,
  updateUser,
  removeFarmer,
  generateCsv,
  fetchStatistics,
})(FarmerPages);
