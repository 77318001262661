import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../redux/actions/blogActions";
import { getProduct } from "../../redux/actions/productActions";
import menuData from "../../data/header/navigation.json";
import BlogOne from "../../components/Sections/Blog/BlogOne";
import LayoutOne from "../../components/Layout/LayoutOne";
import SliderTwo from "../../components/Sections/Slider/SliderTwo";
import sliderData from "../../data/slider/sliderOne.json";
import Introduction from "../../components/Sections/Introduction/Introduction";
import introductionOneData from "../../data/introduction/introductionOne.json";
import IntroductionTwo from "../../components/Sections/Introduction/IntroductionTwo";
import introductionTwoData from "../../data/introduction/introductionTwo.json";
import ProductSlideOne from "../../components/Sections/ProductThumb/ProductSlide/ProductSlideOne";

export default function Home() {
  const { products } = useSelector(({ productReducer }) => productReducer);
  const { blogs } = useSelector(({ blogReducer }) => blogReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProduct({ is_for_sale: "True", status: "approved" }));
    dispatch(
      getBlog({
        limit: 7,
      })
    );
    return () => {};
  }, [dispatch]);
  return (
    <LayoutOne
      title="Mefarms | Home"
      menuData={menuData}
      menuStyle="-style-1"
      hide="search"
    >
      <SliderTwo data={sliderData} className="-style-1" showDots />
      <Introduction data={introductionOneData} />
      <IntroductionTwo data={introductionTwoData} />
      {blogs.count > 0 && <BlogOne data={blogs.results.slice(0, 4)} />}
      <ProductSlideOne data={products.results} />
    </LayoutOne>
  );
}
