import queryString from "query-string";
import { requestOptions } from "./request";

const createBlog = (token, data) => {
  return {
    url: "news/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updateBlog = (token, data) => {
  return {
    url: "news/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getBlogs = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "news/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findBlog = (token, newsId) => {
  return {
    url: "news/" + newsId + "/",
    opt: requestOptions("GET", token),
  };
};

const removeBlog = (token, newsId) => {
  return {
    url: "news/" + newsId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createBlog,
  updateBlog,
  findBlog,
  removeBlog,
  getBlogs,
};
