import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, gupdateAPI } from "../../api";
import {
  createGupdateFailure,
  createGupdateSucess,
  updateGupdateSucess,
  updateGupdateFailure,
  findGupdateSucess,
  removeGupdateSucess,
  removeGupdateFailure,
  findGupdateFailure,
  getGupdateSucess,
  getGupdateFailure,
} from "../actions/gupdateActions";
import { GUPDATE } from "../variables";
function* createGupdate({ payload }) {
  try {
    // const token = yield select(({ authReducer }) => authReducer.token);
    const gupdateData = yield call(
      API.request,
      gupdateAPI.createGupdate(payload)
    );
    if (!gupdateData) {
      throw new Error("Request failed");
    }

    yield put(createGupdateSucess(gupdateData));

    yield call(getGupdate, { payload: { limit: 6 } });
  } catch (error) {
    yield put(createGupdateFailure());
  }
}

function* updateGupdate({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const gupdateData = yield call(
      API.request,
      gupdateAPI.updateGupdate(token, payload)
    );
    if (!gupdateData) {
      throw new Error("Request failed");
    }

    yield put(updateGupdateSucess(gupdateData));
    yield call(getGupdate, { payload: { limit: 6 } });
  } catch (error) {
    yield put(updateGupdateFailure());
  }
}

function* findGupdate({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const gupdateData = yield call(
      API.request,
      gupdateAPI.findGupdate(token, payload)
    );
    if (!gupdateData) {
      throw new Error("Request failed");
    }

    yield put(findGupdateSucess(gupdateData));
  } catch (error) {
    yield put(findGupdateFailure());
  }
}

function* getGupdate({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const gupdateData = yield call(
      API.request,
      gupdateAPI.getGupdates(token, payload)
    );
    if (!gupdateData) {
      throw new Error("Request failed");
    }

    yield put(getGupdateSucess(gupdateData));
  } catch (error) {
    yield put(getGupdateFailure());
  }
}

function* removeGupdate({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    yield call(API.request, gupdateAPI.removeGupdate(token, payload));
    // if (!gupdateData) {
    //   throw new Error("Request failed");
    // }

    yield put(removeGupdateSucess(payload));
    yield call(getGupdate, { payload: { limit: 6 } });
  } catch (error) {
    yield put(removeGupdateFailure());
  }
}

export function* createGupdateRequest() {
  yield takeLatest(GUPDATE.ADD_GUPDATE, createGupdate);
}

export function* updateGupdateRequest() {
  yield takeLatest(GUPDATE.UPDATE_GUPDATE, updateGupdate);
}

export function* findGupdateRequest() {
  yield takeLatest(GUPDATE.FIND_GUPDATE, findGupdate);
}

export function* getGupdateRequest() {
  yield takeLatest(GUPDATE.GET_GUPDATE, getGupdate);
}

export function* removeGupdateRequest() {
  yield takeLatest(GUPDATE.REMOVE_GUPDATE, removeGupdate);
}

export default function* rootSaga() {
  yield all([
    fork(createGupdateRequest),
    fork(findGupdateRequest),
    fork(updateGupdateRequest),
    fork(getGupdateRequest),
    fork(removeGupdateRequest),
  ]);
}
