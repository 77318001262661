/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import SearchBox from "./SearchBox";
import CartItemsSidebar from "./CartItemsSidebar";
import MobileNavSidebar from "./MobileNavSidebar";
import { formatCurrency } from "../../../common/utils";
import { Link } from "react-router-dom";

export default function MenuFunctionIcons(props) {
  const cartState = useSelector((state) => state.cartReducer);
  const { user, authFarmer } = useSelector(({ authReducer }) => authReducer);
  const hide = props.hide || "";
  const [showSearch, setShowSearch] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  function calcalateTotal(arr) {
    let total = 0;
    arr instanceof Array &&
      arr.forEach((item) => (total += item.price * item.cartQuantity));
    return total;
  }
  return (
    <>
      <div
        className={`menu__wrapper__functions ${classNames(props.className)}`}
      >
        {!hide.includes("search") && (
          <Link
            className="menu-icon -search"
            onClick={(e) => {
              e.preventDefault();
              setShowSearch(true);
            }}
            to="#"
            style={{ marginRight: hide.includes("cart") && 0 }}
          >
            <img
              src={
                props.white
                  ? process.env.PUBLIC_URL +
                    "/assets/images/header/search-icon-white.png"
                  : process.env.PUBLIC_URL +
                    "/assets/images/header/search-icon.png"
              }
              alt="Search icon"
            />
          </Link>
        )}
        {!hide.includes("cart") && (
          <>
            <Link
              to={process.env.PUBLIC_URL + "/shop/wishlist"}
              className="menu-icon -wishlist"
            >
              <img
                src={
                  props.white
                    ? process.env.PUBLIC_URL +
                      "/assets/images/header/wishlist-icon-white.png"
                    : process.env.PUBLIC_URL +
                      "/assets/images/header/wishlist-icon.png"
                }
                alt="Wishlist icon"
              />
            </Link>
            <div className="menu__cart">
              <Link
                className="menu-icon -cart"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCart(!showCart);
                }}
                to="#"
              >
                <img
                  src={
                    props.white
                      ? process.env.PUBLIC_URL +
                        "/assets/images/header/cart-icon-white.png"
                      : process.env.PUBLIC_URL +
                        "/assets/images/header/cart-icon.png"
                  }
                  alt="Cart icon"
                />
                <span className="cart__quantity">{cartState.length}</span>
              </Link>
              <h5>
                Cart: <span>{formatCurrency(calcalateTotal(cartState))}</span>
              </h5>
            </div>
            <Link
              className="menu-icon -navbar"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileNav(!showMobileNav);
              }}
              to="#"
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </Link>
          </>
        )}
        {props.isAdmin && (
          <div className="menu__cart">
            <Link
              className="menu-icon -cart"
              to={`/admin/profile/${
                user.is_a_farmer
                  ? Object.prototype.hasOwnProperty.call(authFarmer, "id")
                    ? authFarmer.id
                    : user.id
                  : user.id
              }?view=${user.is_a_farmer ? "farmer" : "user"}`}
            >
              <img
                src={require("../../../assets/images/header/user-2.png")}
                alt="Cart icon"
                style={{ backgroundColor: "white" }}
              />
              {/* <span className="cart__quantity">{cartState.length}</span> */}
            </Link>
            <h5 style={{ textTransform: "capitalize" }}>
              <span> {`${user.first_name} ${user.last_name}`} </span>
            </h5>
          </div>
        )}
      </div>
      {/* Search input */}
      <SearchBox showSearch={showSearch} setShowSearch={setShowSearch} />
      {/* Cart sidebar */}
      <CartItemsSidebar showCart={showCart} setShowCart={setShowCart} />
      {/* Mobile navigation sidebar */}
      <MobileNavSidebar
        showMobileNav={showMobileNav}
        setShowMobileNav={setShowMobileNav}
      />
    </>
  );
}
