/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Paginator from "react-hooks-paginator";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../redux/actions/productActions";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import { getProductBySort } from "../../common/productSelect";
import LayoutFour from "../../components/Layout/LayoutFour";
import productData from "../../data/products.json";
import ShopProducts from "../../components/Shop/ShopProducts";
import ShopHeader from "../../components/Shop/ShopHeader";

export default function Shop() {
  const { products, is } = useSelector(({ productReducer }) => productReducer);
  const dispatch = useDispatch();
  const pageLimit = 12;
  const [offset, setOffset] = useState(0);
  const [currentView, setCurrentView] = useState();
  const [currentSort, setCurrentSort] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // const [producttData, setProducttData] = useState(products.results);

  useEffect(() => {
    dispatch(
      getProduct({
        limit: pageLimit,
        offset,
        is_for_sale: "True",
        status: "approved",
      })
    );
    return () => {};
  }, [dispatch, offset]);

  return (
    <LayoutFour title="MeFarms | Shop">
      <Breadcrumb title="Shop">
        <BreadcrumbItem name="Home" />
        <BreadcrumbItem name="Shop" current />
      </Breadcrumb>
      <div className="shop">
        <div className="container">
          <ShopHeader
            view={currentView}
            getCurrentSort={setCurrentSort}
            getCurrentView={(view) => setCurrentView(view)}
          />
          <ShopProducts
            gridColClass="col-12 col-sm-6 col-md-4 col-lg-3"
            listColClass="col-12"
            view={currentView}
            data={products.results}
          />

          <Paginator
            pageContainerClass="paginator"
            totalRecords={products.count}
            pageLimit={pageLimit}
            pageNeighbours={2}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </LayoutFour>
  );
}
