import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import shopReducers from "./shopReducers";
import farmerReducer from "./farmerReducer";
import investorReducer from "./investorReducer";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import productReducer from "./productReducer";
import gupdateReducer from "./gupdatesReducers";
import sendMailReducer from "./contactUsReducer";

import categoryReducer from "./categoryReducer";
import ordersReducer from "./ordersReducer";
import postsReducer from "./postsReducer";
import topicsReducer from "./topicReducer";
import blogReducer from "./blogActions";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    cartReducer,
    wishlistReducer,
    shopReducers,
    farmerReducer,
    investorReducer,
    userReducer,
    productReducer,
    authReducer,
    gupdateReducer,
    sendMailReducer,
    categoryReducer,
    ordersReducer,
    postsReducer,
    topicsReducer,
    blogReducer,
  });
