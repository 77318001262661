export const product = {
  DEFAULT_QUANTITY: 1,
};

export const blog = {
  CATEGORIES: [
    "Farming",
    "Goat Raring",
    "Imports",
    "Exports",
    "Administration",
  ],
};

export const shop = {
  PRICE_POINTS: [0, 25, 50, 75, 100],
  SORT_TYPES: [
    { name: "Default", value: "default" },
    { name: "A to Z Sorting", value: "az" },
    { name: "Z to A Sorting", value: "za" },
    { name: "Low to High Price Sorting", value: "lowToHigh" },
    { name: "High to Low Price Sorting", value: "highToLow" },
  ],
  DEFAULT_VIEW: "grid",
};
