import { CONTACT_US } from "../variables";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  is: {
    processing: false,
    ok: false,
    error: false,
    errors: [],
  },
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        is: { ...initialState.is },
      };

    case CONTACT_US.CONTACT_US_SEND_MAIL:
      return {
        ...state,
        is: { ...initialState.is, processing: true },
      };

    case CONTACT_US.CONTACT_US_SEND_MAIL_SUCCESS:
      return {
        ...state,
        is: { ...initialState.is, ok: true },
      };

    case CONTACT_US.CONTACT_US_SEND_MAIL_FAILURE:
      return {
        ...state,
        is: { ...initialState.is, error: true, errors: action.payload },
      };

    default:
      return state;
  }
}
