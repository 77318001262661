import queryString from "query-string";
import { requestOptions } from "./request";

const createPost = (token, data) => {
  return {
    url: "posts/",
    opt: requestOptions("POST", token, {}, { data: data }, true),
  };
};

const updatePost = (token, data) => {
  return {
    url: "posts/" + data.get("id") + "/",
    opt: requestOptions("PATCH", token, {}, { data: data }, true),
  };
};

const getPosts = (token, payload) => {
  const filters = queryString.stringify(payload);
  return {
    url: "posts/?" + filters,
    opt: requestOptions("GET", token),
  };
};

const findPost = (token, postId) => {
  return {
    url: "posts/" + postId + "/",
    opt: requestOptions("GET", token),
  };
};

const removePost = (token, postId) => {
  return {
    url: "posts/" + postId + "/",
    opt: requestOptions("DELETE", token),
  };
};

export default {
  createPost,
  updatePost,
  findPost,
  removePost,
  getPosts,
};
