/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Paginator from "react-hooks-paginator";
import {
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
} from "../../../redux/actions/categoryActions";
import EntryForm from "./EntryForm";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";

const CATEGORY_INITIAL = {
  name: "",
};

const CategoryPage = ({
  category,
  processing,
  ok,
  error,
  getCategory,
  userReducer,
  createCategory,
  updateCategory,
  is_admin,
  removeCategory,
}) => {
  const pageLimit = 2;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectCategory, setSelectedCategory] = useState(CATEGORY_INITIAL);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) setSelectedCategory({});
  }, [isOpen]);

  useEffect(() => {
    getCategory({ limit: pageLimit, offset });
    return () => {};
  }, [getCategory, offset]);

  const handleCreateCategory = useCallback(
    (data) => {
      createCategory(data);
      setSelectedCategory({});
    },
    [createCategory]
  );

  const handleEditClick = useCallback(
    (data) => {
      setSelectedCategory(data);
      toggle();
    },
    [toggle]
  );

  const handleUpdateCategory = useCallback(
    (data) => {
      updateCategory(data);
      setSelectedCategory({});
    },
    [updateCategory]
  );

  const handleRemoveCategory = useCallback(
    (id) => {
      removeCategory(id);
    },
    [removeCategory]
  );

  return (
    <LoadingOverlay
      active={processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <EntryForm
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={
            Object.prototype.hasOwnProperty.call(selectCategory, "id")
              ? handleUpdateCategory
              : handleCreateCategory
          }
          initialValues={selectCategory}
        />
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="Stock category grouping"
        >
          Categories
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            {is_admin && (
              <Button
                color="red"
                // as={Link}
                onClick={(_evt) => toggle()}
                className="btn-md pl-3 pr-3 pt-2 pb-2 mt-4"
                content={"Create "}
              />
            )}
          </div>
        </div>
      </div>
      {category.results.length === 0 && (
        <div className="wishlist__empty">
          <h3> No categories!</h3>
        </div>
      )}

      {category.results.length !== 0 && (
        <div className="row justify-content-center">
          <div className="col-8" style={{ paddingBottom: "30px" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">Name</th>

                  {is_admin && <th scope="col">Actions</th>}
                </tr>
              </thead>
              <tbody>
                {category.results.map((_category) => (
                  <tr key={_category.id}>
                    {/* <th scope="row">1</th> */}
                    <td>{_category.name}</td>

                    {is_admin && (
                      <td>
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            onClick={(_evt) => handleEditClick(_category)}
                            className="btn btn-outline-primary btn-sm pl-2 pr-2 pt-1 pb-1"
                          >
                            Edit
                          </button>

                          <button
                            type="button"
                            // onClick={(evt) => handleRemoveFarmer(_farmer.id)}
                            onClick={(_evt) =>
                              handleRemoveCategory(_category.id)
                            }
                            className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Paginator
        pageContainerClass="pagination justify-content-center"
        pageItemClass="page-item"
        pageLinkClass="page-link"
        pageActiveClass="active"
        totalRecords={category.count}
        pageLimit={pageLimit}
        pageNeighbours={2}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ categoryReducer, userReducer, authReducer }) => {
  const { category, is } = categoryReducer;
  const { is_admin } = authReducer.user;
  return {
    category,
    ...is,
    userReducer,
    is_admin,
  };
};
export default connect(mapStateToProps, {
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
})(CategoryPage);
