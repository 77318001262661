import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import draftToHtml from "draftjs-to-html";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Paginator from "react-hooks-paginator";
import md5 from "md5";
import moment from "moment";
import { useRouter } from "../../common/useRouter";
import { findTopic } from "../../redux/actions/topicActions";
import {
  getPost,
  createPost,
  removePost,
} from "../../redux/actions/postsActions";
import LayoutFour from "../../components/Layout/LayoutFour";

const Discussion = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [comment, setComment] = useState("");
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const router = useRouter();

  const dispatch = useDispatch();
  const { topic } = useSelector(({ topicsReducer }) => topicsReducer);
  const { posts, is } = useSelector(({ postsReducer }) => postsReducer);
  const { user } = useSelector(({ authReducer }) => authReducer);

  const { id, sort } = router.query;

  useEffect(() => {
    dispatch(findTopic(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getPost({ topic: id, limit: pageLimit, offset, sort }));
    return () => {
      // console.log("clean");
    };
  }, [dispatch, id, sort, offset]);

  const createComment = useCallback(
    (evt) => {
      evt.preventDefault();
      const id = router.query.id;
      if (editorState.getCurrentContent().hasText()) {
        dispatch(
          createPost({
            message: JSON.stringify(comment),
            created_by: user.id,
            topic: id,
          })
        );
      }
    },
    [dispatch, editorState, router, user, comment]
  );

  const imgHash =
    topic.starter &&
    Object.prototype.hasOwnProperty.call(topic.starter, "id") &&
    md5(topic.starter.email);

  const getPostHash = (_post) => {
    return md5(
      _post.created_by &&
        Object.prototype.hasOwnProperty.call(_post.created_by, "id")
        ? _post.created_by.email
        : ""
    );
  };

  const removePostHandler = useCallback(
    (_post) => {
      confirmAlert({
        title: "Are you sure?",
        message: "You are want to delete this Comment?",
        buttons: [
          {
            label: "Yes",
            onClick: () => dispatch(removePost(_post.id)),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [dispatch]
  );
  return (
    <LayoutFour>
      {/* <Breadcrumb title="Forum" /> */}
      <div className="container all-columns">
        <div className="column-text open">
          <div className="header">
            <h1>
              {topic && Object.prototype.hasOwnProperty.call(topic, "id")
                ? topic.subject
                : ""}
            </h1>
          </div>
          <div className="content">
            <div className="element first">
              <div className="message">
                <div>
                  {topic &&
                  Object.prototype.hasOwnProperty.call(topic, "id") &&
                  topic.body.includes("blocks") ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: draftToHtml(JSON.parse(topic.body)),
                      }}
                    />
                  ) : topic &&
                    Object.prototype.hasOwnProperty.call(topic, "id") ? (
                    topic.body
                  ) : (
                    ""
                  )}
                </div>
                <div className="topic">
                  {/* <a className="tag" href="/view/discussions/tagged/porta">
                    porta
                  </a>{" "} */}
                </div>
                <div className="user-info">
                  <div className="when">
                    {`${moment(topic.created_at).format("MMM DD")} at ${moment(
                      topic.created_at
                    ).format("HH:mm")}`}
                  </div>
                  <div className="p-info">
                    <img
                      src={`https://www.gravatar.com/avatar/${imgHash}?s=64&d=identicon&r=PG`}
                      alt=""
                      width="32"
                      height="32"
                    />

                    <div
                      className="details pl-2"
                      style={{ textTransform: "capitalize" }}
                    >
                      {topic.starter &&
                      Object.prototype.hasOwnProperty.call(topic.starter, "id")
                        ? topic.starter.first_name
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {is.processing && <div>Loading responses...</div>}
            {!is.processing && (
              <React.Fragment>
                <div className="tab-head subtabs">
                  <div className="tabs">
                    <h2>{posts.count} Response(s)</h2>
                    <div>
                      <Link
                        aria-current="page"
                        className={sort === "newest" ? "selected active" : ""}
                        to={`/forum/${id}/discussion?sort=newest`}
                      >
                        Newest
                      </Link>
                      <Link
                        aria-current="page"
                        className={sort === "oldest" ? "selected active" : ""}
                        to={`/forum/${id}/discussion?sort=oldest`}
                      >
                        Oldest
                      </Link>
                    </div>
                  </div>
                </div>

                {posts &&
                  posts.results.map((_post) => (
                    <div className="element" key={_post.id}>
                      <div className="message">
                        {_post.message.includes("blocks") ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(JSON.parse(_post.message)),
                            }}
                          />
                        ) : (
                          _post.message
                        )}
                        <div className="topic">
                          {user &&
                          Object.prototype.hasOwnProperty.call(user, "id") &&
                          (user.is_admin ||
                            user.is_superuser ||
                            user.id === _post.created_by.id) ? (
                            <button
                              type="button"
                              className="btn btn-link btn-sm  p-0 mt-3"
                              onClick={() => removePostHandler(_post)}
                              style={{
                                textTransform: "capitalize",
                                color: "red",
                              }}
                            >
                              Delete
                            </button>
                          ) : null}
                        </div>

                        <div className="user-info">
                          <div className="when">
                            {`${moment(_post.created_at).format(
                              "MMM DD"
                            )} at ${moment(_post.created_at).format("HH:mm")}`}
                          </div>

                          <div className="p-info">
                            <img
                              src={`https://www.gravatar.com/avatar/${getPostHash(
                                _post
                              )}?s=64&d=identicon&r=PG`}
                              alt=""
                              width="32"
                              height="32"
                            />

                            <div
                              className="details pl-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              {_post.created_by &&
                              Object.prototype.hasOwnProperty.call(
                                _post.created_by,
                                "id"
                              )
                                ? _post.created_by.first_name
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <Paginator
                  pageContainerClass="pagination justify-content-center"
                  pageItemClass="page-item"
                  pageLinkClass="page-link"
                  pageActiveClass="active"
                  totalRecords={posts.count}
                  pageLimit={pageLimit}
                  pageNeighbours={2}
                  setOffset={setOffset}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </React.Fragment>
            )}
            <form className="editor" onSubmit={createComment}>
              <h2>Your view</h2>

              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "embedded",
                    "emoji",
                    "image",
                    "remove",
                    "history",
                  ],
                }}
                onContentStateChange={setComment}
              />
              {user && Object.prototype.hasOwnProperty.call(user, "id") ? (
                <button className="btn btn-primary" type="submit">
                  Post Your view
                </button>
              ) : (
                <Link
                  className="btn btn-sm btn-primary p-2 mt-2"
                  style={{ textTransform: "capitalize" }}
                  to="/login"
                >
                  Sign in first
                </Link>
              )}
            </form>
          </div>
        </div>
      </div>
    </LayoutFour>
  );
};

export default Discussion;
