import { all } from "redux-saga/effects";

import farmerSagas from "./farmers";
import investorSagas from "./inverstors";
import userSagas from "./users";
import authSagas from "./auth";
import gupdateSagas from "./gupdates";
import productSagas from "./product";
import sendMailSagas from "./contactus";

import categorySagas from "./category";
import orderSagas from "./orders";
import postsSagas from "./posts";
import topicsSagas from "./topics";
import genSagas from "./general";
import blogSagas from "./blog";

// eslint-disable-next-line no-unused-vars
export default function* rootSaga(getState) {
  yield all([
    farmerSagas(),
    investorSagas(),
    userSagas(),
    authSagas(),
    gupdateSagas(),
    productSagas(),
    sendMailSagas(),
    categorySagas(),
    orderSagas(),
    postsSagas(),
    topicsSagas(),
    genSagas(),
    blogSagas(),
  ]);
}
