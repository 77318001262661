/* eslint-disable react/prop-types */
import React from "react";
import SectionTitle from "../../SectionTitle/SectionTitle";
import Button from "../../../Control/Button";

import ProductSlider from "../Elements/ProductSlider";
import { PrevArrow, NextArrow } from "../../../Other/SliderArrow";

export default function ProductSlideOne({ data, title = "Our Shop" }) {
  const settings = {
    speed: 500,
    slidesToShow: data.length < 4 ? data.length : 4,
    slidesToScroll: 1,
    arrows: false,
    className: "product-slide__wrapper",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: data.length < 4 ? data.length : 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: data.length < 4 ? data.length : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.length < 4 ? data.length : 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="product-slide">
      <div className="container">
        <SectionTitle align="center" spaceBottom="50px">
          {title}
        </SectionTitle>
        {data.length ? (
          <React.Fragment>
            <ProductSlider data={data} sliderSettings={settings} />
            <div className="text-center">
              <Button
                action={process.env.PUBLIC_URL + "/shop"}
                color="transparent"
                className="-underline"
                content="View all product"
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="wishlist__empty">
            <h3>No New Products on Sale!</h3>
          </div>
        )}
      </div>
    </div>
  );
}
