/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Paginator from "react-hooks-paginator";
import {
  getGupdate,
  createGupdate,
  updateGupdate,
  removeGupdate,
} from "../../../redux/actions/gupdateActions";
import EntryForm from "./EntryForm";
import Button from "../../../components/Control/Button";
import SectionTitleOne from "../../../components/Sections/SectionTitle/SectionTitle";

const GUpdatesPage = ({
  gupdates,
  processing,
  ok,
  error,
  getGupdate,
  userReducer,
  createGupdate,
  updateGupdate,
  is_admin,
  removeGupdate,
}) => {
  const pageLimit = 2;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectGupdate, setSelectedGupdate] = useState({});

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) setSelectedGupdate({});
  }, [isOpen]);

  useEffect(() => {
    getGupdate({ limit: pageLimit, offset });
    return () => {};
  }, [getGupdate, offset]);

  const handleCreateGupdate = useCallback(
    (data) => {
      createGupdate(data);
      setSelectedGupdate({});
    },
    [createGupdate]
  );

  const handleEditClick = useCallback(
    (data) => {
      setSelectedGupdate(data);
      toggle();
    },
    [toggle]
  );

  const handleUpdateGupdate = useCallback(
    (data) => {
      updateGupdate(data);
      setSelectedGupdate({});
    },
    [updateGupdate]
  );

  const handleRemoveGupdate = useCallback(
    (id) => {
      removeGupdate(id);
    },
    [removeGupdate]
  );

  return (
    <LoadingOverlay
      active={processing}
      spinner
      styles={{
        wrapper: (base) => {
          return {
            ...base,
            padding: "10px",
          };
        },

        overlay: (base) => {
          return {
            ...base,
            opacity: 0.9,
          };
        },
      }}
      text="Loading..."
    >
      <div className="row  justify-content-between mt-3 mb-3">
        <EntryForm
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={
            Object.prototype.hasOwnProperty.call(selectGupdate, "id")
              ? handleUpdateGupdate
              : handleCreateGupdate
          }
          initialValues={selectGupdate}
        />
        <SectionTitleOne
          className="col-md-8"
          align="left"
          description="Company wide communications"
        >
          General
        </SectionTitleOne>
        <div className="col-md-4">
          <div className="btn-group" role="group" style={{ float: "right" }}>
            {is_admin && (
              <Button
                color="red"
                // as={Link}
                onClick={(_evt) => toggle()}
                className="btn-info btn-md pl-3 pr-3 pt-2 pb-2 mt-4 "
                content={"Create "}
              />
            )}
          </div>
        </div>
      </div>
      {gupdates.results.length === 0 && (
        <div className="wishlist__empty">
          <h3>No new updates yet!</h3>
        </div>
      )}
      {gupdates.results.map((_update) => (
        <div className="row justify-content-center" key={_update.id}>
          <div className="col-8" style={{ paddingBottom: "30px" }}>
            <div
              className="card introduction-one__content"
              //   style={{ width: "18rem" }}
            >
              <div className="card-body">
                <h5 className="card-title">{_update.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {_update.sub_title}
                </h6>
                <p className="card-text ">{_update.content}</p>
                {is_admin && (
                  <div
                    className="btn-group"
                    role="group"
                    style={{ float: "right" }}
                  >
                    <button
                      type="button"
                      onClick={(_evt) => handleEditClick(_update)}
                      className="btn btn-outline-primary btn-sm pl-2 pr-2 pt-1 pb-1"
                    >
                      Edit
                    </button>
                    |
                    <button
                      type="button"
                      // onClick={(evt) => handleRemoveFarmer(_farmer.id)}
                      onClick={(_evt) => handleRemoveGupdate(_update.id)}
                      className="btn btn-outline-secondary btn-sm pl-2 pr-2 pt-1 pb-1"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      <Paginator
        pageContainerClass="pagination justify-content-center"
        pageItemClass="page-item"
        pageLinkClass="page-link"
        pageActiveClass="active"
        totalRecords={gupdates.count}
        pageLimit={pageLimit}
        pageNeighbours={2}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ gupdateReducer, userReducer, authReducer }) => {
  const { gupdates, is } = gupdateReducer;
  const { is_admin } = authReducer.user;
  return {
    gupdates,
    ...is,
    userReducer,
    is_admin,
  };
};
export default connect(mapStateToProps, {
  getGupdate,
  createGupdate,
  updateGupdate,
  removeGupdate,
})(GUpdatesPage);
