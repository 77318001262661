import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, initializeStore, history } from "../redux/store";
import "../styles/styles.scss";
import Loading from "../components/Other/Loading";
import HomePage from "./home";
import AboutPage from "./about";
import BlogPage from "./blog";
import BLogItemPage from "./blog/post";
import ContactPage from "./contact";
import ExportPage from "./export";
import ImportPage from "./import";
import LoginPage from "./login";
import AdminPage from "./admin";
import ResourcePage from "./resources";
import ServicesPage from "./service";
import ShopPage from "./shop";
import ShopProductPage from "./shop/product";
import ShopWishListPage from "./shop/wishlist";
import ShopCartPage from "./shop/cart";
import ShopCheckoutPage from "./shop/checkout";
import ForumPage from "./forum";
import DiscussionPage from "./forum/Discussion";
import ForgotPwdPage from "./forgot-pwd";
import ResetPwdPage from "./reset-pwd";
import RegisterPage from "./register";
import ErrorPage from "./404";
import ProjectsPage from "./projects";
import NewDiscussion from "./forum/NewDiscussion";
import InvestorPage from "./investor";
const trackingId = "UA-177212680-1";

ReactGA.initialize(trackingId);

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const MainApp = () => (
  <Provider store={initializeStore({})}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route exact path="/blog" component={BlogPage} />
          <Route path="/investments" component={InvestorPage} />
          <Route path="/blog/post/:id" component={BLogItemPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/export" component={ExportPage} />
          <Route path="/import" component={ImportPage} />
          <Route exact path="/shop" component={ShopPage} />
          <Route path="/shop/product/:slug" component={ShopProductPage} />
          <Route path="/shop/wishlist" component={ShopWishListPage} />
          <Route path="/shop/checkout" component={ShopCheckoutPage} />
          <Route path="/shop/cart" component={ShopCartPage} />
          <Route path="/shop/product/:slug" component={ShopProductPage} />
          {/* <Route path="/shop" component={ShopPage} /> */}
          <Route path="/service/:slug" component={ServicesPage} />
          <Route path="/resources" component={ResourcePage} />
          <Route path="/projects" component={ProjectsPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/forgot-pwd" component={ForgotPwdPage} />
          <Route path="/set-pwd/:userID" component={ResetPwdPage} />
          <Route exact path="/forum" component={ForumPage} />
          <Route path="/forum/:id/discussion" component={DiscussionPage} />
          <Route path="/forum/discussion/new" component={NewDiscussion} />
          <Route component={ErrorPage} />
        </Switch>
      </ConnectedRouter>
      <ToastContainer position="bottom-left" autoClose={3000} />

      <ScrollToTop
        smooth
        component={<i className="fal fa-arrow-to-top" />}
        style={{
          backgroundColor: "#f7f5f4",
          borderRadius: "999px",
          height: "50px",
          width: "50px",
        }}
      />
    </PersistGate>
  </Provider>
);

export default MainApp;
