import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { API, categoryAPI } from "../../api";
import {
  createCategoryFailure,
  createCategorySucess,
  updateCategorySucess,
  updateCategoryFailure,
  findCategorySucess,
  removeCategorySucess,
  removeCategoryFailure,
  findCategoryFailure,
  getCategorySucess,
  getCategoryFailure,
} from "../actions/categoryActions";
import { CATEGORY } from "../variables";
function* createCategory({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const categoryData = yield call(
      API.request,
      categoryAPI.createCategory(token, payload)
    );
    if (!categoryData) {
      throw new Error("Request failed");
    }

    yield put(createCategorySucess(categoryData));
    yield call(getCategory, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(createCategoryFailure());
  }
}

function* updateCategory({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    const categoryData = yield call(
      API.request,
      categoryAPI.updateCategory(token, payload)
    );
    if (!categoryData) {
      throw new Error("Request failed");
    }

    yield put(updateCategorySucess(categoryData));
    yield call(getCategory, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(updateCategoryFailure());
  }
}

function* findCategory({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const categoryData = yield call(
      API.request,
      categoryAPI.findCategory(token, payload)
    );
    if (!categoryData) {
      throw new Error("Request failed");
    }

    yield put(findCategorySucess(categoryData));
  } catch (error) {
    yield put(findCategoryFailure());
  }
}

function* getCategory({ payload }) {
  try {
    const token = yield select(({ authReducer }) => authReducer.token);
    const categoryData = yield call(
      API.request,
      categoryAPI.getCategorys(token, payload)
    );
    if (!categoryData) {
      throw new Error("Request failed");
    }

    yield put(getCategorySucess(categoryData));
  } catch (error) {
    yield put(getCategoryFailure());
  }
}

function* removeCategory({ payload }) {
  try {
    const { token, user } = yield select(({ authReducer }) => authReducer);
    yield call(API.request, categoryAPI.removeCategory(token, payload));

    yield put(removeCategorySucess(payload));
    yield call(getCategory, { payload: { limit: 10, user: user.id } });
  } catch (error) {
    yield put(removeCategoryFailure());
  }
}

export function* createCategoryRequest() {
  yield takeLatest(CATEGORY.ADD_CATEGORY, createCategory);
}

export function* updateCategoryRequest() {
  yield takeLatest(CATEGORY.UPDATE_CATEGORY, updateCategory);
}

export function* findCategoryRequest() {
  yield takeLatest(CATEGORY.FIND_CATEGORY, findCategory);
}

export function* getCategoryRequest() {
  yield takeLatest(CATEGORY.GET_CATEGORY, getCategory);
}

export function* removeCategoryRequest() {
  yield takeLatest(CATEGORY.REMOVE_CATEGORY, removeCategory);
}

export default function* rootSaga() {
  yield all([
    fork(createCategoryRequest),
    fork(findCategoryRequest),
    fork(updateCategoryRequest),
    fork(getCategoryRequest),
    fork(removeCategoryRequest),
  ]);
}
