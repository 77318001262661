/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from "react";

import PostCardTwo from "../Post/PostCardTwo";

export default function BlogContent({ search, data }) {
  return (
    <div className="blog-content">
      {search && (
        <h3 className="search-result">
          Searching for <span>"{search}"</span> keyword
        </h3>
      )}

      <div className="row">
        {data.map((item, index) => {
          return (
            <div key={index} className="col-12 col-md-4">
              <PostCardTwo data={item} />
            </div>
          );
        })}
      </div>
      {/* ) : (
        <div className="wishlist__empty">
          <h3>Content not yet available</h3>
        </div>
      )} */}
    </div>
  );
}
