import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { investmentCalculatorMachine } from "../../../../machines/investmentCalculatorMachine";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { formatCurrency } from "../../../../common/utils";
import { Link, useLocation } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ProductItemDetails = ({ product }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{product && product.title}</h5>
        {/* <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p> */}
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <img
                src={
                  product && product.photo
                    ? product.photo
                    : `https://via.placeholder.com/200x140.png?text=${
                        product && product.title
                      }`
                }
                alt={product && product.title}
              />
            </li>

            <li className="list-group-item">Age: {product && product.age}</li>
            <li className="list-group-item">
              Is Pregnant?: {product && product.is_pregnant ? "Yes" : "No"}
            </li>
          </ul>
        </div>
        <div className={"col"}>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              Price: {product && product.price}
            </li>
            <li className="list-group-item">
              Breed: {product && product.breed}
            </li>
            <li className="list-group-item">
              For Sale?: {product && product.is_for_sale ? "Yes" : "No"}
            </li>
            <li className="list-group-item">
              Quantity: {product && product.quantity}
            </li>
            <li className="list-group-item">
              Description: {product && product.description}
            </li>
            <li className="list-group-item">
              Product Type: {product && product.product_type}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
// eslint-disable-next-line react/prop-types
export const InvestmentProductDetails = ({
  isOpen,
  roi,
  quantity,
  investmentYears,
  price,
  productCode,
  closeDetails,
  isAdmin,
  status,
  investmentId,
  updateInvestment,
  product,
  investorId,
}) => {
  const [state, send] = useMachine(investmentCalculatorMachine, {
    devTools: false,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    send({ type: "SET_GOAT_COST", value: price });
    send({
      type: "SET_INVESTMENT_YEARS",
      value: investmentYears,
    });
    send({
      type: "SET_GOAT_COUNT",
      value: quantity,
    });
  }, [price, investmentYears, quantity, send]);

  return (
    <Modal isOpen={isOpen} className={"modal-lg"}>
      <ModalHeader toggle={closeDetails}>
        Investment Detail for {productCode}
      </ModalHeader>
      <ModalBody>
        <div className="container">
          {!searchParams.get("view") && (
            <div className="row mt-5">
              <div className="col">
                <h4 className={"mb-3"}>Investor Info</h4>
                <p>
                  Click{" "}
                  <Link to={`/admin/profile/${investorId}?view=investor`}>
                    here
                  </Link>{" "}
                  to visit profile
                </p>
              </div>
            </div>
          )}
          <div className="row mt-5">
            <div className="col">
              <h4 className={"mb-3"}>Goat Details</h4>
              <ProductItemDetails product={product} />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className={"row"}>
                <div className="col">
                  <h4 className={"mb-3"}>Investment Details</h4>
                </div>
              </div>
              <div className={"row"}>
                <div className="col">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item  d-flex flex-column justify-content-start align-items-start">
                      <div className="ms-2 me-auto">
                        <h4 className="fw-bold">No of Goats</h4>
                      </div>
                      <input
                        type="range"
                        min="0"
                        disabled
                        step="1"
                        value={quantity}
                        className="form-control form-control-lg form-range"
                        id="goatCount"
                      />
                    </li>
                    <li className="list-group-item d-flex flex-column justify-content-start align-items-start">
                      <div className="ms-2 me-auto">
                        <h4 className="fw-bold">Investment Years</h4>
                      </div>
                      <input
                        type="range"
                        min="0"
                        step="1"
                        disabled
                        value={investmentYears}
                        className="form-control form-control-lg form-range"
                        id="investmentYears"
                      />
                    </li>
                    <li className="list-group-item d-flex flex-row justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <h4 className="fw-bold">Rate of Return</h4>
                      </div>

                      <span className="badge bg-primary rounded-pill">
                        {roi}%
                      </span>
                    </li>
                  </ol>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">RETURNS (What I get back)</h4>
                    </div>
                    <ol className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="fw-bold">Number of Goats</div>
                        <span>{state.context.count}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="fw-bold">Total Years</div>
                        <span>{state.context.investmentYears}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="fw-bold">Total Investment:</div>
                        <span>
                          {formatCurrency(state.context.totalInvestment)}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="fw-bold">Total Return:</div>
                        <span>{formatCurrency(state.context.totalReturn)}</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"row"}>
            {/*    table for assets breakdown per year */}
            <div className="col mt-5">
              <table className="table table-striped table-hover table-bordered">
                <caption>Investment breakdown per year</caption>
                <thead>
                  <tr>
                    <th>Years</th>
                    <th>Number of Goats</th>
                    <th>ROI</th>
                    <th>Profit</th>
                    <th>Investment Value</th>
                  </tr>
                </thead>

                <tbody id="tblBody">
                  {state.context.yearReturns.map((item) => (
                    <tr key={item.year}>
                      <td>{item.year}</td>
                      <td>{item.totalAssets}</td>
                      <td>{item.roi}%</td>
                      <td>{formatCurrency(item.profit)}</td>
                      <td>{formatCurrency(item.investmentValue)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} onClick={closeDetails}>
          Cancel
        </Button>
        {isAdmin && (
          <UncontrolledDropdown>
            <DropdownToggle
              // className="btn btn-outline-info btn-sm pl-2 pr-2 pt-1 pb-1"
              caret
              outline
              style={{
                color: "#FFF",
              }}
              className="btn-info btn-sm"
            >
              Approve/Reject
            </DropdownToggle>
            <DropdownMenu>
              {status !== "approved" && (
                <DropdownItem
                  onClick={(_evt) => {
                    updateInvestment({
                      id: investmentId,
                      status: "approved",
                    });
                  }}
                >
                  Approve
                </DropdownItem>
              )}

              {status !== "rejected" && (
                <React.Fragment>
                  {status !== "approved" && <DropdownItem divider />}
                  <DropdownItem
                    onClick={(_evt) =>
                      updateInvestment({
                        id: investmentId,
                        status: "Rejected",
                      })
                    }
                  >
                    Reject
                  </DropdownItem>
                </React.Fragment>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InvestmentProductDetails;
