import { assign, createMachine } from "xstate";

export const dataMachine = (machineId) =>
  createMachine(
    {
      id: machineId,
      initial: "idle",
      context: {
        response: {},
        results: [],
        message: undefined,
      },
      states: {
        idle: {
          on: {
            CREATE: "creating",
            FETCH: "fetching",
            DELETE: "deleting",
            UPDATE: "updating",
          },
        },
        fetching: {
          invoke: {
            src: "fetchData",
            onDone: { target: "success" },
            onError: { target: "failure", actions: "setMessage" },
          },
        },
        creating: {
          invoke: {
            src: "createData",
            onDone: { target: "success" },
            onError: { target: "failure", actions: "setMessage" },
          },
        },
        deleting: {
          invoke: {
            src: "deleteData",
            onDone: { target: "success" },
            onError: { target: "failure", actions: "setMessage" },
          },
        },
        updating: {
          invoke: {
            src: "updateData",
            onDone: { target: "success" },
            onError: { target: "failure", actions: "setMessage" },
          },
        },
        success: {
          entry: ["setResponse"],
          on: {
            CREATE: "creating",
            FETCH: "fetching",
            DELETE: "deleting",
            UPDATE: "updating",
          },
          initial: "unknown",
          states: {
            unknown: {
              always: [
                { target: "withData", cond: "hasData" },
                { target: "withoutData" },
              ],
            },
            withData: {},
            withoutData: {},
          },
        },
        failure: {
          entry: ["setMessage"],
          on: {
            FETCH: "fetching",
          },
        },
      },
    },
    {
      actions: {
        setResponse: assign((ctx, event) => ({
          response: event.data,
        })),
        setMessage: /* istanbul ignore next */ assign((ctx, event: any) => ({
          message: event.data,
        })),
      },
      guards: {
        hasData: (ctx, event) => Object.keys(ctx.response).length > 1,
      },
    }
  );
