import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import ServiceItem from "../../components/Pages/Services/ServiceItem";

export default function Import() {
  const serviceData = {
    title: "Import Goats",
    image: "/assets/images/introduction/IntroductionOne/banner.jpg",
    text: {
      blocks: [
        {
          key: "89m4f",
          text:
            "Mefarms Ltd works closely with foreign experts and farmers to import top quality full blood, pure bred and disease-free animals into Uganda. Some of the breeds under this scheme include; Boers, Saanen,Sudanese dwarf goat, the Toggern Burg goat and alpine breed. We have goats that have been well managed and with a high survivability, fertility and mothering abilities, calm disposition and adaptability to thrive under harsh conditions and in any part of Uganda with low quality food or harsh climate.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "5kpi1",
          text:
            "\nMenigte Farms’ experience indicates exotic goat (s) selection should targets exotic goats with short white coats on the major part of their bodies, making them more a pigmented skin on the hairless parts, e.g. under the tail, around the eyelids and mouth, which are usually essential, due to their resistance to sunburn and skin disease. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "djg9",
          text:
            "A loose, supple skin is essential for adaptability to climatic conditions and also offers additional resistance to external parasites. \n\nMenigte Farms Ltd holds active import permits all year round, to enable farmers’ flexibility to schedule their next order. Our services are customized based on the farmer needs, implying that import orders can be made to allow payment locally after receiving the goat or book for import (refer to the advert to book in MeFarms’ next consignment).",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "6ic56",
          text:
            "\n\nWe manage customs clearance, Veterinary tests at the comfort of your home. BOOK NOW! ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
  };

  return (
    <LayoutFour>
      <Breadcrumb title="Shop">
        <BreadcrumbItem name="Shop" />
        <BreadcrumbItem name="Import" current />
      </Breadcrumb>
      <div className={`services__item`}>
        {serviceData && (
          <ServiceItem
            bigImgSrc={serviceData.image}
            title={serviceData.title}
            text={serviceData.text}
          />
        )}
      </div>
      {/*<ProductSlideOne title="Local Breeds on sale" data={products.results} />*/}
    </LayoutFour>
  );
}
