/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import truncateHtml from "truncate-html";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
export default function PostCardTwo({ data }) {
  return (
    <div className="post-card-two">
      <div className="post-card-two__image">
        <img
          src={
            data.photo
              ? data.photo
              : "https://via.placeholder.com/360x240?text=www.menigtefarms.com"
          }
          alt={data.title}
        />
        <div className="post-card-two__info">
          <div className="post-card-two__info__date">
            <h5>{moment(data.created_at).format("DD")}</h5>
            <p>{moment(data.created_at).format("MMM")}</p>
          </div>
          <div className="post-card-two__info__detail">
            <p>
              by{" "}
              <span>
                {Object.prototype.hasOwnProperty.call(data.created_by, "id")
                  ? data.created_by.first_name
                  : ""}
              </span>
            </p>
            {/* <Link to="#"> {data.category}</Link> */}
          </div>
        </div>
      </div>
      <div className="post-card-two__content">
        <Link to={process.env.PUBLIC_URL + "/blog/post/" + data.id}>
          {data.title}
        </Link>
        <p
          dangerouslySetInnerHTML={{
            __html: truncateHtml(
              draftToHtml(JSON.parse(data.description)),
              260
            ),
          }}
        ></p>
      </div>
    </div>
  );
}
